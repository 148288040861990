import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
//import Checkbox from '@material-ui/core/Checkbox';
import '../App.css';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
//import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
/*import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';*/
import Config from '../assets/config';
import Cities from '../assets/cities';
import Checkbox from '@material-ui/core/Checkbox';
import { postFileDownload, postFetch } from '../http';
import Paper from '@material-ui/core/Paper';
import Gridtable from '../gridtable';
//import QueryBuilderRoundedIcon from '@material-ui/icons/QueryBuilderRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import Box from '@material-ui/core/Box';
import { Switch, Route } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { useHistory } from "react-router-dom";
import moment from "moment";
import NumberFormat from 'react-number-format';
import _ from 'lodash';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#fff',
        borderRadius: '5px',
        margin: '30px 10px',
        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 0px 0px rgb(0 0 0 / 12%)',
        "& .MuiFormControlLabel-labelPlacementStart": {
            marginLeft: 1
        }
    },
    header: {
        backgroundColor: "#1a75c9",
        color: "#fff",
        marginBottom: 10,
        padding: 10,
        fontWeight: 900,
        fontSize: 15
    },
    filters: {
        "& .MuiFormLabel-root.Mui-focused, .MuiFormLabel-root": {
            color: "#000",
            fontSize: 17
        },
        margin: '20px 0',
        padding: '0 10px'
    },
    textfield: {
        padding: '10px 10px 10px 0',
        position: 'relative'
    },
    textLabel: {
        position: 'relative',
        bottom: '8px',
        fontSize: '14px',
        fontWeight: '500'
    },
    datepicker: {
        position: 'relative',
        bottom: 3,
        padding: '0 10px 0 0'
    },
    formControl: {
        width: "100%",
        marginTop: 1
    },
    addressField: {
        width: 400,
        marginTop: 1
    },
    fabIcon: {
        padding: '10px',
        marginTop: '34px'
    },
    radioLabel: {
        position: 'relative',
        top: 13,
        marginRight: 20
    }
}));

const Subheader = ({ children }) => {
    return (
        <div className="wrapper">
            <p>{children}</p>
            <div className="line"></div>
        </div>
    )
}

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            format="##########"
            mask="_"
        />
    );
}

function AmountFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            allowNegative={false}
            prefix="$"
        />
    );
}

export default function MileageExp(props) {
    const classes = useStyles();
    const history = useHistory();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [summaryData, setSummaryData] = React.useState([]);
    const [totalAmount, setTotalAmount] = React.useState(0);
    const [onInit, setOnInit] = React.useState(true);
    const [carrierDetails, setCarrierDetails] = React.useState({ expReason: null, depDate: null, retDate: null, state: null, city: null, ledgerCode: null, carrierType: null, amount: null, reimbAmount: 0, coachFare: null, medSw: 'N', medStatus: 'No', medFirstname: null, medLastname: null, medDob: null, medId: null, medInternalOrder: null, medCostCenter: null, dhsCardSw: 'N', dhsCardAmount: 0, revReason: null, rembId: null, status: 'IP', mediStatus: 'IP', nonMedStatus: 'IP', mediStatusRevReason: null, nonMedStatusRevReason: null, delSw: 'N', id: null, medicaidApproved: null, outOfState: 'N', outsideWorkHours: 'N'});
    const [error, setError] = React.useState(false);
    const [medError, setMedError] = React.useState(false);
    const [documents, setDocuments] = React.useState([]);
    const [readOnly, setReadOnly] = React.useState(false);
    const [states, setStates] = React.useState([]);
    const [cities, setCities] = React.useState([]);
    //const [isRREdited,setIsRREdited] = React.useState(false);
    const [oldCarrierDetails, setOldCarrierDetails] = React.useState([]);
    const [allowMedicaid, setAllowMedicaid] = React.useState(true);
    const [stateHoliday, setStateHoliday] = React.useState([]);
    const [defaultReimbAmt, setDefaultReimbAmt] = React.useState(0);
    const [defaultDhsCardAmount, setDefaultDhsCardAmount] = React.useState(0);
    const [isFileUploaded, setIsFileUploaded] = React.useState(false);
    const [medicaidDocuments, setMedicaidDocuments] = React.useState([]);


    const documentMedicaid = (row, column, index) => {
        return (
            <FormControl component="fieldset">
                <RadioGroup row aria-label="med-sw" name="med-sw" value={row.medSw} onChange={(e) => changeDocumentDetails(index, 'medSw', e.target.value)}>
                    <div className={classes.radioButton}>
                        <FormControlLabel value="Y" control={<Radio disabled={readOnly} color="primary" />} label="Yes" />
                        <FormControlLabel value="N" control={<Radio disabled={readOnly} color="primary" />} label="No" />
                    </div>
                </RadioGroup>
            </FormControl>)
    }

    const medicaidDocumentMedicaidButton = (row, column, index) => {
        return (
            <FormControl component="fieldset">
                <RadioGroup row aria-label="med-sw" name="med-sw" value={row.medSw} onChange={(e) => changeMedicaidDocumentDetails(index, 'medSw', e.target.value)}>
                    <div className={classes.radioButton}>
                        <FormControlLabel value="Y" control={<Radio disabled color="primary" />} label="Yes" />
                        <FormControlLabel value="N" control={<Radio disabled color="primary" />} label="No" />
                    </div>
                </RadioGroup>
            </FormControl>)
    }

    const changeDocumentDetails = (index, key, value) => {
        let updatedDocs = [...documents].filter((doc) => doc.delSw !== 'Y');
        updatedDocs[index][key] = value;
        setDocuments(updatedDocs);
    }

    const changeMedicaidDocumentDetails = (index, key, value) => {
        let updatedDocs = [...medicaidDocuments].filter((doc) => doc.delSw !== 'Y');
        updatedDocs[index][key] = value;
        setMedicaidDocuments(updatedDocs);
    }

    const documentActionButton = (row, column, index) => {
        return (<div style={{ cursor: 'pointer' }}><VisibilityRoundedIcon onClick={() => viewDocument(row, index)} style={{ fontSize: 20 }} /><DeleteIcon onClick={() => readOnly ? null : changeDocumentDetails(index, 'delSw', 'Y')} style={{ fontSize: 20 }} /></div>);
    }

    const medicaidDocumentActionButton = (row, column, index) => {
        return (<div style={{ cursor: 'pointer' }}><VisibilityRoundedIcon onClick={() => viewDocument(row, index)} style={{ fontSize: 20 }} /><DeleteIcon onClick={() => readOnly ? null : changeMedicaidDocumentDetails(index, 'delSw', 'Y')} style={{ fontSize: 20 }} /></div>);
    }

    React.useEffect(() => {
        if (props.currentMedicaid && props.currentMedicaid.index !== undefined) {
            setIsFileUploaded(false);
        } else {
            console.error('props.currentMedicaid or props.currentMedicaid.index is undefined');
        }
    }, [props.currentMedicaid ? props.currentMedicaid.index : null]);
            
    React.useEffect(() => {
        const loadCarrierDetails = async () => {
            props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
            setOnInit(false);
            history.push("/tr1");
            const request = {
                rembId: parseInt(localStorage.getItem("requestId"))
            }
            // if(props.userInfo.role === 'SA' || props.userInfo.division === 'DCFS')
            // {
            //     setAllowMedicaid(false);
            // }
            await postFetch("/carrier/getByRembId", request).then((data) => {
                if (!data.message) {
                    let summary = [];
                    let totalAmount = 0;
                    let existingDates = localStorage.getItem("dates").split(",");
                    existingDates = existingDates.filter((date) => date.indexOf("ca") === -1);
                    data.forEach(exp => {
                        let medCount = [exp].filter((mil) => (mil.medSw === 'Y' && ( mil.mediStatus === 'IP' || mil.mediStatus === 'RR' || exp.mediStatus === 'XX')));
                        let mediApprover = props.staticData.dropdown.MedicaidApprovers.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0;
                        exp.sumexpenseDate = convertDateFromServer(exp.depDate) + " - " + convertDateFromServer(exp.retDate);
                        existingDates = existingDates + "," + exp.depDate + "ca," + exp.retDate + "ca";
                        exp.sumexpenseReason = props.staticData.reference.ReasonForExpense.value.find((config) => config.value === exp.expReason).name;
                        exp.sumamount = "$" + parseFloat(exp.reimbAmount).toFixed(2);
                        if(medCount.length > 0  && mediApprover && exp.status !== 'RR'){
                            exp.sumstatus = props.staticData.reference.ReimbursementStatus.value.find((config) => config.value === 'SB').name;
                        }else{
                            exp.sumstatus = props.staticData.reference.ReimbursementStatus.value.find((config) => config.value === exp.status).name;
                        }

                        /*if(medCount.length > 0  && mediApprover && (exp.mediStatus === 'IP' ) ){
                            console.log("here ");
                            exp.sumstatus = props.staticData.reference.ReimbursementStatus.value.find((config) => config.value === 'SB').name;
                        } else if(medCount.length > 0  && mediApprover && exp.mediStatus === 'AP' ){
                            exp.sumstatus = props.staticData.reference.ReimbursementStatus.value.find((config) => config.value === 'AP').name;
                        }else if(medCount.length > 0  && mediApprover && (exp.mediStatus === 'RR' || exp.mediStatus === 'XX') ){
                            exp.sumstatus = props.staticData.reference.ReimbursementStatus.value.find((config) => config.value === 'RR').name;
                        }else{
                            exp.sumstatus = props.staticData.reference.ReimbursementStatus.value.find((config) => config.value === exp.status).name;
                        }*/
                        if(exp.status === 'SB' && exp.nonMedStatus === 'RR'){
                            exp.nonMedStatusRevReason = null;
                            exp.nonMedStatus = null;
                        }
                        if(exp.status === 'SB' && exp.nonMedStatus === 'AP'){
                            exp.nonMedStatusRevReason = null;
                            exp.nonMedStatus = null;
                        }
                        if(exp.status === 'AP' && exp.nonMedStatus === 'AP' && exp.mediStatus === 'RR'){
                            exp.mediStatus = null;
                            exp.mediStatusRevReason = null;
                        }
                        //exp.sumstatus = props.staticData.reference.ReimbursementStatus.value.find((config) => config.value === exp.status).name;
                        if (exp.delSw !== 'Y') {
                            summary.push(exp);
                            totalAmount = parseFloat(totalAmount) + parseFloat(exp.reimbAmount);
                        }
                    });
                    localStorage.setItem("dates", existingDates);
                    summary.forEach(sum => {
                        sum.medicaid = sum.medSw === 'Y'?'Yes':'No';
                    })
                    setSummaryData(summary);
                    setTotalAmount(parseFloat(totalAmount).toFixed(2));
                    setTimeout(() => {
                        props.loader({ loader: false, notification: false });
                    }, 1000);
                    if (localStorage.getItem("readOnly") !== null) {
                        setReadOnly(true);
                    }
                }
                else {
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                    }, 1000);
                }
            }).catch((e) => {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
                }, 1000);
            });
        }
        if (onInit && (localStorage.getItem("requestId") !== undefined) && (localStorage.getItem("requestId") !== null)) {
            loadCarrierDetails();
            getStateHolidays();
        }
       
        if(((props.userInfo.role === 'PR') ? props.userInfo.division === 'DCFS' :  props.travellerInfo.divisionCode === 'DCFS') || ((props.travellerInfo.accountActive !== null && props.travellerInfo.accountActive === '0') && props.reimbursement.division === 'DCFS'))
        {
            setAllowMedicaid(false);
        }
    }, [props, onInit, summaryData, history]);

    function convertDateFromServer(value) {
        if ((value !== null) && !(value instanceof Date)) {
            let serverDateArray = value.split("-");
            if (serverDateArray.length > 1) {
                return serverDateArray[1] + "/" + serverDateArray[2] + "/" + serverDateArray[0];
            }
            else {
                return serverDateArray[0];
            }
        }
        else if (value instanceof Date) {
            return value;
        }
        else {
            return null;
        }

    }
    const saveCarriers = async () => {
        if (carrierDetails.coachFare === null) {
            setError(true);
        }
        else if (document.getElementsByClassName("Mui-error").length > 0) {
            setError(true);
        }
        else if ((carrierDetails.medSw === 'N' && [...documents].filter((doc) => doc.delSw !== 'Y').length === 0)) {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "At least one document is required to submit expense." });
        }
        else if ((carrierDetails.medSw === 'Y' && [...medicaidDocuments].filter((doc) => doc.delSw !== 'Y').length === 0)) {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "At least one medicaid document is required to submit expense." });
        }
        else if (props.reimbursement.expenseStartDate === null || props.reimbursement.expenseEndDate === null) {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "Total Reimbursement Expense Start Date or Expense End Date is missing" });
        }
        else if (((carrierDetails.status !== 'IP') && (props.reimbursement.assignedTo !== null) && (props.reimbursement.assignedTo === props.userInfo.id)) && carrierDetails.status === 'SB' && props.reimbursement.status === 'SB') {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "Please Complete Status Details." });
        }
        else if (((carrierDetails.status !== 'IP') && (props.reimbursement.assignedTo !== null) && (props.reimbursement.medSupervisor !== null) && (props.reimbursement.medSupervisor.toString() === props.userInfo.id.toString()) && (carrierDetails.medSw === 'Y')) && carrierDetails.mediStatus === 'IP' && props.reimbursement.status === 'SB') {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "Please Complete Status Details." });
        }
        else {
            if (localStorage.getItem("requestId") === null) {
                props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
                let request = { ...props.reimbursement };
                request.expenseStartDate = request.expenseStartDate === null ? null : new Date(request.expenseStartDate);
                request.expenseEndDate = request.expenseEndDate === null ? null : new Date(request.expenseEndDate);
                //request.status = request.mediStatus !== null ? request.mediStatus : request.nonMedStatus !== null ? request.nonMedStatus : 'IP';
                await postFetch("/reimbursement/save", request).then((data) => {
                    if (!data.message) {
                        setTimeout(() => {
                            localStorage.setItem("requestId", data.id);
                            localStorage.setItem("recordVersion", data.recordVersion);
                            saveExpense(data.id);
                        }, 1000);
                    }
                    else {
                        setTimeout(() => {
                            props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                        }, 1000);
                    }
                }).catch((e) => {
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
                    }, 1000);
                });
            }
            else {
                saveExpense(localStorage.getItem("requestId"));
            }
        }
    }

    const validateMedicaid = async () => {
        if ((carrierDetails.medId === null || carrierDetails.medId === '') || (carrierDetails.medFirstname === null || carrierDetails.medFirstname === '')
            || (carrierDetails.medLastname === null || carrierDetails.medLastname === '') || (carrierDetails.medDob === null)) {
            setMedError(true);
        }
        else {
            props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
            const request = {
                mediciadId: carrierDetails.medId,
                firstName: carrierDetails.medFirstname,
                lastName: carrierDetails.medLastname,
                dateOfBirth: (carrierDetails.medDob !== null) ? moment(carrierDetails.medDob).format("YYYY-MM-DD") : null,
                inquiryBeginDate: (carrierDetails.depDate !== null) ? moment(carrierDetails.depDate).format("YYYY-MM-DD") : null,
                inquiryEndDt: (carrierDetails.retDate !== null) ? moment(carrierDetails.retDate).format("YYYY-MM-DD") : null
            }

            await postFetch("/aries/verifyMedicaidStatus2", request).then((data) => {
                if (!data.message) {
                    if (data.ArMedicaidStatusInquiryResponse.MedicaidStatusCodes.StatusCode === '00') {
                        let updatedDetails = { ...carrierDetails };
                        updatedDetails.medStatus = 'Yes';
                        //updatedDetails.medInternalOrder = (new Date(carrierDetails.depDate).getMonth() < 9 && new Date(carrierDetails.depDate).getFullYear() < new Date().getFullYear()) ? props.staticData.dropdown["MedicaidDetails" + (new Date().getFullYear() - 1)].value.find((config) => config.value === 'IO').name : props.staticData.dropdown.MedicaidDetails.value.find((config) => config.value === 'IO').name;
                        //updatedDetails.medCostCenter = (new Date(carrierDetails.depDate).getMonth() < 9 && new Date(carrierDetails.depDate).getFullYear() < new Date().getFullYear()) ? props.staticData.dropdown["MedicaidDetails" + (new Date().getFullYear() - 1)].value.find((config) => config.value === 'CO').name : props.staticData.dropdown.MedicaidDetails.value.find((config) => config.value === 'CO').name;
                        setCarrierDetails({ ...updatedDetails });
                        setTimeout(() => {
                            props.loader({ loader: false, notification: true, message: "success", validationMessage: "Medicaid Verified Successfully" });
                        }, 1000);
                    }
                    else {
                        let message = 'Unable to verify Medicaid status: Invalid ';
                        data.ArMedicaidStatusInquiryResponse.MedicaidStatusCodes.StatusCode.toString().split(',').forEach((code) => {
                            message += props.staticData.reference.MedicaidStatusCodes.value.find((config) => config.value.toString() === code.toString()).name + ', ';
                        });
                        setTimeout(() => {
                            props.loader({ loader: false, notification: true, message: "error", validationMessage: message });
                        }, 1000);
                    }
                }
                else {
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
                    }, 1000);
                }
            }).catch((e) => {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
                }, 1000);
            });
        }
    }

    const saveExpense = async (reimId) => {
        if (carrierDetails.medSw === "Y" && carrierDetails.medStatus === "No") {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "One or More Medicaid details are not verified." });
        }
        else {
            props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
            let request = { ...carrierDetails };
            request.rembId = reimId;
            request.depDate = new Date(request.depDate);
            request.retDate = new Date(request.retDate);
            request.dhsCardAmount = request.dhsCardAmount !== null ? parseFloat(request.dhsCardAmount) : null;
            request.amount = request.amount !== null ? parseFloat(request.amount) : null;
            request.reimbAmount = request.reimbAmount !== null ? parseFloat(request.reimbAmount) : null;
            //request.ledgerCode = props.staticData.dropdown.GeneralLedgerCodes.value.find((config) => config.value === request.expReason).name;
            request.documents = [...documents];
            request.medicaidDocuments = [...medicaidDocuments];

            
            request.status = request.nonMedStatus !== null ? request.nonMedStatus : request.mediStatus !== null ? request.mediStatus : 'IP';
            request.revReason = request.mediStatusRevReason !== null ? request.mediStatusRevReason : request.nonMedStatus;
            request.status = request.status !== undefined ? request.status : 'IP';
            if(request.medSw === 'N' && request.status !== 'AP'){
                request.nonMedStatus = 'IP';
            } else if(request.medSw === 'Y' && request.status !== 'AP'){
                request.mediStatus = 'IP';
            }

            if(request.medSw === 'N' && request.status === 'RR'){
                request.nonMedStatus = 'RR';
            }

             if(request.medSw === 'Y' && request.mediStatus === 'RR' && props.staticData.dropdown.MedicaidApprovers.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0){
                request.status = 'RR';
                request.mediStatus = 'XX';
                request.medRevReason = request.revReason;
            }

            if (props.reimbursement.status === 'RR') {
                let oldCarrierDtl = { ...oldCarrierDetails };
                oldCarrierDtl.depDate = new Date(oldCarrierDtl.depDate);
                oldCarrierDtl.retDate = new Date(oldCarrierDtl.retDate);
                oldCarrierDtl.rembId = reimId;
                if (!_.isEqual(oldCarrierDtl, request)) {
                    request.status = 'IP';
                }
            }
            if(request.status === 'AP' && request.mediStatus === 'AP' && request.medSw === 'Y' && props.staticData.dropdown.MedicaidApprovers.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0){
                request.medicaidApproved = true;
            }
            if((props.travellerInfo.accountActive !== null && props.travellerInfo.accountActive === '0') && request.status === 'IP' && props.reimbursement.assignedTo === props.userInfo.id)
            {
                request.status = 'SB'
            }
            await postFetch("/carrier/save", request).then((data) => {
                if (!data.message) {
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "success", validationMessage: "Common Carrier Expense saved successfully." });
                    }, 1500);
                    setOnInit(true);
                }
                else {
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                    }, 1000);
                }
            }).catch((e) => {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
                }, 1000);
            }); 
        }
    }
   
    const upload = async (target, fromMethod) => {
        if (target.files.length === 0) {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "No File Selected" });
        }
        else if (target.files[0].type.length === 0) {
            target.value = null;
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "Upload Failed. Please upload a document with a valid file extension such as .doc,.docx, .pdf, .txt, .jpeg etc." });
        }
        else {
            props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
            var fileByteArray = [];
            const fileReader = new FileReader();
            fileReader.onloadend = (evt) => {
                if (evt.target.readyState === FileReader.DONE) {
                    var arrayBuffer = evt.target.result,
                        array = new Uint8Array(arrayBuffer);
                    for (var i = 0; i < array.length; i++) {
                        fileByteArray.push(array[i]);
                    }
                }
            }
            fileReader.readAsArrayBuffer(target.files[0]);
            setTimeout(() => {
                const request = {};
                request.DocumentName = target.files[0].name;
                request.DocumentTitle = target.files[0].name + "_" + props.userInfo.id;
                request.DocumentID = null;
                request.DatabaseName = Config.edocutusDatabase;
                request.DocumentTypeID = Config.edocutusDocumentTypeId;
                request.DocumentData = fileByteArray;
                postFetch("/document", request).then((data) => {
                    if (data.documentID !==null && !data.message) {
                        const newDocument = { id: null, 
                            fileType: target.files[0].type, 
                            fileName: (fromMethod === "Medicaid" ? "Carrier Medicaid Document - " : "Carrier Expense - ") + target.files[0].name, 
                            size: (target.files[0].size / 1048576).toFixed(2) + " MB",
                            edocId: data.DocumentID,
                            medSw: fromMethod === "Medicaid" ?'Y' : 'N', 
                            carrierId: null, 
                            delSw: 'N' 
                        };
                        if(fromMethod === "Medicaid")
                        {
                            let medUpdatedDocs = [...medicaidDocuments];
                            medUpdatedDocs.push(newDocument);
                            setMedicaidDocuments(medUpdatedDocs);
                            
                        }else
                        {
                            let updatedDocs = [...documents];
                            updatedDocs.push(newDocument);
                            setDocuments(updatedDocs);
                        }
                        target.value = null;
                        setTimeout(() => {
                            props.loader({ loader: false, notification: true, message: "success", validationMessage: fromMethod === "Medicaid" ? "Medicaid Document uploaded successfully" : "Document uploaded successfully" });
                        }, 1000);
                    }
                    else {
                        target.value = null;
                        setTimeout(() => {
                            props.loader({ loader: false, notification: true, message: "error", validationMessage: "Document upload failed" });
                        }, 1000);
                    }
                }).catch(() => {
                    target.value = null;
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: "Document upload failed." });
                    }, 1000);
                });
            }, 1000);
        }
    }

    const viewDocument = (row, index) => {
        props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
        postFileDownload("/document?DocumentID=" + row.edocId + "&DatabaseName=" + Config.edocutusDatabase + "&DocumentTypeID=" + Config.edocutusDocumentTypeId).then((data) => {
            if (!data.message) {
                var fileUrl = "data:" + row.fileType + ";base64," + data;
                fetch(fileUrl)
                    .then(response => response.blob())
                    .then(blob => {
                        var link = window.URL.createObjectURL(blob, { type: row.fileType });
                        window.open(link);
                    });
                setTimeout(() => {
                    props.loader({ loader: false, notification: false });
                }, 1000);
            }
            else {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                }, 1000);
            }
        }).catch((e) => {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            }, 1000);
        });
    }


    const summaryActionButton = (row, column, index) => {
        return (<div style={{ cursor: 'pointer' }}><EditRoundedIcon onClick={() => viewExpense(row)} style={{ fontSize: 20 }} /><DeleteIcon onClick={() => readOnly ? null : deleteExpense(row)} style={{ fontSize: 20 }} /></div>);
    }

    const viewExpense = (row) => {
        let details = { ...row };
        getStates();
        getCities(details.state);
        details.depDate = new Date(convertDateFromServer(details.depDate));
        details.retDate = new Date(convertDateFromServer(details.retDate));
        if (details.medSw === 'Y') {
            details.medDob = new Date(convertDateFromServer(details.medDob));
        }
        setCarrierDetails({ ...details });
        setDefaultReimbAmt(details.reimbAmount);
        setDefaultDhsCardAmount(details.dhsCardAmount);
        setDocuments([...details.documents]);
        setMedicaidDocuments([...details.medicaidDocuments]);
        setOldCarrierDetails({ ...details });
        history.push("/tr1/carriersexp");
    }

    const reset = async () => {
        let details = { ...carrierDetails };
        //setIsRREdited(false);
        if (details.id === null) {
            setCarrierDetails({ expReason: null, depDate: null, retDate: null, state: null, city: null, ledgerCode: null, carrierType: null, amount: null, reimbAmount: 0, coachFare: null, medSw: 'N', medStatus: 'No', medFirstname: null, medLastname: null, medDob: null, medId: null, medInternalOrder: null, medCostCenter: null, dhsCardSw: 'N', dhsCardAmount: 0, revReason: null, rembId: null, status: 'IP', delSw: 'N', id: null,outOfState: 'N', outsideWorkHours: 'N' });
            setError(false);
            setDocuments([]);
            setMedicaidDocuments([]);
        }
        else {
            props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
            let request = { ...details };
            await postFetch("/carrier/findById", request).then((data) => {
                if (!data.message) {
                    setTimeout(() => {
                        props.loader({ loader: false, notification: false, message: "success" });
                        let details = { ...data };
                        details.depDate = new Date(convertDateFromServer(details.depDate));
                        details.retDate = new Date(convertDateFromServer(details.retDate));
                        if (details.medSw === 'Y') {
                            details.medDob = new Date(convertDateFromServer(details.medDob));
                        }
                        setCarrierDetails({ ...details });
                        setDocuments([...details.documents]);
                        setMedicaidDocuments([...details.medicaidDocuments]);
                        forceUpdate();
                    }, 1000);
                }
                else {
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                    }, 1000);
                }
            }).catch((e) => {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
                }, 1000);
            });
        }
    }
    const deleteExpense = async (row) => {
        let details = { ...row };
        details.delSw = 'Y';
        props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
        let request = { ...details };
        await postFetch("/carrier/save", request).then((data) => {
            if (!data.message) {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "success", validationMessage: "Common Carrier Expense saved successfully." });
                    setOnInit(true);
                }, 1000);
            }
            else {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                }, 1000);
            }
        }).catch((e) => {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            }, 1000);
        });
    }


    const changeReimAmount = () => {
        let newDetails = { ...carrierDetails };
        newDetails.dhsCardAmount = null;
        newDetails.reimbAmount = newDetails.amount !== null ? parseFloat(newDetails.amount).toFixed(2) : null;
        
        setDefaultReimbAmt(newDetails.reimbAmount);
        setDefaultDhsCardAmount(newDetails.dhsCardAmount);

        if (newDetails.dhsCardSw === 'P') {
            if(newDetails.reimbAmount == 0.0 && newDetails.dhsCardAmount>0){
                newDetails.reimbAmount = newDetails.dhsCardAmount
            }

            newDetails.dhsCardAmount = null;
            newDetails.reimbAmount = parseFloat(newDetails.reimbAmount).toFixed(2);
        }
        if (newDetails.dhsCardSw === 'D' || newDetails.dhsCardSw === 'C') {
            if ( (newDetails.dhsCardAmount !== null && newDetails.dhsCardAmount !== '') && ((parseFloat(newDetails.dhsCardAmount) > parseFloat(newDetails.reimbAmount)) || (newDetails.reimbAmount === null))) {
               newDetails.reimbAmount = newDetails.dhsCardAmount;
               newDetails.dhsCardAmount = 0.0;
            }
                newDetails.dhsCardAmount = parseFloat(newDetails.reimbAmount);
                newDetails.reimbAmount = parseFloat(newDetails.reimbAmount) - parseFloat(newDetails.dhsCardAmount);
                newDetails.reimbAmount = parseFloat(newDetails.reimbAmount).toFixed(2);
            
        }

        
        setCarrierDetails(newDetails);
    }
    const getCities = async (value) => {
        props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
        await postFetch("/reimbursement/loadCities", value).then((data) => {
            if (!data.message) {
                setTimeout(() => {
                    props.loader({ loader: false, notification: false, message: "success" });
                    setCities([...data]);
                }, 1000);
            }
            else {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                }, 1000);
            }
        }).catch((e) => {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            }, 1000);
        });
    }

    const getStates = async () => {
        let details = { id: null };
        props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
        await postFetch("/reimbursement/loadStates", details).then((data) => {
            if (!data.message) {
                setTimeout(() => {
                    props.loader({ loader: false, notification: false, message: "success" });
                    setStates([...data]);
                }, 1000);
            }
            else {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                }, 1000);
            }
        }).catch((e) => {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            }, 1000);
        });
    }

    const getStateHolidays = async () => {
        let details = { id: null };
        props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
        await postFetch("/expense/getStateHoliday", details).then((data) => {
            if (!data.message) {
                setTimeout(() => {
                    props.loader({ loader: false, notification: false, message: "success" });
                    setStateHoliday([...data]);
                }, 1000);
            }
            else {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                }, 1000);
            }
        }).catch((e) => {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            }, 1000);
        });
    }

    const checkCarrierExpenseDates = async (depDate, retDate) => {
        try {
            // Format the departure and return dates
            let formattedDepartureDate = moment(depDate).format('YYYY-MM-DD');
            let formattedReturnDate = moment(retDate).format('YYYY-MM-DD');
            let details = { departureDate: formattedDepartureDate, returnDate: formattedReturnDate, userId: props.userInfo.id };
                    
            // Fetch the response from the backend
            const response = await postFetch("/expense/checkCarrierDepartureDate", details);
        
            if (response && response.expenses && response.expenses.length > 0) {
                // Filter to only include 'carrier' expense types
                const carrierExpenses = response.expenses.filter(item => item.expenseType === 'carrier');
        
                if (carrierExpenses.length > 0) {
                    // Extract unique reimbursement IDs
                    const reimbursementIds = [...new Set(
                        carrierExpenses.map(item => item.rembId).filter(id => id !== undefined && id !== null)
                    )];
                                    
                    if (reimbursementIds.length > 0) {
                        const reimbursementIdsString = reimbursementIds.join(", ");
                        const message = `You already have a carrier expense for these dates in Reimbursement ${reimbursementIdsString}. Please ensure this is not a duplicate expense.`;
        
                        // Set the error message in state
                        setCarrierDetails(prevDetails => ({
                            ...prevDetails,
                            expenseDateWarning: message
                        }));
                    } else {
                        // Clear any previous error message if no unique reimbursement IDs are found
                        setCarrierDetails(prevDetails => ({
                            ...prevDetails,
                            expenseDateWarning: ""
                        }));
                    }
                } else {
                    // Clear any previous error message if no matching carrier expenses are found
                    setCarrierDetails(prevDetails => ({
                        ...prevDetails,
                        expenseDateWarning: ""
                    }));
                }
            } else {
                // Clear any previous error message if no expenses exist for the given dates
                setCarrierDetails(prevDetails => ({
                    ...prevDetails,
                    expenseDateWarning: ""
                }));
            }
        
            // Continue with the save operation (do not block)
            return true;
        } catch (error) {
            // Set a system error message in state
            setCarrierDetails(prevDetails => ({
                ...prevDetails,
                expenseDateWarning: "System Error: Please contact help desk."
            }));
            return true;
        }
    };
    
    const changeCarrierDetails = (key, value) => {
        let newDetails = { ...carrierDetails };
         // Update state with new values
    newDetails[key] = value;

    if (key === 'depDate') {
        // If departure date is updated, clear the return date
        newDetails.retDate = null;
    }

    if (key === 'retDate') {
        // If return date is updated and departure date is not set, clear the departure date
        if (newDetails.depDate === null) {
            newDetails.depDate = null;
        }
    }

    // Update the state with new details
    setCarrierDetails(newDetails);
    setTimeout(() => {
    // Call the function if both dates are set
    if (newDetails.depDate && newDetails.retDate) {
        checkCarrierExpenseDates(newDetails.depDate, newDetails.retDate);
        setCarrierDetails(prevDetails => ({
            ...prevDetails,
            expenseDateWarning: ""
        }));
    }  else {
        // Both dates are missing, clear the warning message
        setCarrierDetails(prevDetails => ({
            ...prevDetails,
            expenseDateWarning: ""
        }));
    }
}, 0);
        // if(newDetails[key] !== value)
        // {
        //     setIsRREdited(true);
        // }
        newDetails[key] = value;
        if (key === 'nonMedStatus' || key === 'mediStatus') {
            newDetails['status'] = value;
        }
        if (key === 'state' && value !== null) {
            newDetails.city = null;
            getCities(value);
        }
        if (key === 'depDate' && (value !== null)){
            checkCarrierExpenseDates(value);
        }
        if (key === 'depDate' && (value !== null) && (props.reimbursement.expenseStartDate !== null) && (value.setHours(0, 0, 0) < new Date(new Date(props.reimbursement.expenseStartDate).toDateString()).setHours(0, 0, 0))) {
            value = null;
            props.loader({ loader: false, notification: true, message: "error", validationMessage: 'Departure Date cannot be prior to Total Reimbursement Expense Start Date' });
        }
        if (key === 'depDate' && (value !== null) && (props.reimbursement.expenseEndDate !== null) && (value.setHours(0, 0, 0, 0) > new Date(new Date(props.reimbursement.expenseEndDate).toDateString()).setHours(0, 0, 0))) {
            value = null;
            props.loader({ loader: false, notification: true, message: "error", validationMessage: 'The Departure Date must be a past or current date.' });
        }
        if (key === 'retDate' && (value !== null) && (props.reimbursement.expenseStartDate !== null) && (value.setHours(0, 0, 0) < new Date(new Date(props.reimbursement.expenseStartDate).toDateString()).setHours(0, 0, 0))) {
            value = null;
            props.loader({ loader: false, notification: true, message: "error", validationMessage: 'Return Date cannot be prior to Total Reimbursement Expense Start Date' });
        }
        if (key === 'retDate' && (value !== null) && (props.reimbursement.expenseEndDate !== null) && (value.setHours(0, 0, 0, 0) > new Date(new Date(props.reimbursement.expenseEndDate).toDateString()).setHours(0, 0, 0))) {
            value = null;
            props.loader({ loader: false, notification: true, message: "error", validationMessage: 'The Return Date must be a past or current date.' });
        }

        if (key === 'depDate' && (value !== null) && (newDetails.retDate !== null) && (value.setHours(0, 0, 0, 0) > new Date(new Date(newDetails.retDate).toDateString()).setHours(0, 0, 0))) {
            value = null;
            props.loader({ loader: false, notification: true, message: "error", validationMessage: 'Departure date must be prior to Return date' });
        }

        if (key === 'retDate' && (value !== null) && (newDetails.depDate !== null) && (value.setHours(0, 0, 0, 0) < new Date(new Date(newDetails.depDate).toDateString()).setHours(0, 0, 0))) {
            value = null;
            props.loader({ loader: false, notification: true, message: "error", validationMessage: 'Return date must be after Departure date' });
        }

        if (key === 'medDob' && (value !== null) && (value.setHours(0, 0, 0) > new Date(new Date().toDateString()).setHours(0, 0, 0))) {
            value = null;
            props.loader({ loader: false, notification: true, message: "error", validationMessage: 'The Date of Birth must be a past or current date.' });
        }
        if ((key === 'dhsCardSw') && (value === 'N')) {
            newDetails.dhsCardAmount = null;
            newDetails.reimbAmount = newDetails.amount !== null ? parseFloat(newDetails.amount).toFixed(2) : null;
        }
        if ((key === 'dhsCardSw') && (value === 'Y') && (newDetails.dhsCardAmount !== null) && (newDetails.dhsCardAmount !== '')) {
            if ((parseFloat(newDetails.dhsCardAmount) > parseFloat(newDetails.amount)) || (newDetails.amount === null)) {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: 'DHS Credit Card Amount should be less than Total Expense Amount' });
                }, 1000);
            }
            else if ((newDetails.dhsCardAmount !== null) && (newDetails.dhsCardAmount !== '') && (newDetails.dhsCardSw === 'Y')) {
                newDetails.reimbAmount = parseFloat(newDetails.reimbAmount) - parseFloat(newDetails.dhsCardAmount);
                newDetails.reimbAmount = parseFloat(newDetails.reimbAmount).toFixed(2);
            }
            else {
                newDetails.reimbAmount = parseFloat(newDetails.reimbAmount).toFixed(2);
            }
            //else {
            //    newDetails.dhsCardAmount = parseFloat(newDetails.reimbAmount);
             //   newDetails.reimbAmount = parseFloat(newDetails.amount) - parseFloat(newDetails.dhsCardAmount);
            //    newDetails.reimbAmount = parseFloat(newDetails.reimbAmount).toFixed(2);
           // }
       // }
        }
        if(key === 'dhsCardSw'){
            newDetails.dhsCardAmount = defaultDhsCardAmount;
            newDetails.reimbAmount = defaultReimbAmt;
            if (value === 'P') {
                if(newDetails.reimbAmount == 0.0 && newDetails.dhsCardAmount>0){
                    newDetails.reimbAmount = newDetails.dhsCardAmount
                }
                newDetails.dhsCardAmount = null;
                newDetails.reimbAmount = parseFloat(newDetails.reimbAmount).toFixed(2);
            }
            if (value === 'D' || value === 'C') {
                if ((newDetails.dhsCardAmount !== null && newDetails.dhsCardAmount !== '') && ((parseFloat(newDetails.dhsCardAmount) > parseFloat(newDetails.reimbAmount)) || (newDetails.reimbAmount === null))) {
                    newDetails.reimbAmount = newDetails.dhsCardAmount;
                    newDetails.dhsCardAmount = 0.0;
                }
                    newDetails.dhsCardAmount = parseFloat(newDetails.reimbAmount);
                    newDetails.reimbAmount = parseFloat(newDetails.reimbAmount) - parseFloat(newDetails.dhsCardAmount);
                    newDetails.reimbAmount = parseFloat(newDetails.reimbAmount).toFixed(2);
    
            }
        }       
       
            newDetails[key] = value;
            if((key === "expReason" || key === "depDate" || key === "retDate") && (newDetails.expReason === null || newDetails.expReason === 0) || (newDetails.depDate === null || newDetails.retDate === null))
            {
                newDetails.ledgerCode = null;
                newDetails.medInternalOrder = null;
                newDetails.medCostCenter = null;
            }
            if(key === 'city' && newDetails.state !== null && props.userInfo.userType === 'EM')
            {
                if((["Arkansas"].indexOf(newDetails.state)  === -1))
                {
                    newDetails.outOfState = 'Y';
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: "Location selected requires Out of State Travel Preapproval attachment prior to submission. Please attach your preapproval on the Travel Reconciliation Screen." });
                    }, 1000);
                }else{
                    newDetails.outOfState = 'N';
                }            
            }
            if((key === 'depDate' || key === 'retDate') && (newDetails.depDate !== null && newDetails.retDate !== null) && props.userInfo.userType === 'EM' && (moment(newDetails.depDate, 'MM/DD/YYYY').isValid()) && (moment(newDetails.retDate, 'MM/DD/YYYY').isValid()))
            {        
                
                var startDt = new Date(newDetails.depDate).getDay();
                var endDt = new Date(newDetails.retDate).getDay();
                var checkinStateHoliday = stateHoliday.filter((holiday) => holiday.holidayDate === moment(newDetails.depDate).format("YYYY-MM-DD") && holiday.active === 'Y' ).length > 0 ? true : false;
                var checkoutStateHoliday = stateHoliday.filter((holiday) => holiday.holidayDate === moment(newDetails.retDate).format("YYYY-MM-DD") && holiday.active === 'Y').length > 0 ? true : false;
                if((startDt === 6 || startDt  === 0) || (endDt === 6 || endDt  === 0) || (checkinStateHoliday || checkoutStateHoliday)){
                        newDetails["outsideWorkHours"] = 'Y';
                        carrierDetails["outsideWorkHours"] = 'Y';
                        setTimeout(() => {
                            props.loader({ loader: false, notification: true, message: "error", validationMessage: "Expense Date selected requires Request to Travel Outside Normal Work Schedule Preapproval attachment prior to submission. Please attach your preapproval on the Travel Reconciliation Screen." });
                        }, 1000);
                    } else{
                        newDetails["outsideWorkHours"] = 'N';
                        carrierDetails["outsideWorkHours"] = 'N';
                }
                // postFetch("/expense/checkStateHoliday", request).then((data) => {
                //     if (!data.message) {
                //         props.loader({ loader: false, notification: false, message: "success" });
                //         var dayOfWeek = new Date(value).getDay();
                //         if((dayOfWeek === 6 || dayOfWeek  === 0) || (data.holidayCount > 0)){
                //                 newDetails.outsideWorkHours = 'Y';
                //                 setTimeout(() => {
                //                     props.loader({ loader: false, notification: true, message: "error", validationMessage: "Expense Date selected requires Request to Travel Outside Normal Work Schedule Preapproval attachment prior to submission. Please attach your preapproval on the Travel Reconciliation Screen." });
                //                 }, 1000);
                //             } else{
                //                 newDetails.outsideWorkHours = 'N';
                //             }
                //     }
                //     else {
                //         setTimeout(() => {
                //             props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                //         }, 1000);
                //     }
                // }).catch((e) => {
                //     setTimeout(() => {
                //         props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
                //     }, 1000);
                // });
            }
            setCarrierDetails(newDetails);

            if((key === "expReason" || key === "depDate" || key === "retDate" || key === "medSw") && (newDetails.expReason !== null && newDetails.expReason !== 0) && (newDetails.depDate !== null && newDetails.retDate !== null)
                && (moment(newDetails.depDate, 'MM/DD/YYYY').isValid()) && (moment(newDetails.retDate, 'MM/DD/YYYY').isValid())){
                let request = {
                    expense: 'CA',
                    expenseType: (newDetails.medSw !== null && newDetails.medSw === 'Y' ? 'ME' : 'NM'),
                    reasonForExpense: newDetails.expReason,
                    userType: props.userInfo.userType,
                    division: (props.travellerInfo.accountActive !== null && props.travellerInfo.accountActive === '0') ? props.reimbursement.division : (props.userInfo.role === 'PR') ? props.userInfo.division : props.travellerInfo.divisionCode,
                    startDate: new Date(newDetails.depDate),
                    endDate: new Date(newDetails.retDate)
                }
                getIOCC(request);
            }

            // if(key === 'medSw' && value !== null){
            //     let request = {
            //         expense: 'CA',
            //         expenseType: (value === 'Y' ? 'ME' : 'NM'),
            //         reasonForExpense: newDetails.expReason,
            //         userType: props.userInfo.userType,
            //         division: props.travellerInfo.divisionCode,
            //         startDate: new Date(newDetails.depDate),
            //         endDate: new Date(newDetails.retDate)
            //     }
            //     getIOCC(request);
            // }
        }

        const getIOCC = async (request) => {
            let newDetails = { ...carrierDetails };
            props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
            postFetch("/financialAdministration/loadIOCC", request).then((data) => {
                if (!data.message) {
                    props.loader({ loader: false, notification: false, message: "success" });
                    newDetails.medInternalOrder = data.internalOrder;
                    newDetails.medCostCenter = data.costCenter;
                    newDetails.expReason = request.reasonForExpense;
                    newDetails.ledgerCode = data.generalLedgerCode;
                    newDetails.medSw = request.expenseType === 'ME' ? 'Y' : 'N';
                    newDetails.depDate = request.startDate;
                    newDetails.retDate = request.endDate;
                    //if(request.userType === 'EM' && request.expenseType !== 'ME')
                    if(data.internalOrder === null || data.internalOrder === '')
                    {
                        newDetails.medInternalOrder = props.travellerInfo.empInternalOrder;
                        newDetails.medCostCenter = props.travellerInfo.empCostCenter;
                    }
                    setCarrierDetails(newDetails);
                }
                else {
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                    }, 1000);
                }
            }).catch((e) => {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
                }, 1000);
            });
        }

        const addNewCarrierExp = () => {
            setCarrierDetails({ expReason: null, depDate: null, retDate: null, state: null, city: null, ledgerCode: null, carrierType: null, amount: null, reimbAmount: 0, coachFare: null, medSw: 'N', medStatus: 'No', medFirstname: null, medLastname: null, medDob: null, medId: null, medInternalOrder: null, medCostCenter: null, dhsCardSw: 'N', dhsCardAmount: 0, revReason: null, rembId: null, status: 'IP', delSw: 'N', id: null ,outOfState: 'N', outsideWorkHours: 'N'});
            setError(false);
            getStates();
            setDocuments([]);
            setMedicaidDocuments([]);
            getStateHolidays();
            history.push("/tr1/carriersexp");
        }
        const carrierColumns = [
            {
                name: "Expense Period",
                field: "sumexpenseDate",
                type: "text"
            },
            {
                name: "Medicaid",
                field: "medicaid",
                type: "text"
            },
            {
                name: "Amount",
                field: "sumamount",
                type: "text"
            },
            {
                name: "Status",
                field: "sumstatus",
                type: "text"
            },
            {
                name: "Action",
                field: "action",
                type: "input",
                renderView: summaryActionButton
            }
        ]
        const uploadColumns = [
            {
                name: "File Name",
                field: "fileName",
                type: "text"
            },
            {
                name: "Size",
                field: "size",
                type: "text"
            },
            {
                name: "Medicaid?",
                field: "medSw",
                type: "input",
                renderView: documentMedicaid
            },
            {
                name: "Action",
                field: "action",
                type: "input",
                renderView: documentActionButton
            }
        ]

        const medicaidUploadColumns = [
            {
                name: "File Name",
                field: "fileName",
                type: "text"
            },
            {
                name: "Size",
                field: "size",
                type: "text"
            },
            {
                name: "Medicaid?",
                field: "medSw",
                type: "input",
                renderView: medicaidDocumentMedicaidButton
            },
            {
                name: "Action",
                field: "action",
                type: "input",
                renderView: medicaidDocumentActionButton
            }
        ]


        return (
            <div>
                <Switch>
                    <Route exact path="/tr1">
                        <div className={classes.root}>
                            <Typography className={classes.header}>Common Carrier Expense Summary</Typography>
                            <Box style={{ float: 'right' }} m={2}>
                                <Button variant="outlined" color="primary" size="small" className={(readOnly || (props.pageInfo.find((page) => page.pageId === 4).permissionSw !== 'Y')) ? "disabled" : null}
                                    disabled={(readOnly || (props.pageInfo.find((page) => page.pageId === 4).permissionSw !== 'Y'))} onClick={() => addNewCarrierExp()}>Add New</Button>
                            </Box>
                            <Paper elevation={0}>
                                <Box p={"10px"} pb={"60px"}>
                                    <Gridtable columns={carrierColumns} data={summaryData}></Gridtable>
                                    <div className="summaryTotal">
                                        <span>Total Amount: ${totalAmount}</span>
                                    </div>
                                </Box>
                            </Paper>
                        </div>
                    </Route>
                    <Route exact path="/tr1/carriersexp">
                        <div>
                            <Typography className={classes.header}>Common Carrier Expense Details</Typography>

                            <Grid container className={classes.filters}>
                                <Grid item xs={10} sm={5} className={classes.datepicker}>
                                    <span className="datepickerLabel">Departure Date</span>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                        <KeyboardDatePicker
                                            //disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="dep-date"
                                            autoOk={true}
                                            className={readOnly ? "disabled" : "required"}
                                            disabled={readOnly}
                                            size="small"
                                            fullWidth
                                            value={carrierDetails.depDate}
                                            error={(carrierDetails.depDate === null) || (!moment(carrierDetails.depDate, 'MM/DD/YYYY').isValid())}
                                            minDate={props.reimbursement.expenseStartDate !== null ? props.reimbursement.expenseStartDate : null}
                                            maxDate={props.reimbursement.expenseEndDate !== null ? props.reimbursement.expenseEndDate : new Date()}
                                            onChange={(date) => changeCarrierDetails('depDate', date)}
                                            placeholder="MM/DD/YYYY"
                                            KeyboardButtonProps={{
                                                'aria-label': 'depDate date',
                                            }}
                                            inputVariant="outlined"
                                            helperText={(carrierDetails.depDate === null && error) ? "Required Field" : null}                                        />
                                    </MuiPickersUtilsProvider>
                                    <FormHelperText style={{ color: 'red' }}> {/* Use a color to indicate warning */}
                                    {carrierDetails.expenseDateWarning}
                                    </FormHelperText>
                                </Grid>
                                    
                                <Grid item xs={10} sm={5} className={classes.datepicker}>
                                    <span className="datepickerLabel">Return Date</span>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                        <KeyboardDatePicker
                                            //disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="ret-date"
                                            autoOk={true}
                                            className={readOnly ? "disabled" : "required"}
                                            disabled={readOnly}
                                            size="small"
                                            fullWidth
                                            value={carrierDetails.retDate}
                                            error={(carrierDetails.retDate === null) || (!moment(carrierDetails.retDate, 'MM/DD/YYYY').isValid())}
                                            minDate={props.reimbursement.expenseStartDate !== null ? props.reimbursement.expenseStartDate : null}
                                            maxDate={props.reimbursement.expenseEndDate !== null ? props.reimbursement.expenseEndDate : new Date()}
                                            onChange={(date) => changeCarrierDetails('retDate', date)}
                                            placeholder="MM/DD/YYYY"
                                            KeyboardButtonProps={{
                                                'aria-label': 'retDate date',
                                            }}
                                            inputVariant="outlined"
                                            helperText={(carrierDetails.retDate === null && error) ? "Required Field" : null}
                                        />
                                    </MuiPickersUtilsProvider>
                                    {carrierDetails.expenseDateError && (
                                    <FormHelperText error>{carrierDetails.expenseDateError}</FormHelperText> // Only show the error message below the input
                                )}
                                </Grid>
                            </Grid>
                            <Grid container className={classes.filters}>
                                <Grid item xs={10} sm={5} className={classes.textfield}>
                                    <FormControl variant="outlined" size="small" className={classes.formControl} error={carrierDetails.expReason === 0 || carrierDetails.expReason === null}>
                                        <span className={classes.textLabel}>Reason for Expense</span>
                                        <Select
                                            id="reason-select"
                                            value={carrierDetails.expReason === null ? 0 : carrierDetails.expReason}
                                            className={readOnly ? "disabled" : "required"}
                                            disabled={readOnly}
                                            onChange={(e) => changeCarrierDetails('expReason', e.target.value)}
                                        >
                                            <MenuItem value={0}>Pick an Option</MenuItem>
                                            {props.staticData.dropdown.ReasonForExpense.value.map((method) => (
                                                <MenuItem key={method.value} value={method.value}>{method.name}</MenuItem>
                                            ))}
                                        </Select>
                                        {((carrierDetails.expReason === 0 || carrierDetails.expReason === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                                            : null}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Subheader>For Agency Payment Purposes Only</Subheader>
                            <Grid container className={classes.filters}>
                               
                                <Grid item xs={10} sm={5} className={classes.textfield}>
                                    <span className={classes.textLabel}>General Ledger Code </span>
                                    <TextField
                                        id="ledgerCode"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        disabled
                                        className="disabled"
                                        value={(carrierDetails.ledgerCode === null || carrierDetails.ledgerCode === 0) ? '' : carrierDetails.ledgerCode}
                                    />
                                </Grid>
                            </Grid>
                        <Grid container className={classes.filters}>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                    <span className={classes.textLabel}>Internal Order</span>
                                    <TextField
                                    variant="outlined"
                                    size="small"
                                    id="expinternalOrder"
                                    fullWidth
                                    disabled
                                    className="disabled"                                    
                                    value={carrierDetails.medInternalOrder === null ? '' : carrierDetails.medInternalOrder}                                    
                                    />
                            </Grid>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <span className={classes.textLabel}>Cost Center</span>
                                    <TextField
                                    variant="outlined"
                                    size="small"
                                    id="expcostCenter"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={carrierDetails.medCostCenter === null ? '' : carrierDetails.medCostCenter}                                    
                                    />
                            </Grid>
                        </Grid>
                            <Grid container className={classes.filters}>
                                <Grid item xs={10} sm={5} className={classes.textfield}>
                                    <FormControl variant="outlined" size="small" className={classes.formControl} error={carrierDetails.state === 0 || carrierDetails.state === null}>
                                        <span className={classes.textLabel}>State</span>
                                        <Select
                                            id="state-select"
                                            value={carrierDetails.state === null ? 0 : carrierDetails.state}
                                            className={readOnly ? "disabled" : "required"}
                                            disabled={readOnly}
                                            onChange={(e) => changeCarrierDetails('state', e.target.value)}
                                        >
                                            <MenuItem value={0}>Pick an Option</MenuItem>
                                            {states.sort().map((method) => (
                                                <MenuItem key={method} value={method}>{method}</MenuItem>
                                            ))}
                                        </Select>
                                        {((carrierDetails.state === 0 || carrierDetails.state === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                                            : null}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={10} sm={5} className={classes.textfield}>
                                    <FormControl variant="outlined" size="small" className={classes.formControl} error={carrierDetails.city === 0 || carrierDetails.city === null}>
                                        <span className={classes.textLabel}>City</span>
                                        <Select
                                            id="city-select"
                                            value={carrierDetails.city === null ? 0 : carrierDetails.city}
                                            className={readOnly ? "disabled" : "required"}
                                            disabled={readOnly}
                                            onChange={(e) => changeCarrierDetails('city', e.target.value)}
                                        >
                                            <MenuItem value={0}>Pick an Option</MenuItem>
                                            {(carrierDetails.state === 0 || carrierDetails.state === null) ? null : cities.map((value) => (
                                                <MenuItem key={value} value={value}>{value}</MenuItem>
                                            ))}
                                        </Select>
                                        {((carrierDetails.city === 0 || carrierDetails.city === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                                            : null}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container className={classes.filters}>
                                <Grid item xs={10} sm={5} className={classes.textfield}>
                                    <FormControl variant="outlined" size="small" className={classes.formControl} error={carrierDetails.carrierType === 0 || carrierDetails.carrierType === null}>
                                        <span className={classes.textLabel}>Carrier Type</span>
                                        <Select
                                            id="carrier-select"
                                            value={carrierDetails.carrierType === null ? 0 : carrierDetails.carrierType}
                                            className={readOnly ? "disabled" : "required"}
                                            disabled={readOnly}
                                            onChange={(e) => changeCarrierDetails('carrierType', e.target.value)}
                                        >
                                            <MenuItem value={0}>Pick an Option</MenuItem>
                                            {props.staticData.dropdown.CarrierTypes.value.sort((a, b) => a.name > b.name ? 1:-1).map((method) => (
                                                <MenuItem key={method.value} value={method.value}>{method.name}</MenuItem>
                                            ))}
                                        </Select>
                                        {((carrierDetails.carrierType === 0 || carrierDetails.carrierType === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                                            : null}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={10} sm={5} className={classes.textfield}>
                                    <span className={classes.textLabel}>Amount</span>
                                    <TextField
                                        id="expAmount"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        className={readOnly ? "disabled" : "required"}
                                        disabled={readOnly}
                                        value={carrierDetails.amount === null ? '' : parseFloat(carrierDetails.amount).toFixed(2)}
                                        error={carrierDetails.amount === '' || carrierDetails.amount === null}
                                        onChange={(e) => (e.target.value !== '') ? changeCarrierDetails('amount', parseFloat(e.target.value).toFixed(2)) : changeCarrierDetails('amount', null)}
                                        onBlur={() => changeReimAmount()}
                                        InputProps={{
                                            inputComponent: AmountFormatCustom,
                                        }}
                                        helperText={((carrierDetails.amount === '' || carrierDetails.amount === null) && error) ? "Required Field" : null}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className={classes.filters}>
                                <Grid item xs={10} sm={5} className={classes.textfield}>
                                    <span className={classes.textLabel}>Reimbursement Amount</span>
                                    <TextField
                                        id="reimbAmount"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        disabled
                                        className="disabled"
                                        value={"$" + (carrierDetails.reimbAmount === null ? 0 : parseFloat(carrierDetails.reimbAmount).toFixed(2))}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className={classes.filters}>
                                <Grid item xs={12} className={classes.textfield}>
                                    <FormControl component="fieldset">
                                        <RadioGroup row aria-label="coachfare-sw" name="coachfare-sw" className={readOnly ? "disabled" : "required"}
                                            value={carrierDetails.coachFare} onChange={(e) => changeCarrierDetails('coachFare', e.target.value)}>
                                            <FormLabel component="legend" className={classes.radioLabel}>Coach Fare?</FormLabel>
                                            <div>
                                                <FormControlLabel value="Y" control={<Radio disabled={readOnly} color="primary" />} label="Yes" />
                                                <FormControlLabel value="N" control={<Radio disabled={readOnly} color="primary" />} label="No" />
                                            </div>
                                        </RadioGroup>
                                    </FormControl>
                                    {((carrierDetails.coachFare === '' || carrierDetails.coachFare === null) && error) ? <FormHelperText error>Required Field</FormHelperText>
                                        : null}
                                </Grid>
                            </Grid>
                            {props.userInfo.role !== 'PR' ? <Grid container className={classes.filters}>
                                <Grid item xs={12} className={classes.textfield}>
                                    <FormControl component="fieldset">
                                        <RadioGroup row aria-label="stateCard-sw" name="stateCard-sw" className={readOnly ? "disabled" : null}
                                            defaultValue={(carrierDetails.dhsCardSw == 'D' || carrierDetails.dhsCardSw == 'C') ? carrierDetails.dhsCardSw : 'P'} onChange={(e) => changeCarrierDetails('dhsCardSw', e.target.value)}>
                                            <FormLabel component="legend" className={classes.radioLabel}>How have you paid for this expense?</FormLabel>
                                            <div>
                                                <FormControlLabel value="P" control={<Radio disabled={readOnly} color="primary" />} label="Personal" />
                                                <FormControlLabel value="D" control={<Radio disabled={readOnly} color="primary" />} label="Direct Billing" />
                                                <FormControlLabel value="C" control={<Radio disabled={readOnly} color="primary" />} label="DHS Credit Card" />
                                            </div>
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid> : null}
                            {carrierDetails.dhsCardSw === 'D' || carrierDetails.dhsCardSw === 'C' ? <div>
                                <Grid container className={classes.filters}>
                                    <Grid item xs={10} sm={5} className={classes.textfield}>
                                        <span className={classes.textLabel}>Direct Billing/DHS Credit Card Amount</span>
                                        <TextField
                                            id="stateCardAmnt"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            className={"disabled"}
                                            disabled={true}
                                            value={carrierDetails.dhsCardAmount === null ? '' : parseFloat(carrierDetails.dhsCardAmount).toFixed(2)}
                                            error={carrierDetails.dhsCardAmount === '' || carrierDetails.dhsCardAmount === null}
                                           
                                            InputProps={{
                                                inputComponent: AmountFormatCustom,
                                                readOnly:true
                                            }}
                                            helperText={((carrierDetails.dhsCardAmount === '' || carrierDetails.dhsCardAmount === null) && error) ? "Required Field" : null}
                                        />
                                    </Grid>
                                </Grid>
                            </div> : null}
                            <Grid container className={classes.filters}>
                                <Grid item xs={12} className={classes.textfield}>
                                    <FormControl component="fieldset">
                                        <RadioGroup row aria-label="medicaid-sw" name="medicaid-sw" value={carrierDetails.medSw} onChange={(e) => changeCarrierDetails('medSw', e.target.value)}>
                                            <FormLabel component="legend" className={classes.radioLabel}>Is this Medicaid ?</FormLabel>
                                            <div>
                                                <FormControlLabel value="Y" control={<Radio disabled={readOnly || allowMedicaid} color="primary" />} label="Yes" />
                                                <FormControlLabel value="N" control={<Radio disabled={readOnly || allowMedicaid} color="primary" />} label="No" />
                                            </div>
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {carrierDetails.medSw === 'Y' ? <div>
                                <Subheader>Medicaid Details</Subheader>
                                <Grid container className={classes.filters}>
                                    <Grid item xs={10} sm={5} className={classes.textfield}>
                                        <span className={classes.textLabel}>First Name</span>
                                        <TextField
                                            id="firstName"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            className={readOnly ? "disabled" : "required"}
                                            disabled={readOnly}
                                            value={carrierDetails.medFirstname === null ? '' : carrierDetails.medFirstname}
                                            error={carrierDetails.medFirstname === '' || carrierDetails.medFirstname === null}
                                            onChange={(e) => changeCarrierDetails("medFirstname", e.target.value)}
                                            helperText={((carrierDetails.medFirstname === '' || carrierDetails.medFirstname === null) && medError) ? "Required Field" : null}
                                        />
                                    </Grid>
                                    <Grid item xs={10} sm={5} className={classes.textfield}>
                                        <span className={classes.textLabel}>Last Name</span>
                                        <TextField
                                            id="lastName"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            className={readOnly ? "disabled" : "required"}
                                            disabled={readOnly}
                                            value={carrierDetails.medLastname === null ? '' : carrierDetails.medLastname}
                                            error={carrierDetails.medLastname === '' || carrierDetails.medLastname === null}
                                            onChange={(e) => changeCarrierDetails("medLastname", e.target.value)}
                                            helperText={((carrierDetails.medLastname === '' || carrierDetails.medLastname === null) && medError) ? "Required Field" : null}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container className={classes.filters}>
                                    <Grid item xs={10} sm={5} className={classes.datepicker}>
                                        <span className="datepickerLabel">Date of Birth</span>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                            <KeyboardDatePicker
                                                //disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                id="dob-date"
                                                autoOk={true}
                                                className={readOnly ? "disabled" : "required"}
                                                disabled={readOnly}
                                                size="small"
                                                fullWidth
                                                value={carrierDetails.medDob}
                                                error={(carrierDetails.medDob === null) || (!moment(carrierDetails.medDob, 'MM/DD/YYYY').isValid())}
                                                onChange={(date) => changeCarrierDetails('medDob', date)}
                                                maxDate={new Date()}
                                                placeholder="MM/DD/YYYY"
                                                KeyboardButtonProps={{
                                                    'aria-label': 'dob date',
                                                }}
                                                inputVariant="outlined"
                                                helperText={((carrierDetails.medDob === null && medError)) ? "Required Field" : null}
                                            />
                                        </MuiPickersUtilsProvider>
                                        {(carrierDetails.medDob !== null && !moment(carrierDetails.medDob, 'MM/DD/YYYY').isValid()) ? <FormHelperText error>Invalid Date Format</FormHelperText>
                                            : null}
                                    </Grid>
                                    <Grid item xs={10} sm={5} className={classes.textfield}>
                                        <span className={classes.textLabel}>Medicaid Number</span>
                                        <TextField
                                            id="medNum"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            className={readOnly ? "disabled" : "required"}
                                            disabled={readOnly}
                                            value={carrierDetails.medId === null ? '' : carrierDetails.medId}
                                            error={carrierDetails.medId === '' || carrierDetails.medId === null}
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            onChange={(e) => changeCarrierDetails("medId", e.target.value)}
                                            helperText={((carrierDetails.medId === '' || carrierDetails.medId === null) && medError) ? "Required Field" : null}
                                        />
                                    </Grid>
                                </Grid>
                                <Subheader>For Agency Payment Purposes Only</Subheader>
                                <Grid container className={classes.filters}>
                                
                                    <Grid item xs={10} sm={5} className={classes.textfield}>
                                        <span className={classes.textLabel}>General Ledger Code </span>
                                        <TextField
                                            id="ledgerCode"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            disabled
                                            className="disabled"
                                            value={(carrierDetails.ledgerCode === null || carrierDetails.ledgerCode === 0) ? '' : carrierDetails.ledgerCode}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container className={classes.filters}>
                                    <Grid item xs={10} sm={5} className={classes.textfield}>
                                        <span className={classes.textLabel}>Internal Order</span>
                                        <TextField
                                            id="internalOrder"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            className="disabled"
                                            //value={(new Date(carrierDetails.depDate).getMonth() < 9 && new Date(carrierDetails.depDate).getFullYear() < new Date().getFullYear()) ? props.staticData.dropdown["MedicaidDetails" + (new Date().getFullYear() - 1)].value.find((config) => config.value === 'IO').name : props.staticData.dropdown.MedicaidDetails.value.find((config) => config.value === 'IO').name}
                                            value={carrierDetails.medInternalOrder === null ? '' : carrierDetails.medInternalOrder}
                                        />
                                    </Grid>
                                    <Grid item xs={10} sm={5} className={classes.textfield}>
                                        <span className={classes.textLabel}>Cost Center</span>
                                        <TextField
                                            id="costCenter"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            className="disabled"
                                            //value={(new Date(carrierDetails.depDate).getMonth() < 9 && new Date(carrierDetails.depDate).getFullYear() < new Date().getFullYear()) ? props.staticData.dropdown["MedicaidDetails" + (new Date().getFullYear() - 1)].value.find((config) => config.value === 'CO').name : props.staticData.dropdown.MedicaidDetails.value.find((config) => config.value === 'CO').name}                                           
                                            value={carrierDetails.medCostCenter === null ? '' : carrierDetails.medCostCenter}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
    <Subheader>Upload Medicaid Documents</Subheader>
</Grid>

<Grid container style={{ padding: "0 10px" }}>
    <Grid item xs={12}>
        <Box style={{ float: 'right', margin: "20px 10px 10px 20px" }}>
            <Button 
                variant="outlined" 
                color="primary" 
                size="small" 
                className={readOnly ? "disabled" : null}
                disabled={readOnly}
            >
                <span style={{ position: 'absolute', maxWidth: '210px' }}>Select File & Upload</span>
                <input
                    color="primary"
                    type="file"
                    onChange={(event) => upload(event.target, "Medicaid")}
                    id="upload-medicaid-file"  /* Unique ID for Medicaid Upload */
                    style={{ opacity: 0, maxWidth: '210px', cursor: 'pointer' }}
                />
            </Button>
        </Box>
        <Box pr={"10px"}>
            <Gridtable 
                columns={medicaidUploadColumns} 
                data={medicaidDocuments
                    .filter((doc) => doc.delSw !== 'Y' && doc.medSw === 'Y')} // Only Medicaid documents with medSw as 'Y'
            />
        </Box>
    </Grid>
</Grid> 
</Grid>
                                <div style={{ textAlign: 'center', paddingBottom: 10 }}>
                                    <Button style={{ margin: "20px 5px 5px 20px" }} variant="contained" color="primary" size="small" className={readOnly ? "disabled" : null} 
                                     disabled={readOnly || [...medicaidDocuments].filter((doc) => doc.delSw !== 'Y').length === 0}
                                    onClick={() => validateMedicaid()}>Verify</Button>
                                </div>
                            </div> : null}

                            <Subheader>Upload Documents</Subheader>
                            <Grid container style={{ padding: "0 10px" }}>
                                <Grid item xs={12}>
                                    <Box style={{ float: 'right', margin: "20px 10px 10px 20px" }}>
                                        <Button variant="outlined" color="primary" size="small" className={readOnly ? "disabled" : null}
                                            disabled={readOnly}>
                                            <span style={{ position: 'absolute', maxWidth: '210px'  }}>Select FIle & Upload</span>
                                            <input
                                                color="primary"
                                                type="file"
                                                onChange={(event) => upload(event.target, "NonMedicaid")}
                                                id="upload-file"
                                                style={{ opacity: 0, maxWidth: '210px', cursor: 'pointer' }}
                                            />
                                        </Button>
                                    </Box>
                                    <Box pr={"10px"}>
                                        <Gridtable columns={uploadColumns} data={documents.filter((doc) => doc.delSw !== 'Y')}></Gridtable>
                                    </Box>
                                </Grid>
                            </Grid>
                   
                                
                            {((carrierDetails.status !== 'IP') && (props.reimbursement.assignedTo !== null) && (props.reimbursement.assignedTo === props.userInfo.id)) ? <div>
                                <Subheader>Travel Supervisor Status Details</Subheader>
                                <Grid container className={classes.filters}>
                                    <Grid item xs={10} sm={3} className={classes.textfield}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={carrierDetails.nonMedStatus === 'AP'}
                                                    onChange={() => changeCarrierDetails('nonMedStatus', 'AP')}
                                                    name="Approve"
                                                    color="primary"
                                                    className={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : null}
                                                    disabled={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id) ||  props.staticData.dropdown.MedicaidApprovers.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0 || props.staticData.dropdown.PaymentApprover.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0)}
                                                />
                                            }
                                            label="Approve"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                    <Grid item xs={10} sm={3} className={classes.textfield}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={carrierDetails.nonMedStatus === 'RR'}
                                                    onChange={() => changeCarrierDetails('nonMedStatus', 'RR')}
                                                    name="Return for Revision"
                                                    color="primary"
                                                    className={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : null}
                                                    disabled={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id) ||  props.staticData.dropdown.MedicaidApprovers.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0 || props.staticData.dropdown.PaymentApprover.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0 ) }
                                                />
                                            }
                                            label="Return for Revision"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                </Grid>

           
                                
                                {carrierDetails.nonMedStatus === 'RR' ? <Grid container className={classes.filters}>
                                    <Grid item xs={10} sm={5} className={classes.textfield}>
                                        <FormControl variant="outlined" size="small" className={classes.formControl} error={carrierDetails.nonMedStatusRevReason === 0 || carrierDetails.nonMedStatusRevReason === null}>
                                            <span className={classes.textLabel}>Reason for Revision</span>
                                            <Select
                                                id="reason-select"
                                                value={carrierDetails.nonMedStatusRevReason === null ? 0 : carrierDetails.nonMedStatusRevReason}
                                                className={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : "required"}
                                                disabled={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id) )}
                                                onChange={(e) => changeCarrierDetails('nonMedStatusRevReason', e.target.value)}
                                            >
                                                <MenuItem value={0}>Pick an Option</MenuItem>
                                                {props.staticData.dropdown.ReasonForRevision.value.map((method) => (
                                                    <MenuItem key={method.value} value={method.value}>{method.name}</MenuItem>
                                                ))}
                                            </Select>
                                            {((carrierDetails.nonMedStatusRevReason === 0 || carrierDetails.nonMedStatusRevReason === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                                                : null}
                                        </FormControl>
                                    </Grid>
                                </Grid> : null}
                            </div> : null}

            { ( carrierDetails.mediStatus === 'XX' &&  props.reimbursement.medSupervisor === null ) ? 
               <div>
                    <Subheader>Medicaid Status Detail</Subheader>
                <Grid container className={classes.filters}>
                <Grid item xs={10} sm={3} className={classes.textfield}>
                    <FormControlLabel
                        control={
                            <Checkbox
                            checked={carrierDetails.mediStatus === 'AP'}
                            name="Approve"
                            color="primary"
                            className ="disabled"
                            disabled
                            />
                        }
                        label="Approve"
                        labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={10} sm={3} className={classes.textfield}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={carrierDetails.mediStatus === 'RR' || carrierDetails.mediStatus === 'XX'}
                                name="Return for Revision"
                                color="primary"
                                className = "disabled"
                                disabled 
                            />
                        }
                        label="Return for Revision"
                        labelPlacement="end"
                    />
                </Grid>
            </Grid>
            
            <Grid container className={classes.filters}>
                    <Grid item xs={10} sm={5} className={classes.textfield}>
                        <FormControl variant="outlined" size="small" className={classes.formControl} error={carrierDetails.mediStatusRevReason === 0 || carrierDetails.mediStatusRevReason === null}>
                            <span className={classes.textLabel}>Reason for Revision</span>
                            <Select
                                id="reason-select"
                                value={carrierDetails.mediStatusRevReason === null ? 0 : carrierDetails.mediStatusRevReason}
                                className =  "disabled" 
                                disabled
                            >
                                <MenuItem value={0}>Pick an Option</MenuItem>
                                {props.staticData.dropdown.ReasonForRevision.value.map((method)=>(
                                    <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                                ))}
                            </Select>                                             
                        </FormControl>                      
                    </Grid> 
                    </Grid> 
                     </div>
                : null }

                            {((carrierDetails.status !== 'IP') && (props.reimbursement.assignedTo !== null) && (props.reimbursement.medSupervisor !== null) && ( (props.reimbursement.medSupervisor.toString() === props.userInfo.id.toString()) && (carrierDetails.medSw === 'Y')) || (props.staticData.dropdown.PaymentApprover.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0 && carrierDetails.medSw === 'Y')) ? <div>
                                <Subheader>{"Medicaid Status Details"}</Subheader>
                                <Grid container className={classes.filters}>
                                    <Grid item xs={10} sm={3} className={classes.textfield}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={carrierDetails.mediStatus === 'AP'}
                                                    onChange={() => changeCarrierDetails('mediStatus', 'AP')}
                                                    name="Approve"
                                                    color="primary"
                                                    className={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : null}
                                                    disabled={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id) || props.staticData.dropdown.PaymentApprover.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0)}
                                                />
                                            }
                                            label="Approve"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                    <Grid item xs={10} sm={3} className={classes.textfield}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={carrierDetails.mediStatus === 'RR' || carrierDetails.mediStatus === 'XX'}
                                                    onChange={() => changeCarrierDetails('mediStatus', 'RR')}
                                                    name="Return for Revision"
                                                    color="primary"
                                                    className={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : null}
                                                    disabled={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id) || props.staticData.dropdown.PaymentApprover.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0)}
                                                />
                                            }
                                            label="Return for Revision"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                </Grid>
                                {carrierDetails.mediStatus === 'RR' || carrierDetails.mediStatus === 'XX' ? <Grid container className={classes.filters}>
                                    <Grid item xs={10} sm={5} className={classes.textfield}>
                                        <FormControl variant="outlined" size="small" className={classes.formControl} error={carrierDetails.revReason === 0 || carrierDetails.revReason === null}>
                                            <span className={classes.textLabel}>Reason for Revision</span>
                                            <Select
                                                id="reason-select"
                                                value={carrierDetails.mediStatusRevReason === null ? 0 : carrierDetails.mediStatusRevReason}
                                                className={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : "required"}
                                                disabled={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id))}
                                                onChange={(e) => changeCarrierDetails('mediStatusRevReason', e.target.value)}
                                            >
                                                <MenuItem value={0}>Pick an Option</MenuItem>
                                                {props.staticData.dropdown.ReasonForRevision.value.map((method) => (
                                                    <MenuItem key={method.value} value={method.value}>{method.name}</MenuItem>
                                                ))}
                                            </Select>
                                            {((carrierDetails.mediStatusRevReason === 0 || carrierDetails.mediStatusRevReason === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                                                : null}
                                        </FormControl>
                                    </Grid>
                                </Grid> : null}
                            </div> : null}
                            <div style={{ textAlign: 'center', paddingBottom: 10 }}>
                                <Button style={{ margin: "20px 5px 5px 20px" }} variant="contained" color="primary" size="small" disabled = {["SB","AP","PO","PP"].indexOf(props.reimbursement.status)  > -1 && (props.reimbursement.userId === props.userInfo.id)} onClick={() => reset()}>Reset</Button>
                                <Button style={{ margin: "20px 5px 5px 20px" }} variant="contained" color="primary" size="small" disabled = {["SB","AP","PO","PP"].indexOf(props.reimbursement.status)  > -1  && (props.reimbursement.userId === props.userInfo.id)} onClick={() => saveCarriers()}>Save</Button>
                            </div>
                        </div>
                    </Route>
                </Switch>
            </div>
        )

    }
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
/*import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';*/
import MenuIcon from '@material-ui/icons/Menu';
/*import Summary from './summary';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import Config from '../assets/config';*/
import {postFetch} from '../http';
import Loader from '../loader';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
/*import LinearProgress from '@material-ui/core/LinearProgress';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';*/
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
/*import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import AssessmentIcon from '@material-ui/icons/Assessment';
import EventNoteIcon from '@material-ui/icons/EventNote';
import TimerIcon from '@material-ui/icons/Timer';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import BlockIcon from '@material-ui/icons/Block';
import CommentIcon from '@material-ui/icons/Comment';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PrintIcon from '@material-ui/icons/Print';
import CallToActionIcon from '@material-ui/icons/CallToAction';*/
import {  Switch, Route } from "react-router-dom";
import { useHistory  } from "react-router-dom";
import TravelInfo from './travelInfo';
import PreApproval from './PreApproval';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  list:{
    [theme.breakpoints.down('sm')]:{
      display:'none'
    }
  },
  leftMenu:{
    zIndex:800,
    marginTop:70,
    boxShadow:'0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    [theme.breakpoints.up('sm')]:{
      width:300
    },
    [theme.breakpoints.down('sm')]:{
      width:50
    }
  },
  toggleLeft:{
    zIndex:800,
    marginTop:55,
    width:300
  },
  menuItem:{
    borderRadius: 5,
    padding: '5px 15px',
    margin:'10px 0',
    "& .MuiTypography-body1":{
      fontWeight:500
    },
    "& .MuiListItemIcon-root":{
      minWidth:45,
      color:'#444'
    },
    transition:'border 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  },
  content: {
    flexGrow: 1,
    backgroundColor:"#fff",
    marginBottom: 50,
    [theme.breakpoints.up('sm')]:{
      marginLeft:310
    },
    [theme.breakpoints.down('sm')]:{
      marginLeft:60
    },
    boxShadow:'0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 0px 0px rgba(0,0,0,0.12)'
  },
  menuIcon:{
    cursor:'pointer',
    color:'#3f51b5',
    [theme.breakpoints.up('sm')]:{
      display:'none'
    },
    [theme.breakpoints.down('sm')]:{
     display:'inline-block'
    }
  },
  toggleMenu:{
    display:'none'
  },
  navContent:{
    width:'100%',
    height:'100%'
  },
  profile:{
    textAlign: 'center',
    fontWeight: 900,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    backgroundColor: '#1a75c9',
    padding: '15px 0',
    fontSize:14,
    color: '#fff'
  },
  textLabel:{
    fontSize: '15px',
    fontWeight: 'bold',
  },
  filters:{
    margin:'30px 15px',
    width:"90%"
  },
  logoutButton:{
    margin:'10px',
    color: '#fff',
    border: '1px solid #fff',
    "&:hover":{
        border: '1px solid #fff'
    }
  }
}));

export default function TR1Info(props) {
  const classes = useStyles();
  const history = useHistory();
  const [leftNav, setLeftNav] = React.useState(true);
  const [referenceData, setReferenceData] = React.useState(null);
  const [loader,setLoader] = React.useState({loader:false,notification:false,message:"success",validationMessage:"Success"});
  const [travellerInfo, setTravellerInfo] = React.useState({firstName:'',lastName:'',positionTitle:'',vendorNum:'',empInternalOrder:'',empCostCenter:'',empAreaDescription:'',divisionCode:'',aasisempNum:'',accountActive:''});
  const [personInfo] = React.useState({});
  const [view] = React.useState(true);
  const [onInit, setOnInit] = React.useState(true);

  /*const leftNavigation = (event, index, path)=>{
    setSelectedIndex(index);
    history.push("/redirect");
    history.push("/tr1/"+path.toLowerCase().replace(/\s/g, ''));
  }*/

  const changeLeftNav = (value)=>{
    (value === 0) ?
    setLeftNav(true):
    setLeftNav(false);
  }
  React.useEffect(() => {
    const loadleftNav =  async ()=>{
      setLoader({...loader,loader:true,notification:false,message:"success",validationMessage:"success" });
      setOnInit(false);    
      
      const request = {
          eMAILADD: (props.userDetails.role === 'PR') ? props.userDetails.email : null,
          vendorNum: (props.userDetails.role === 'PR') ? props.userDetails.vendorNum : null,
          appId:parseInt(localStorage.getItem('appId')),
          nTID: (props.userDetails.role === 'PR') ? null : props.userDetails.username,
          requestId:(localStorage.getItem('requestId') !== null) ? parseInt(localStorage.getItem('requestId')) : null,
          preApproval:(localStorage.getItem('preApproval') !== null) ? parseInt(localStorage.getItem('preApproval')) : null

        }
       await postFetch("/travellerInfo/load",request).then((data)=>{
            if(!data.message && (data.travellerInfo !== null)){
                let configdata = {dropdown:{},reference:{}};
                  data.configurations.configurations.forEach((config)=>{
                    config.value = JSON.parse(config.value);
                    configdata.reference[config.configName] = config;
                    let filteredConfig ={...config};
                    filteredConfig.value = filteredConfig.value.filter((value)=> value.activeSW.toString().trim() === 'Y' && (new Date(value.activeBeginDt) <= new Date()));
                    configdata.dropdown[filteredConfig.configName] = filteredConfig;
                  })
                setReferenceData({...configdata});
                setTravellerInfo(data.travellerInfo);
                setTimeout(()=>{                         
                  setLoader({...loader,loader:false,notification:false });                                 
              },1000);
            }
            else if(data.travellerInfo === null){
              setTimeout(()=>{                         
                setLoader({...loader,loader:false,notification:true,message:"error",validationMessage:"Unable to load User Details" });                                 
              },1000);
            }
            else{
                setTimeout(()=>{                         
                    setLoader({...loader,loader:false,notification:true,message:"error",validationMessage:data.message });                                 
                },1000);
            }
        }).catch((e)=>{
          setTimeout(()=>{                          
              setLoader({...loader,loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
          },1000);
        });
    }
    if(onInit && props.userDetails.role !== null){
      loadleftNav();
    }
  }, [onInit,loader,props.userDetails]);



  const loaderAction = async()=>{
    setLoader(prevState => {
      return {...prevState.loader,loader : false, notification:false}                                 
      });
  };

  const changeLoader = (value)=>{
    setLoader(value);
  }

  return (
    <div className={classes.root}>
      <Loader loader={loader.loader} notification = {loader.notification} message={loader.message} action={loaderAction} validationMessage = {loader.validationMessage}/>
      <div className={classes.navContent}>
        <Drawer
          variant={leftNav ? "permanent" : "temporary"}
          anchor={"left"}
          open={true}
          classes={{
            paper:leftNav ? classes.leftMenu : classes.toggleLeft
          }}
          onClose={()=>changeLeftNav(0)}
        >
          <div className={ leftNav ? classes.list : null}>
            <div className={classes.profile}>
              <AccountCircleRoundedIcon style={{fontSize:50}}/>
              {personInfo !== null ? <p style={{margin:0}}>{travellerInfo.firstName + " " + travellerInfo.lastName}</p> : null}<br/>
              {((window.location.pathname !== "/tr1") && (window.location.pathname !== "/preApproval")) ? 
              //<Button variant="outlined" size="small" className={classes.logoutButton} onClick={()=> history.push("/tr1")}>
              <Button variant="outlined" size="small" className={classes.logoutButton} onClick={()=> window.location.href = '/tr1'}>
                <ArrowBackIosIcon style={{fontSize:"14px"}}/>Back to Summary
              </Button> : null}
            </div>
                      {(props.userDetails.role !== 'PR' && travellerInfo.positionTitle !== null) ?<Grid container className={classes.filters}>
                <Grid item xs={12}>
                    <TextField
                                      id="title"
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                      multiline
                                      value={"Employee Title: "+travellerInfo.positionTitle}
                                      className="highlightedInput"
                                      disabled
                                  />
                </Grid>
            </Grid>:null}
           
                      {(props.userDetails.role !== 'PR' && travellerInfo.aasisempNum !== null) ? <Grid container className={classes.filters}>
                <Grid item xs={12}>
                    <TextField
                                      id="empNum"
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                      value={"AASIS Employee Number: "+travellerInfo.aasisempNum}
                                      className="highlightedInput"
                                      disabled
                                  />
                </Grid>
            </Grid>: null}
            {(props.userDetails.role !== 'PR' && travellerInfo.accountActive !== null && travellerInfo.accountActive === '0') ? <Grid container className={classes.filters}>
                <Grid item xs={12}>
                    <TextField
                                      id="empStatus"
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                      value={"Status: Terminated Employee"}
                                      className="highlightedInput"
                                      disabled
                                  />
                </Grid>
            </Grid>: null}
            <Grid container className={classes.filters}>
                <Grid item xs={12}>
                    <TextField
                                      id="vendorNum"
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                      value={"AASIS Vendor Number: "+travellerInfo.vendorNum}
                                      className="highlightedInput"
                                      disabled
                                  />
                </Grid>
            </Grid>
                      {(travellerInfo.divisionCode !== null && (props.userDetails.role !== 'PR')) ? <Grid container className={classes.filters}>
                          <Grid item xs={12}>
                              <TextField
                                  id="division"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  value={"Division: " + ((props.userDetails.role !== 'PR') ? travellerInfo.divisionCode : props.userDetails.division)}
                                  className="highlightedInput"
                                  disabled
                              />
                          </Grid>
                      </Grid> : null}

                      {(props.userDetails.role !== 'PR' && travellerInfo.empAreaDescription !== null) ? <Grid container className={classes.filters}>
                <Grid item xs={12}>
                    <TextField
                                      id="station"
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                      value={"Official Station: "+travellerInfo.empAreaDescription}
                                      className="highlightedInput"
                                      disabled
                                  />
                </Grid>
            </Grid>: null}
                      {(props.userDetails.role !== 'PR' && travellerInfo.empInternalOrder !== null) ? <Grid container className={classes.filters}>
                <Grid item xs={12}>
                    <TextField
                                      id="internalOrder"
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                      value={"Internal Order: "+travellerInfo.empInternalOrder}
                                      className="highlightedInput"
                                      disabled
                                  />
                </Grid>
            </Grid>: null}
                      {(props.userDetails.role !== 'PR' && travellerInfo.empCostCenter !== null) ? <Grid container className={classes.filters}>
                <Grid item xs={12}>
                    <TextField
                                      id="cstCnt"
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                      value={"Cost Center: "+travellerInfo.empCostCenter}
                                      className="highlightedInput"
                                      disabled
                                  />
                </Grid>
            </Grid>: null}
          </div>
          <MenuIcon className={leftNav ? classes.menuIcon : classes.toggleMenu} onClick={()=>changeLeftNav(1)}/>
        </Drawer>
        {view ? <div className={classes.content}>
          <Switch>
            <Route path="/tr1">
              {(referenceData !== null && travellerInfo.aasisempNum !== '') ? <TravelInfo staticData = {referenceData} loader={changeLoader} pageInfo ={props.pageInfo} userInfo={props.userDetails} travellerInfo={travellerInfo}/> : null}
            </Route>
            <Route path="/preApproval">
            {(referenceData !== null && travellerInfo.aasisempNum !== '') ? <PreApproval staticData = {referenceData} loader={changeLoader} userInfo={props.userDetails} travellerInfo={travellerInfo} supervisorData={props.supervisorData}/> : null}
            </Route>
          </Switch>
        </div> : null}
      </div>
    </div>
  );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Gridtable from '../gridtable';
import Typography from '@material-ui/core/Typography';
//import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {  Route } from "react-router-dom";
import {postFetch} from '../http';
/*import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import Config from '../assets/config';

import {  Switch, Route } from "react-router-dom";

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import FormHelperText from '@material-ui/core/FormHelperText';*/
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
/*import InputLabel from '@material-ui/core/InputLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';*/
import { useHistory  } from "react-router-dom";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
//import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import '../App.css';
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormLabel } from '@material-ui/core';
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    width: '100%',
    "& .MuiTab-textColorPrimary.Mui-selected":{
        fontWeight:600
    }
  },
  header:{
    backgroundColor:"#1a75c9",
    color:"#fff",
    padding:10,
    fontWeight:900,
    fontSize:15
  },
  filters:{
    "& .MuiFormLabel-root.Mui-focused, .MuiFormLabel-root":{
      color:"#000",
      fontSize:17
    },
    margin:'20px 0',
    padding:'0 10px'
  },
  textfield:{
      padding:'10px 10px 10px 0'
  },
  radioLabel:{
    position: 'relative',
    top: 7,
    marginRight: 5,
    fontSize: '15px !important'
  },
  formControl:{
    width:"90%"
  },
  textLabel:{
    position: 'relative',
    bottom: '8px',
    fontSize: '14px',
    fontWeight: '500'
  },
  statusDetailsMaring:{
    marginLeft: '12px !important'
  },
}));

const Subheader = ({children}) =>{
    return (
        <div className="wrapper">
            <p>{children}</p>
            <div className="line"></div>
        </div>
    )
}

/*function MonthFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format="##/####"
        mask="_"
      />
    );
  }*/

export default function SummaryReconcile(props) {
  const classes = useStyles();
  const history = useHistory();
  const [error,setError] = React.useState(false);
  const [preApprovalData, setPreApprovalData] = React.useState([{type:"Out of State Travel",attachements:[],key:"OS",value:null},{type:"Outside of Normal work hours",attachements:[],key:"OW",value:null},{type:"Exceed Maximum Lodging Rate",attachements:[],key:"EM",value:null}]);
  const [onInit,setOnInit] = React.useState(true);
  const [ summary, setSummary ] = React.useState([]);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [reimbursementDetails, setReimbursementDetails] = React.useState({status:"IP",assignedTo:null,revReason:null,costCenter:null,internalOrder:null,osPreApproval:null,owPreapproval:null,emPreapproval:null,notes:null,medSupervisor:null, travelSupervisor: null, paySupervisor: null, delSw:'N' });
  const [userDetails, setUserDetails] = React.useState([]);
  const [approvalStatus, setApprovalStatus] = React.useState(true);
  const [medicaidSw, setMedicaidSw] = React.useState(false);
  const [saveSw, setSaveSw] = React.useState(false);
  const [medSupvervisorSw, setMedSupvervisorSw ]  = React.useState(false);
  const [medChange, setMedChange ]  = React.useState(true);
  const [assignedTo, setAssignedTo] = React.useState(null);
  const [defaultReimbursement, setDefaultReimbursement] = React.useState({});
  
  React.useEffect(() => {    
    window.scrollTo(0,0);
    const loadSummary =  async ()=>{
      props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
      setOnInit(false);
      const request = { ...props.reimbursement };
      const rem = { ...props.reimbursement };
        request.med =   props.staticData.dropdown.MedicaidApprovers.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0;
        request.county = props.userInfo.role === 'PR' ? props.userInfo.county : null;
      request.expenseStartDate = new Date(request.expenseStartDate);
      request.expenseEndDate = new Date(request.expenseEndDate);
       await postFetch("/summary/load",request).then((data)=>{ 
            if(!data.message){
               let summaryData = [];
               let amount = parseFloat(0);
               if(rem.status === 'SB' && rem.revReason !== null){
                 rem.revReason = null;
                 rem.notes = null;
               }
               if(rem.status === 'AP' && props.staticData.dropdown.MedicaidApprovers.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0 ){
                rem.status = null;
              }
             
              if(props.reimbursement.status === 'AP' && (props.staticData.dropdown.PaymentApprover.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0)){
                props.reimbursement.assignedTo = props.userInfo.id;
                props.reimbursement.paySupervisor = props.userInfo.id
                rem.assignedTo = props.userInfo.id;
                rem.paySupervisor = props.userInfo.id            
              }
              if(props.reimbursement.status === 'RR' && (props.reimbursement.assignedTo.toString() !== props.userInfo.id.toString()))
              {
                rem.assignedTo = null;
              }
               setReimbursementDetails(rem);
               setMedicaidSw(data.medicaidSw);
               setAssignedTo(rem.assignedTo);      
               if(data.incidentalExpense && data.incidentalExpense.reimbAmount !== null){
                  let cca = data.incidentalExpense.creditCardAmount || 0.0;
                  let dba = data.incidentalExpense.directBillingAmount || 0.0
                  data.incidentalExpense.expType = "Incidental";
                  data.incidentalExpense.totalExp = "$" + parseFloat(cca + dba + data.incidentalExpense.reimbAmount).toFixed(2);
                  data.incidentalExpense.cardAmount = "$" + ((data.incidentalExpense.creditCardAmount !== null && data.incidentalExpense.creditCardAmount !== '') ? parseFloat(data.incidentalExpense.creditCardAmount).toFixed(2) : "0.00");
                  amount = amount + parseFloat(data.incidentalExpense.reimbAmount);
                  data.incidentalExpense.remAmount = "$" + parseFloat(data.incidentalExpense.reimbAmount).toFixed(2);
                  data.incidentalExpense.directBillingAmount =  "$" + ((data.incidentalExpense.directBillingAmount !== null && data.incidentalExpense.directBillingAmount !== '') ? parseFloat(data.incidentalExpense.directBillingAmount).toFixed(2) : "0.00");
                  summaryData.push(data.incidentalExpense)
               }
               if(data.carrierExpense && data.carrierExpense.reimbAmount !== null){
                let cca = data.carrierExpense.creditCardAmount || 0.0;
                let dba = data.carrierExpense.directBillingAmount || 0.0
                data.carrierExpense.expType = "Carrier";
                data.carrierExpense.totalExp = "$" + parseFloat(cca + dba  + data.carrierExpense.reimbAmount).toFixed(2);
                data.carrierExpense.cardAmount = "$" + ((data.carrierExpense.creditCardAmount !== null && data.carrierExpense.creditCardAmount !== '') ? parseFloat(data.carrierExpense.creditCardAmount).toFixed(2) : "0.00");
                amount = amount + parseFloat(data.carrierExpense.reimbAmount);
                data.carrierExpense.remAmount = "$" + parseFloat(data.carrierExpense.reimbAmount).toFixed(2);
                data.carrierExpense.directBillingAmount =  "$" + ((data.carrierExpense.directBillingAmount !== null && data.carrierExpense.directBillingAmount !== '') ? parseFloat(data.carrierExpense.directBillingAmount).toFixed(2) : "0.00");
                summaryData.push(data.carrierExpense)
                }
                if(data.lodgingExpense && data.lodgingExpense.reimbAmount !== null){
                  let cca = data.lodgingExpense.creditCardAmount || 0.0;
                  let dba = data.lodgingExpense.directBillingAmount || 0.0
                  data.lodgingExpense.expType = "Lodging";
                  data.lodgingExpense.totalExp = "$" + parseFloat(cca + dba + data.lodgingExpense.reimbAmount).toFixed(2);
                  data.lodgingExpense.cardAmount = "$" + ((data.lodgingExpense.creditCardAmount !== null && data.lodgingExpense.creditCardAmount !== '') ? parseFloat(data.lodgingExpense.creditCardAmount).toFixed(2) : "0.00");
                  amount = amount + parseFloat(data.lodgingExpense.reimbAmount);
                  data.lodgingExpense.remAmount = "$" + parseFloat(data.lodgingExpense.reimbAmount).toFixed(2);
                  data.lodgingExpense.directBillingAmount =  "$" + ((data.lodgingExpense.directBillingAmount !== null && data.lodgingExpense.directBillingAmount !== '') ? parseFloat(data.lodgingExpense.directBillingAmount).toFixed(2) : "0.00");
                  summaryData.push(data.lodgingExpense)
                }
                if(data.mealsExpense && data.mealsExpense.reimbAmount !== null){
                  let cca = data.mealsExpense.creditCardAmount || 0.0;
                  let dba = data.mealsExpense.directBillingAmount || 0.0
                  data.mealsExpense.expType = "Meals";
                  data.mealsExpense.totalExp = "$" + parseFloat(cca + dba + data.mealsExpense.reimbAmount).toFixed(2);
                  data.mealsExpense.cardAmount = "$" + ((data.mealsExpense.creditCardAmount !== null && data.mealsExpense.creditCardAmount !== '') ? parseFloat(data.mealsExpense.creditCardAmount).toFixed(2) : "0.00");
                  amount = amount + parseFloat(data.mealsExpense.reimbAmount);
                  data.mealsExpense.remAmount = "$" + parseFloat(data.mealsExpense.reimbAmount).toFixed(2);
                  data.mealsExpense.directBillingAmount =  "$" + ((data.mealsExpense.directBillingAmount !== null && data.mealsExpense.directBillingAmount !== '') ? parseFloat(data.mealsExpense.directBillingAmount).toFixed(2) : "0.00");
                  summaryData.push(data.mealsExpense)
                }
                if(data.mileageExpense &&  data.mileageExpense.reimbAmount !== null){
                  data.mileageExpense.expType = "Mileage";
                  let cca = data.mileageExpense.creditCardAmount || 0.0;
                  let dba = data.mileageExpense.directBillingAmount || 0.0
                  data.mileageExpense.totalExp = "$" + parseFloat(cca+dba + data.mileageExpense.reimbAmount).toFixed(2);
                  data.mileageExpense.cardAmount = "$" + parseFloat(cca).toFixed(2);
                  amount = amount + parseFloat(data.mileageExpense.reimbAmount);
                  data.mileageExpense.remAmount = "$" + parseFloat(data.mileageExpense.reimbAmount).toFixed(2);
                  data.mileageExpense.directBillingAmount =  "$" + parseFloat(dba).toFixed(2);
                  summaryData.push(data.mileageExpense)
                }
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:false });                                
                },1000);
                setTotalAmount(parseFloat(amount).toFixed(2));
                setSummary(summaryData);
                (props.reimbursement.status === 'IP' || props.reimbursement.status === 'RR') ? setUserDetails([...data.userSummary].slice().filter((user)=> user.id !== props.userInfo.id)) : setUserDetails([...data.userSummary]);
                setApprovalStatus(data.approvalStatus);
                let updatedPreApprovalData = [...preApprovalData];
                updatedPreApprovalData.find((method)=> method.key === "OS").attachements = [...data.osPreApproval];
                updatedPreApprovalData.find((method)=> method.key === "OW").attachements = [...data.owPreApproval];
                updatedPreApprovalData.find((method)=> method.key === "EM").attachements = [...data.emPreApproval];
                updatedPreApprovalData.find((method)=> method.key === "OS").value = props.reimbursement.osPreapproval;
                updatedPreApprovalData.find((method)=> method.key === "OW").value = props.reimbursement.owPreapproval;
                updatedPreApprovalData.find((method)=> method.key === "EM").value = props.reimbursement.emPreapproval;
                setPreApprovalData(updatedPreApprovalData);
            }
            else{
                setTimeout(()=>{                         
                    props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
                },1000);
            }
        }).catch((e)=>{
          setTimeout(()=>{                          
            props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
          },1000);
        });
      
        setDefaultReimbursement({...reimbursementDetails});        
    }
    if(onInit){
      loadSummary();
    }
  },[props,onInit]);
  
  const changeMedicate = (key,value)=>{
    if( key === 'status' && value === 'RR'){
      setMedSupvervisorSw(true);
    }else if ( key === 'status' && value === 'AP'){
      setMedSupvervisorSw(false);
    }
  }
  const changeReimbursementDetails = (key,value)=>{
    if(key === 'medSupervisor' ){
      setMedChange(false);
   }
    let newDetails = {...reimbursementDetails};
    if(key === 'internalOrder' || key === 'costCenter')
    {
      var pattern = new RegExp(/[~`!#$%\^&@()_*+=\-\[\]\\';,/{}|\\":<>\?]/); //unacceptable chars
      if (pattern.test(value)) {        
        value = null;
        setTimeout(()=>{                          
          props.loader({loader:false,notification:true,message:"error",validationMessage:'Special characters are not allowed'});                                 
        },1000);
      }     
    }
    if(key === 'status' && value === "RR")
    {
      newDetails.internalOrder = null;
      newDetails.costCenter = null;
    }
    if(key === 'status' && (value === "RR" || value === "AP"))
    {
      newDetails.delSw = 'N';
    }
    if(key === 'delSw' && value === 'Y')
    {
      newDetails.status = 'SB';
    }
    if(key === 'internalOrder' && value === "")
    {
      newDetails.internalOrder = null;
    }
    if(key === 'costCenter' && value === "")
    {
      newDetails.costCenter = null;
    }
    newDetails[key] = value;
    setReimbursementDetails(newDetails);
    setAssignedTo(newDetails.assignedTo);
  }

  const selectField = (row,column,index)=>{
    if(reimbursementDetails.status !== 'IP' && reimbursementDetails.status !== 'RR'){
      return (<div style={{cursor:'pointer',color:'rgb(51, 51, 255)'}} onClick={()=>navigatePreApproval(row.value)}>{row.value === null ? '' : row.value}</div>);
    }
    else{
      return (
        <FormControl variant="outlined" size="small" className={classes.formControl}>
        <Select
            id={"preapproval-select" + index}
            fullWidth
            value={row.value === null ? 0 : row.value}
            onChange = {(e)=> changePreApprovals(column,index,e.target.value)}
        >
            <MenuItem value={0}>Select from list</MenuItem>
            {row.attachements.map((method)=>(
              <MenuItem  key={parseInt(method.split("-")[0].trim())} value={parseInt(method.split("-")[0].trim())}>{method}</MenuItem>
            ))}
        ))
        </Select>                                               
    </FormControl>   
      )
    }
}

const navigatePreApproval = (value)=>{
  localStorage.setItem('preApproval',value);
  window.open(window.location.origin+"/preApproval");
}

const changePreApprovals = (column,index,value) =>{
  let newDetails = [...preApprovalData];
  newDetails[index].value = value;
  setPreApprovalData([...newDetails]);
}

const save = async (value,key)=>{
  if(document.getElementsByClassName("Mui-error").length > 0){
    setError(true);
  }
  else if((reimbursementDetails.status === 'AP') && !approvalStatus && (value !== 'IP') && (value !== 'SB')){
    setTimeout(()=>{                          
      props.loader({loader:false,notification:true,message:"error",validationMessage:"One or More Expenses are not Approved" });                                 
    },0);
  } else if(reimbursementDetails.userId === props.userInfo.id && ( key!==1 && reimbursementDetails.attestationSw !== 'Y') ){
    
    props.loader({loader:false,notification:true,message:"error",validationMessage:"Attestation Required for Submission" });  
  }
  else if((['TS', 'DA', 'SA'].indexOf(props.userInfo.role) > -1) && ((reimbursementDetails.status !== 'IP' && reimbursementDetails.status !== 'RR') 
  && (props.reimbursement.assignedTo !== null) && (props.reimbursement.assignedTo === props.userInfo.id) ) 
  && (reimbursementDetails.internalOrder !== null && reimbursementDetails.internalOrder !== "") &&(reimbursementDetails.costCenter === null || reimbursementDetails.costCenter === ""))
  {
    props.loader({loader:false,notification:true,message:"error",validationMessage:"If you wish to override IO/CC, Please enter Cost Center" });  
  }
  else if((['TS', 'DA', 'SA'].indexOf(props.userInfo.role) > -1) && ((reimbursementDetails.status !== 'IP' && reimbursementDetails.status !== 'RR') && (props.reimbursement.assignedTo !== null) && (props.reimbursement.assignedTo === props.userInfo.id) )
  && (reimbursementDetails.costCenter !== null && reimbursementDetails.costCenter !== "") &&(reimbursementDetails.internalOrder === null || reimbursementDetails.internalOrder === ""))
  {
    props.loader({loader:false,notification:true,message:"error",validationMessage:"If you wish to override IO/CC, Please enter Internal Order" });  
  }
  else if((props.travellerInfo.accountActive !== null && props.travellerInfo.accountActive === '0') &&
   (reimbursementDetails.status === 'AP' || reimbursementDetails.delSw === 'Y') &&
   (reimbursementDetails.notes === null)){
    
    props.loader({loader:false,notification:true,message:"error",validationMessage:"To approve or delete reimbursements submitted by terminated employees, please enter a value in the “Notes” field." });  
  }
  else{
    props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
    let request = {...reimbursementDetails};

    request.expenseStartDate = new Date(request.expenseStartDate);
    request.expenseEndDate = new Date(request.expenseEndDate);
    request.status = value;
    request.osPreapproval = (preApprovalData[0].value === 0) ? null : parseInt(preApprovalData[0].value);
    request.owPreapproval = (preApprovalData[1].value === 0) ? null : parseInt(preApprovalData[1].value);
    request.emPreapproval = (preApprovalData[2].value === 0) ? null : parseInt(preApprovalData[2].value);
    let ismedicate;
    props.staticData.dropdown.MedicaidApprovers.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0 ? ismedicate = 'Y' : ismedicate = 'N'
    if(value === 'AP' && ismedicate === 'N' && medicaidSw){
      request.assignedTo =  request.medSupervisor;
      request.status = 'SB';
    }
    //if(value === "AP" && ismedicate === 'Y' ){
     // request.assignedTo = parseInt(props.staticData.dropdown.PaymentApprover.value[0].value);
     // request.paySupervisor = parseInt(props.staticData.dropdown.PaymentApprover.value[0].value);
   // }
   // if(value === "AP" && ismedicate === 'N' && !medicaidSw){
   ///   request.assignedTo = parseInt(props.staticData.dropdown.PaymentApprover.value[0].value);
   //   request.paySupervisor = parseInt(props.staticData.dropdown.PaymentApprover.value[0].value);
   // }
  
    // if(value === "RR"){
    //   request.assignedTo = reimbursementDetails.userId;
    //   //request.medSupervisor = null;
    //   request.attestationSw = null;
    // }
    // if((key === 2) && (value !== reimbursementDetails.status))
    // {
    //   request.statusChangeDate = moment.utc(new Date()).local();
    // }
    if((value === "SB") && (reimbursementDetails.status === 'IP'  || reimbursementDetails.status === 'RR'))
    {
      request.assignedTo = parseInt(request.travelSupervisor);
    }
   
    if(value === "SB" && request.medSupervisor !== null && reimbursementDetails.status === 'AP'){
      request.assignedTo = parseInt(request.medSupervisor);
      request.status = 'SB';
    }

    if(value === "SB" && request.medSupervisor !== null && reimbursementDetails.status === 'AP'){
      request.assignedTo = parseInt(request.medSupervisor);
      request.status = 'SB';      
    }

    let url = (key == 1) ? "save" :"savestatus";
    let vlsuccessmessage = (value === "PP") ? "Travel Reimbursement Submitted for Payment Successfully" : ((value === "SB") ? "Travel Reimbursement Submitted Successfully" : "Travel Reimbursement Saved Successfully");
    if(key == 1 && value !== 'PP' && props.staticData.dropdown.PaymentApprover.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0){
      request.assignedTo = props.userInfo.id;
      request.status = 'AP'
    }
    if(value === "RR"){
      request.status = 'RR';
      request.attestationSw = null;
      //request.assignedTo = reimbursementDetails.userId;
    }
    if(url === 'savestatus'){
      request.travelSupervisor = request.assignedTo;
    }
    if(key==1 && value=='PP'){
      request.paySupervisor = props.userInfo.id;
    }
   if(value === "AP" && request.status !== 'SB' && reimbursementDetails.paySupervisor != null && reimbursementDetails.paySupervisor != undefined){
      request.assignedTo = reimbursementDetails.paySupervisor;
      request.paySupervisor = reimbursementDetails.paySupervisor;
    }

   if(value === "AP" && reimbursementDetails.paySupervisor == null && reimbursementDetails.paySupervisor == undefined && request.status !== 'SB'){
    request.assignedTo = 0;
    }

    if(reimbursementDetails.costCenter === "" || reimbursementDetails.internalOrder === "")
    {
      request.costCenter = null;
      request.internalOrder = null;
    }

  await postFetch("/reimbursement/"+url,request).then((data)=>{ 
    if(!data.message){
          data.expenseStartDate = convertDateFromServer(data.expenseStartDate);
          data.expenseEndDate = convertDateFromServer(data.expenseEndDate);
          setReimbursementDetails(data);
        setTimeout(()=>{                         
            props.loader({loader:false,notification:true,message:"success",validationMessage:vlsuccessmessage });
            (key === 1) ? console.log(data.id) :localStorage.removeItem("requestId");                            
        },1000);
        setTimeout(() => {
          history.push("/dashboard");  
        }, 3000);
    }
    else{
        setTimeout(()=>{                         
            props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
        },1000);
    }
}).catch((e)=>{
  setTimeout(()=>{                          
    props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
  },1000);
});
  }
}

  const reset = async()=>{

    const request = {
      id:parseInt(localStorage.getItem("requestId"))
    } 
    
    if(request.id != null)
    {
      await postFetch("/reimbursement/loadById",request).then((data)=>{ 
        if(!data.message){
            data.expenseStartDate = convertDateFromServer(data.expenseStartDate);
            data.expenseEndDate = convertDateFromServer(data.expenseEndDate);
            data.assignedTo = props.reimbursement.assignedTo;
            data.paySupervisor = props.reimbursement.paySupervisor;
            setReimbursementDetails(data);
            setTimeout(()=>{                         
                props.loader({loader:false,notification:false });                                
            },1000);
        }
        else{
            setTimeout(()=>{                         
                props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
            },1000);
        }
    }).catch((e)=>{
      setTimeout(()=>{                          
        props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
      },1000);
    });
    }

  }

  function convertDateFromServer(value){
    if((value !== null) && !(value instanceof Date)){
      let serverDateArray = value.split("-");
      if(serverDateArray.length > 1){
        return serverDateArray[1]+"/"+serverDateArray[2]+"/"+serverDateArray[0];
      }
      else{
        return serverDateArray[0];
      }
    }
    else if (value instanceof Date){
        return value;
    }
    else{
      return null;
    }
    
  }



  const reconcilationColumns = [
    {
        name:"Expense type",
        field:"expType",
        type:"text"
    },
    {
      name:"Total Expense",
      field:"totalExp",
      type:"text"
    },
    {
      name:"Direct Billing Amount",
      field:"directBillingAmount",
      type:"text"
    },
    {
        name:"DHS Credit Card Amount",
        field:"cardAmount",
        type:"text"
    },
    
    {
        name:"Reimbursement Amount",
        field:"remAmount",
        type:"text"
    }
  ]

  const preApprovalColumns = [
    {
        name:"Pre-Approval Type",
        field:"type",
        type:"text"
    },
    {
        name:"Attachments",
        field:"attachments",
        type:"input",
        renderView:selectField
    }
]

  return (
    <Route exact path="/tr1/summary">
    <div className={classes.root}>
                <div>
                    <Typography className={classes.header}>Summary</Typography>
                    <Subheader>Reconciliation Details</Subheader>
                        <Paper elevation={0}>
                            <Box p={"10px"} pb={"60px"}>
                                <Gridtable columns={reconcilationColumns} data={summary}></Gridtable>
                                <div className="summaryTotal">
                                <span>Total Reimbursement Amount: ${totalAmount}</span>
                            </div>
                            </Box> 
                            
                        </Paper>
                        {(props.userInfo.role !== 'PR') ? <Subheader>Pre-Approval Details</Subheader>:null}
                        {(props.userInfo.role !== 'PR') ?  <Grid container className={classes.filters}>
                          <Grid item xs={12}>
                              <Box pr={"10px"}>
                                  <Gridtable columns={preApprovalColumns} data={preApprovalData}></Gridtable>
                              </Box>
                          </Grid>
                      </Grid>:null}
                      
                      <Subheader>Submission Details</Subheader>
                      <Grid container className={classes.filters}>
                      <Grid item xs={12} sm={4} className={classes.textfield}>

                        {/* {(["IPP"].indexOf(props.reimbursement.status) > -1) ? <div>
                        <div><span className={classes.textLabel}>Select Travel Supervisor</span></div> 
                          <Autocomplete
                              id="assigned user"
                              options={userDetails.sort((a, b) => a.details.toLowerCase().localeCompare(b.details.toLowerCase()))}
                              getOptionLabel={(option) => option.details}
                              value={((reimbursementDetails.assignedTo !== null) && (userDetails.find((user)=> user.id === reimbursementDetails.assignedTo) !== undefined)) ? userDetails.find((user)=> user.id === reimbursementDetails.assignedTo) : {details:'',id:null}}
                              onChange={(event,value)=> value !== null ? changeReimbursementDetails('assignedTo',value.id) : changeReimbursementDetails('assignedTo',null)}
                              disabled = {(["AP","PP","PO"].indexOf(props.reimbursement.status) > -1) || ((props.reimbursement.status === 'SB') && (props.reimbursement.userId === props.userInfo.id))}
                              renderOption={(option)=>
                                  <span style={{fontWeight:600}}>{option.details}</span>
                              }
                              size="small"
                              renderInput={(params) => <TextField id="re-assign"  variant="outlined" size="small" {...params}
                              placeholder = "Search User"
                              className={["AP","PP","PO"].indexOf(props.reimbursement.status) > -1 || ((props.reimbursement.status === 'SB') && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : "required"}
                              //className={`${classes.formControl} required`}
                              disabled = {(["AP","PP","PO"].indexOf(props.reimbursement.status) > -1) || ((props.reimbursement.status === 'SB') && (props.reimbursement.userId === props.userInfo.id))}
                              error = {reimbursementDetails.assignedTo === '' || reimbursementDetails.assignedTo === null || (userDetails.find((user)=> user.id === reimbursementDetails.assignedTo) === undefined)}
                              value={reimbursementDetails.assignedTo === null ? '' : reimbursementDetails.assignedTo}
                              //onChange={(event)=> changeReimbursementDetails('assignedTo',event.target.value)}
                              helperText={((reimbursementDetails.assignedTo === '' || reimbursementDetails.assignedTo === null) && error ) ? "Required Field" : null}
                              />}
                              />
                        </div> : */}
                        {(["IP","RR"].indexOf(props.reimbursement.status) > -1 ) ?<div>
                          <div><span className={classes.textLabel}>Select Travel Supervisor</span></div>           
                          <Autocomplete
                              id="assigned user"
                              options={userDetails.sort((a, b) => a.details.toLowerCase().localeCompare(b.details.toLowerCase()))}
                              getOptionLabel={(option) => option.details}
                              value={((parseInt(reimbursementDetails.travelSupervisor) !== null) && (userDetails.find((user)=> user.id === parseInt(reimbursementDetails.travelSupervisor)) !== undefined)) ? userDetails.find((user)=> user.id === parseInt(reimbursementDetails.travelSupervisor)) : {details:'',id:null}}
                              onChange={(event,value)=> value !== null ? changeReimbursementDetails('travelSupervisor',value.id) : changeReimbursementDetails('travelSupervisor',null)}
                              disabled = {(["AP","PP","PO"].indexOf(props.reimbursement.status) > -1) || ((props.reimbursement.status === 'SB') && (props.reimbursement.userId === props.userInfo.id))}
                              renderOption={(option)=>
                                  <span style={{fontWeight:600}}>{option.details}</span>
                              }
                              size="small"
                              renderInput={(params) => <TextField id="re-assign"  variant="outlined" size="small" {...params}
                              placeholder = "Search User"
                              className={["AP","PP","PO"].indexOf(props.reimbursement.status) > -1 || ((props.reimbursement.status === 'SB') && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : "required"}
                              //className={`${classes.formControl} required`}
                              disabled = {(["AP","PP","PO"].indexOf(props.reimbursement.status) > -1) || ((props.reimbursement.status === 'SB') && (props.reimbursement.userId === props.userInfo.id))}
                              error = {parseInt(reimbursementDetails.travelSupervisor) === '' || parseInt(reimbursementDetails.travelSupervisor) === null || (userDetails.find((user)=> user.id === parseInt(reimbursementDetails.travelSupervisor)) === undefined)}
                              value={parseInt(reimbursementDetails.travelSupervisor) === null ? '' : parseInt(reimbursementDetails.travelSupervisor)}
                              //onChange={(event)=> changeReimbursementDetails('assignedTo',event.target.value)}
                              helperText={((parseInt(reimbursementDetails.travelSupervisor) === '' || parseInt(reimbursementDetails.travelSupervisor) === null) && error ) ? "Required Field" : null}
                              />}
                              />
                           </div>
                        : 
                        <FormControl variant="outlined" size="small" className={classes.formControl} >
                           <span className={classes.textLabel}>Travel Supervisor</span>
                        <Select
                                  id="travel-sup1"
                                  value={props.reimbursement.travelSupervisor}
                                  disabled 
                                  className = 'disabled'

                              >
                                
                                  <MenuItem value={0}>Pick an Option</MenuItem>
                                  {userDetails.map((method)=>(
                                    <MenuItem  key={method.id} value={method.id}>{method.details}</MenuItem>
                                  ))}
                          </Select>
                          {((props.reimbursement.travelSupervisor === 0 || props.reimbursement.travelSupervisor === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                          : null}  
                          </FormControl>
                         }
                      </Grid>
                          {((["SB","PP","PO","AP"].indexOf(props.reimbursement.status) > -1) && medicaidSw  ) ? <Grid item xs={12} sm={4} className={classes.textfield}>
                            <FormControl variant="outlined" size="small" className={classes.formControl} error={(reimbursementDetails.medSupervisor === 0 || reimbursementDetails.medSupervisor === null) && !medSupvervisorSw}>
                                <span className={classes.textLabel}>Medicaid Supervisor</span>
                                <Select
                                    id="medicaid-sup2"
                                    value={reimbursementDetails.medSupervisor === null ? 0 : reimbursementDetails.medSupervisor}
                                    className = {["PP","PO","AP"].indexOf(props.reimbursement.status) > -1 || (props.reimbursement.status === 'SB' && (props.reimbursement.assignedTo !== null && props.reimbursement.assignedTo.toString() !== props.userInfo.id.toString()))? "disabled":"required"}
                                    disabled = {["PP","PO","AP"].indexOf(props.reimbursement.status) > -1 || (props.reimbursement.status === 'SB' && (props.reimbursement.assignedTo !== null && props.reimbursement.assignedTo.toString() !== props.userInfo.id.toString()))}
                                    onChange={(e)=> changeReimbursementDetails('medSupervisor',e.target.value)}
                                    
                                >
                                    <MenuItem value={0}>Pick an Option</MenuItem>
                                    {props.staticData.dropdown.MedicaidApprovers.value.map((method)=>(
                                      <MenuItem  key={method.value} value={method.value}>{method.fullName}</MenuItem>
                                    ))}
                            
                                </Select>
                                {((reimbursementDetails.medSupervisor === 0 || reimbursementDetails.medSupervisor === null) && error && !medSupvervisorSw) ? <FormHelperText>Required Field</FormHelperText>
                                : null}                                               
                            </FormControl>                      
                        </Grid> : null}
                        {(["AP","PP","PO"].indexOf(props.reimbursement.status) > -1)  ? <Grid item xs={12} sm={4} className={classes.textfield}>
                              <FormControl variant="outlined" size="small" className={classes.formControl} >
                              <span className={classes.textLabel}>Payment Approver</span>
                                <Select
                                  id="payment-sup"
                                  value={props.reimbursement.paySupervisor === null ? -1 : props.reimbursement.paySupervisor}
                                  className="disabled"
                                  disabled>
                                  <MenuItem value={0}>Pick an Option</MenuItem>
                                  {props.staticData.dropdown.PaymentApprover.value.map((method) => (
                                    <MenuItem key={method.value} value={method.value}>{method.name}</MenuItem>
                                  ))}
                                </Select>
                                {((props.reimbursement.paySupervisor === 0 || props.reimbursement.paySupervisor === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                                  : null}
                              </FormControl>
                            </Grid> : null}
                      </Grid>
                  {((reimbursementDetails.status !== 'IP') && (props.reimbursement.assignedTo !== null) && (props.reimbursement.assignedTo === props.userInfo.id) ) ? <div>                 
                    
                    <Subheader>Status Details</Subheader>
                      {(['TS', 'DA', 'SA'].indexOf(props.userInfo.role) > -1 && reimbursementDetails.status !== 'RR') ? <div>
                      <div className={classes.statusDetailsMaring}><span className={classes.textLabel} style={{ color: "red" }}>The Internal Order/Cost Center entered below will override the Internal Order/Cost Center for all expenses on this reimbursement.</span></div>
                      <Grid container className={classes.filters}>
                        <Grid item xs={12} sm={5} className={classes.textfield}>
                        <span className={classes.textLabel}>Internal Order</span>
                            <TextField
                                id="io_change"
                                className=""
                                //error ={reimbursementDetails.internalOrder === '' || reimbursementDetails.internalOrder === null}
                                value={reimbursementDetails.internalOrder === null ? '' :reimbursementDetails.internalOrder}
                                //value={reimbursementDetails.internalOrder}
                                variant="outlined"
                                inputProps={{
                                  maxLength: 8
                                }}
                                size="small"
                                fullWidth
                                onChange={(event)=> changeReimbursementDetails('internalOrder',event.target.value)}
                                //helperText={((reimbursementDetails.internalOrder === '' || reimbursementDetails.internalOrder === null) && error) ? "Required Field" : null}
                            />
                        </Grid>
                        <Grid item xs={12} sm={5} className={classes.textfield}>
                        <span className={classes.textLabel}>Cost Center</span>
                            <TextField
                                id="co_change"
                                className=""
                                //error ={reimbursementDetails.costCenter === '' || reimbursementDetails.costCenter === null}
                                value={reimbursementDetails.costCenter === null ? '' :reimbursementDetails.costCenter}
                                //value={reimbursementDetails.costCenter}
                                variant="outlined"
                                inputProps={{
                                  maxLength: 10
                                }}
                                size="small"
                                fullWidth
                                onChange={(event)=> changeReimbursementDetails('costCenter',event.target.value)}
                                //helperText={((reimbursementDetails.costCenter === '' || reimbursementDetails.costCenter === null) && error) ? "Required Field" : null}
                            />
                        </Grid>
                    </Grid></div> : null}
                    <Grid container className={classes.filters}>
                        <Grid item xs={10} sm={3} className={classes.textfield}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={(reimbursementDetails.status === 'AP') || (reimbursementDetails.status === 'PO') || (reimbursementDetails.status === 'PP')}
                                        onChange={()=> {changeReimbursementDetails('status','AP'); changeMedicate('status','AP')}}
                                        name="Approve"
                                        color="primary"
                                        className = {((["RR","PP","PO"].indexOf(props.reimbursement.status) > -1) && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : null}
                                        disabled = {((["RR","PP","PO"].indexOf(props.reimbursement.status) > -1) && (props.reimbursement.userId === props.userInfo.id))}
                                    />
                                }
                                label="Approve"
                                labelPlacement="end"
                            />
                        </Grid>
                        <Grid item xs={10} sm={3} className={classes.textfield}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={reimbursementDetails.status === 'RR'}
                                        onChange={()=> {changeReimbursementDetails('status','RR'); changeMedicate('status','RR')}}
                                        name="Return for Revision"
                                        color="primary"
                                        className = {((["RR","PP","PO"].indexOf(props.reimbursement.status) > -1) && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : null}
                                        disabled = {((["RR","PP","PO"].indexOf(props.reimbursement.status) > -1) && (props.reimbursement.userId === props.userInfo.id))}
                                    />
                                }
                                label="Return for Revision"
                                labelPlacement="end"
                            />
                        </Grid>

                       { (props.travellerInfo.accountActive !== null && props.travellerInfo.accountActive === '0') ? <Grid item xs={10} sm={3} className={classes.textfield}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={reimbursementDetails.delSw === 'Y'}
                                        onChange={()=> {changeReimbursementDetails('delSw','Y'); changeMedicate('delSw','Y')}}
                                        name="Delete"
                                        color="primary"
                                        //className = {((["RR","PP","PO"].indexOf(props.reimbursement.status) > -1) && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : null}
                                        //disabled = {((["RR","PP","PO"].indexOf(props.reimbursement.status) > -1) && (props.reimbursement.userId === props.userInfo.id))}
                                    />
                                }
                                label="Delete"
                                labelPlacement="end"
                            />
                        </Grid> : null}
                    </Grid>
                    {reimbursementDetails.status === 'RR' ? <Grid container className={classes.filters}>
                    <Grid item xs={10} sm={5} className={classes.textfield}>
                        <FormControl variant="outlined" size="small" className={classes.formControl} error={reimbursementDetails.revReason === 0 || reimbursementDetails.revReason === null}>
                            <span className={classes.textLabel}>Reason for Revision</span>
                            <Select
                                id="reason-select"
                                value={reimbursementDetails.revReason === null ? 0 : reimbursementDetails.revReason}
                                className = {((["RR","PP","PO"].indexOf(props.reimbursement.status) > -1) && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : "required"}
                                disabled = {((["RR","PP","PO"].indexOf(props.reimbursement.status) > -1) && (props.reimbursement.userId === props.userInfo.id))}
                                onChange={(e)=> changeReimbursementDetails('revReason',e.target.value)}
                            >
                                <MenuItem value={0}>Pick an Option</MenuItem>
                                {props.staticData.dropdown.ReasonForRevision.value.map((method)=>(
                                    <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                                ))}
                            </Select>
                            {((reimbursementDetails.revReason === 0 || reimbursementDetails.revReason === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                            : null}                                               
                        </FormControl>                      
                    </Grid> 
                    </Grid> : null}
                    {(reimbursementDetails.status === 'RR') || ((props.travellerInfo.accountActive !== null && props.travellerInfo.accountActive === '0') && (reimbursementDetails.status === 'AP' || reimbursementDetails.delSw === 'Y')) ? <Grid container className={classes.filters}>
                            <Grid item xs={10} className={classes.textfield}>
                                <span className={classes.textLabel}>Notes</span>
                                <TextField
                                    id="notes"
                                    multiline
                                    value={reimbursementDetails.notes === null ? '' : reimbursementDetails.notes}
                                    variant="outlined"
                                    disabled={['RR','IP','PP','PO'].indexOf(props.reimbursement.status) > -1}
                                    InputLabelProps={{
                                        shrink: true,
                                        }}
                                    size="small"
                                    fullWidth
                                    onChange={(e)=> changeReimbursementDetails('notes',e.target.value)}
                                    inputProps={{
                                        maxLength: 400
                                        }}
                                    helperText={((reimbursementDetails.notes === '' || reimbursementDetails.notes === null) && error) ? "Required Field" : null}
                            />
                            <FormHelperText>{reimbursementDetails.notes === null ? 0 :reimbursementDetails.notes.length}/400 Characters</FormHelperText>
                            </Grid>
                    </Grid> : null}
                </div> : null}

                
              {(reimbursementDetails.userId === props.userInfo.id) ? 
                      <div  className={classes.textLabel} style={{margin:"10px"}} >
                      <FormControlLabel
                            control={
                                <Checkbox
                                    id="sendsearch-checkbox"
                                    checked={reimbursementDetails.attestationSw === 'Y'}
                                    onChange={()=> changeReimbursementDetails('attestationSw','Y')}
                                    name="Save Switch"
                                    color="primary"
                                  
                                />
                            }
                            label="By checking this box, I certify that all expenses claimed on this form were incurred while conducting official business for the State of Arkansas and are in compliance with all travel regulations that have been issued by the Chief Fiscal Officer of the State of Arkansas."
                            labelPlacement="end"
                        />
                      </div>  : null                  
                   }
                     
                      <div style={{textAlign:'center',paddingBottom:10}}>
                          {((props.reimbursement.status !== 'IP') && (props.reimbursement.status !== 'RR') && (props.reimbursement.assignedTo !== null) && (props.reimbursement.assignedTo === props.userInfo.id)) ?
                          <div>
                            <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small"  disabled={["PO","PP"].indexOf(props.reimbursement.status)  > -1} onClick={()=>reset()}>Reset</Button>
                            <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small"  disabled={["PO","PP"].indexOf(props.reimbursement.status) > -1 } onClick={()=> save(reimbursementDetails.status,1)}>Save</Button>
                            {((props.staticData.dropdown.PaymentApprover.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0) && (reimbursementDetails.status === 'AP')) ? 
                            <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small"  onClick={()=> save("PP",1)}>Submit for Payment</Button> : null}
                          </div> :
                          <div>
                            <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small"  disabled={["IP","RR"].indexOf(props.reimbursement.status) === -1} onClick={()=>reset()}>Reset</Button>
                            <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small"  disabled={["IP","RR"].indexOf(props.reimbursement.status) === -1 } onClick={()=> save("IP",1)}>Save</Button>
                            <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small"  disabled={["IP","RR"].indexOf(props.reimbursement.status) === -1 || !(totalAmount > 0) } onClick={()=> save("SB",2)}>Submit</Button>
                          </div>}
                          
                      </div>
                </div>
    </div>
    </Route>
  );
}

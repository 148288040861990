/* global google */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
//import Checkbox from '@material-ui/core/Checkbox';
import '../App.css';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
//import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
/*import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';*/
import Config from '../assets/config';
import Checkbox from '@material-ui/core/Checkbox';
import { postFileDownload, postFetch, postFetch2 } from '../http';
import Paper from '@material-ui/core/Paper';
import Gridtable from '../gridtable';
//import QueryBuilderRoundedIcon from '@material-ui/icons/QueryBuilderRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import Box from '@material-ui/core/Box';
import { Switch, Route } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import Tooltip from '@material-ui/core/Tooltip';
import RadioGroup from '@material-ui/core/RadioGroup';
import { useHistory } from "react-router-dom";
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import moment from "moment";
import NumberFormat from 'react-number-format';
import _, { truncate } from 'lodash';
import { Cached, Repeat,FormatIndentDecreaseTwoTone, FormatIndentIncreaseTwoTone, ImageSearchTwoTone, ImportContactsTwoTone, InsertChartOutlinedTwoTone, SwapHorizontalCircle } from '@material-ui/icons';
import { Icon} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#fff',
        borderRadius: '5px',
        margin: '30px 10px',
        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 0px 0px rgb(0 0 0 / 12%)',
        "& .MuiFormControlLabel-labelPlacementStart": {
            marginLeft: 1
        }
    },
    header: {
        backgroundColor: "#1a75c9",
        color: "#fff",
        marginBottom: 10,
        padding: 10,
        fontWeight: 900,
        fontSize: 15
    },
    filters: {
        "& .MuiFormLabel-root.Mui-focused, .MuiFormLabel-root": {
            color: "#000",
            fontSize: 17
        },
        margin: '20px 0',
        padding: '0 10px'
    },
    textfield: {
        padding: '10px 10px 10px 0',
        position: 'relative'
    },
    textLabel: {
        position: 'relative',
        bottom: '8px',
        fontSize: '14px',
        fontWeight: '500',
    },
    hidetext:{
        zIndex:-1,
        visibility:"hidden"
      },
    datepicker: {
        position: 'relative',
        bottom: 3,
        padding: '0 10px 0 0'
    },
    formControl: {
        width: "100%",
        marginTop: 1
    },
    addressField: {
        width: 400,
        marginTop: 1
    },
    fabIcon: {
        padding: '10px',
        marginTop: '34px'
    },
    radioLabel: {
        position: 'relative',
        top: 13,
        marginRight: 20
    },
    selectGrid:{
        padding: '0px 10px 10px 0',
        position: 'relative'
    }
}));

const Subheader = ({ children }) => {
    return (
        <div className="wrapper">
            <p>{children}</p>
            <div className="line"></div>
        </div>
    )
}

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            format="##########"
            mask="_"
        />
    );
}

function AmountFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            allowNegative={false}
            prefix="$"
        />
    );
}

export default function MileageExp(props) {
    const classes = useStyles();
    const history = useHistory();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [summaryData, setSummaryData] = React.useState([]);
    const [initialTrips, setInitialTrips] = React.useState([]);
    const [totalAmount, setTotalAmount] = React.useState(0);
    const [totalMileage, setTotalMileage] = React.useState(0);
    const [onInit, setOnInit] = React.useState(true);
    const [mileageDetails, setMileageDetails] = React.useState({ expReason: null, expenseDate: null, justification: null, ledgerCode: null, rate: null, totalMileage: 0, amount: 0, dhsCardSw: 'N', dhsCardAmount: 0, revReason: null, rembId: null, status: 'IP', medStatus: 'IP', nonMedStatus: 'IP', medStatusRevReason: null, nonMedStatusRevReason: null, delSw: 'N', emergencyExSw:'N', emergencyExReason:'0', medicaidApproved: null, expInternalOrder: null, expCostCenter: null, outsideWorkHours: 'N' });
    const [error, setError] = React.useState(false);
    const [medError, setMedError] = React.useState(false);
    const [addresses, setAddresses] = React.useState([]);
    const [medicaid, setMedicaid] = React.useState('N');
    const [medicaidSw, setMedicaidSw] = React.useState(false);
    const [currentMedicaid, setCurrentMedicaid] = React.useState({ medFirstname: null, medLastname: null, medDob: null, medId: null, index: null, internalOrder: null, costCenter: null, ledgerCode: null, medicaidDocuments: [] });
    const [documents, setDocuments] = React.useState([]);
    const [readOnly, setReadOnly] = React.useState(false);
    const [hideText,setHideText]= React.useState(true);
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    //const [isRREdited,setIsRREdited] = React.useState(false);
    const [oldMileageDetails, setOldMileageDetails] = React.useState([]);
    const [mileageRate, setMileageRate] = React.useState(0);
    const [historyData, setHistoryData] = React.useState([]);
    const [emgExpReason, setEmgExpReason] = React.useState(true);
    const [allowMedicaid, setAllowMedicaid] = React.useState(true);
    const [returnTripBtnDisabled, setReturnTripBtnDisabled] = React.useState({0:false});
    const [stateHoliday, setStateHoliday] = React.useState([]);
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    //const [medicaidDocuments, setMedicaidDocuments] = useState([]);
    const [generalDocuments, setUploadDocuments] = useState([]);
    const [validationMessage, setValidationMessage] = useState(""); 
    const [uploadMessage, setUploadMessage] = useState("");
    const [showWarning, setShowWarning] = useState(false);  // New state for controlling visibility
    const [warningMessage, setWarningMessage] = useState(''); // Stores the warning message
    const [isEditing, setIsEditing] = useState(false);


    const {
        placePredictions,
        getPlacePredictions
    } = useGoogle({
        apiKey: "AIzaSyDv82CVEz0pvZpc5E4LeWtcM00n2S6292w",
    });

    React.useEffect(() => {
        if (props.currentMedicaid && props.currentMedicaid.index !== undefined) {
            setIsFileUploaded(false);
        } else {
            console.error('props.currentMedicaid or props.currentMedicaid.index is undefined');
        }
    }, [props.currentMedicaid ? props.currentMedicaid.index : null]);
    
    const loadHistory = async(newDetails)=>{  
        if(newDetails.expenseDate !== null && newDetails.expenseDate !== ''){
          props.loader({loader:true,notification:false,message:"success",validationMessage:"success" });
          let request = {};
          request.expenseDate = moment(newDetails.expenseDate).format("YYYY-MM-DD");
          request.userId = newDetails.userId || props.userInfo.vmUserId;
          request.assignedTo = newDetails.status !== 'IP' ? newDetails.userId : null;
          await postFetch("/vehicleSearch/load",request).then((data)=>{ 
              if(!data.message){
                  setTimeout(()=>{                         
                      props.loader({loader:false,notification:false,message:"success"});                        
                  }, 0);
                  data.forEach((vehicle) => {
                      vehicle.reservationStartDate = convertDateFromServer(vehicle.reservationStartDate);
                      vehicle.reservationEndDate = convertDateFromServer(vehicle.reservationEndDate);
                  })
                  setHistoryData([...data]);
                  if(data.length > 0 ) {
                    setHideText(true)

                }
                else   
                    setHideText(false)

            }
              else{
                  setTimeout(()=>{                         
                      props.loader({loader:false,notification:true,message:"error",validationMessage:data.message });                                
                  },1000);
              }
          }).catch(()=>{
          setTimeout(()=>{                          
              props.loader({loader:false,notification:true,message:"error",validationMessage:"System Error: Please contact help desk." });                                 
          },1000);
          });
        }
            
      }
    const changeMedicaidDetails = (key, value) => {
        let newDetails = { ...currentMedicaid };
        // if(newDetails[key] !== value)
        // {
        //     setIsRREdited(true);
        // }
        if (key === 'medDob' && (value !== null) && (value > new Date(new Date().toDateString()))) {
            value = null;
            props.loader({ loader: false, notification: true, message: "error", validationMessage: 'The Date of Birth must be a current or Past Date' });
        }
        newDetails[key] = value;
        setCurrentMedicaid(newDetails);
    }

    const fromAddress = (row, column, index) => {
        return (
            <Autocomplete
                id={`fromaddress ${row.id}`}
                options={placePredictions}
                getOptionLabel={(option) => option.description}
                className={classes.addressField}
                disabled={readOnly}
                value={(row.fromAddress !== null) ? { description: row.fromAddress } : { description: '' }}
                onChange={(event, value) => value !== null ? changeTripDetails(index, "fromAddress", value) : changeTripDetails(index, "fromAddress", null)}
                renderOption={(option) =>
                    <span style={{ fontWeight: 500 }}>{option.description}</span>
                }
                onHighlightChange={(event, value) => value !== null ? changeTripDetails(index, "fromAddress", value) : changeTripDetails(index, "fromAddress", null)}
                
                size="small"
                renderInput={(params) => <TextField id={`address ${row.id}`} variant="outlined" size="small" {...params}
                    placeholder="Search Address"
                    className={readOnly ? "disabled" : `${classes.addressField} required`}
                    disabled={readOnly}
                    error={row.fromAddress === '' || row.fromAddress === null}
                    value={row.fromAddress === null ? '' : row.fromAddress}
                    onChange={(event) => timeoutPredictions(index, 'fromAddress', event.target.value)}
                    helperText={((row.fromAddress === '' || row.fromAddress === null) && error) ? "Required Field" : null}
                    //onKeyDown={(event) => onKeyTab(index, 'fromAddress', event)}
                     onBlur={(e) => {
                         onfocusChange(index, 'fromAddress', e)
                       }}
                    //onPaste={(e)=>{
                    //    e.preventDefault()
                    //    //return false;
                    //  }}
                    InputLabelProps={{
                        shrink: true,
                    }} />}
            />
        )
    }
    const timeoutPredictions = (index, key, value) => {
        props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
        let trips = [...addresses];
        trips[index][key] = value;
        trips[index]["miles"] = null;
        trips[index]["amount"] = 0;
        let newDetails = { ...mileageDetails };
        newDetails.tripDetails = [...trips];
        newDetails = calculateExp(newDetails);
        setMileageDetails(newDetails);
        if(value.length > 5)
        {
            var location = { lat: 34.7454902, lng: -92.2751838 };
            var radius = 1500;
            // eslint-disable-next-line no-undef
            const circle = new google.maps.Circle({
                center: location,
                radius: radius
            });
            const center = { lat: 35.20105, lng: -91.8318334 };
            // Create a bounding box with sides ~10km away from the center point
            const defaultBounds = {
                north: center.lat + 1000,
                south: center.lat - 1000,
                east: center.lng + 1000,
                west: center.lng - 1000,
            };
            setTimeout(() => {
                getPlacePredictions({ input: value, componentRestrictions: { country: "us" }, bounds: circle.getBounds() });
                props.loader({ loader: false, notification: false });
            }, 10);
        }
        setAddresses(trips);
    }
    const toAddress = (row, column, index) => {
        return (
            <Autocomplete
                id={`toaddress ${row.id}`}
                options={placePredictions}
                getOptionLabel={(option) => option.description}
                className={classes.addressField}
                disabled={readOnly}
                value={(row.toAddress !== null) ? { description: row.toAddress } : { description: '' }}
                onChange={(event, value) => value !== null ? changeTripDetails(index, "toAddress", value) : changeTripDetails(index, "toAddress", null)}
                renderOption={(option) =>
                    <span style={{ fontWeight: 500 }}>{option.description}</span>
                }

                onHighlightChange={(event, value) => value !== null ? changeTripDetails(index, "toAddress", value) : changeTripDetails(index, "toAddress", null)}
                size="small"
                renderInput={(params) => <TextField id={`address ${row.id}`} variant="outlined" size="small" {...params}
                    placeholder="Search Address"
                    className={readOnly ? "disabled" : `${classes.addressField} required`}
                    disabled={readOnly}
                    error={row.toAddress === '' || row.toAddress === null}
                    value={row.toAddress === null ? '' : row.toAddress}
                    onChange={(event) =>  timeoutPredictions(index, 'toAddress', event.target.value)}
                    helperText={((row.toAddress === '' || row.toAddress === null) && error) ? "Required Field" : null}
                    onKeyDown={(event) => onKeyTab(index, 'toAddress', event)}
                     onBlur={(e) => {
                         onfocusChange(index, 'toAddress', e)
                       }}
                    //onPaste={(e)=>{
                    //    e.preventDefault()
                    //    //return false;
                    //  }}
                    InputLabelProps={{
                        shrink: true,
                    }} />}
            />
        )
    }
    const onKeyTab = (index, key, e) => {
        
        if (e.key === "Tab") {
          //e.preventDefault();
          if(e.target.value != null && e.target.value.length > 0)
          {
            getGooglePlaceID(index,key,e);
          }
        }
      }

      const onfocusChange = (index, key, e) => {

          //e.preventDefault();
          let trips = [...addresses];
          if(e.target.value != null && e.target.value.length > 0 && trips[index]["miles"] == null)
          {            
            getGooglePlaceID(index,key,e);
          }
        
      }

      const getGooglePlaceID = (index, key, e) => {
        var map;

         map = new google.maps.Map(document.createElement('div'), {
           center: {lat: 34.7454902, lng: -92.2751838}, 
           zoom: 15
         });
       

         var request = {
           location: map.getCenter(),
           radius: '1500',
           query: e.target.value
         };
       
         var service = new google.maps.places.PlacesService(map);
         service.textSearch(request, callback);
       
       
       // Checks that the PlacesServiceStatus is OK, and adds a marker
       // using the place ID and location from the PlacesService.
       function callback(results, status) {
         if (status == google.maps.places.PlacesServiceStatus.OK) {
           var marker = new google.maps.Marker({
             map: map,
             place: {
               placeId: results[0].place_id,
               location: results[0].formatted_address
             }
           });
           var placesResult = {
               description: results[0].formatted_address,
               place_id: results[0].place_id
           }
           changeTripDetails(index, key, placesResult);
         }
       }
    }

    const changeTripDetails = (index, key, value) => {
        let trips = [...addresses];
        let newDetails = { ...mileageDetails };
        // if(value !== null)
        // {
        //     value = value.replace(", USA", ", United States");
        // }

        trips[index][key] = value;
        if(key === "fromAddress") 
        {
            trips[index][key] = value !== null ? value.description : null;
            trips[index]["fromAddressDir"] = value !== null ? value.place_id: null;
        }
        if( key === "toAddress" )
        {
            trips[index][key] = value !== null ? value.description : null;
            trips[index]["toAddressDir"] = value !== null ? value.place_id: null;
        }
        if(trips[index]["fromAddress"] === null || trips[index]["toAddress"] === null)
        {
            trips[index]["miles"] = null;
            trips[index]["amount"] = 0;
            newDetails.tripDetails = [...trips];
            newDetails = calculateExp(newDetails);
            setMileageDetails(newDetails);
        }
        if ((key === "toAddress" && trips[index]["fromAddress"] !== null && value !== null) || (key === "fromAddress" && trips[index]["toAddress"] !== null && value !== null)) {
            var fromDim = (key === "fromAddress" ? value.place_id : trips[index]["fromAddressDir"]);
            var toDim = (key === "toAddress" ? value.place_id : trips[index]["toAddressDir"]);
            props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
            // geocodeByAddress(key === "fromAddress" ? value : trips[index]["fromAddress"])
            // .then(results => getLatLng(results[0]))
            // .then((dim) =>{
            //     fromDim = {...dim};
            //     console.log(fromDim);
            // });
            // geocodeByAddress(key === "toAddress" ? value : trips[index]["toAddress"])
            // .then(results => getLatLng(results[0]))
            // .then((dim) =>{
            //     toDim = {...dim};
            //     console.log(toDim);
            // });
            // eslint-disable-next-line no-undef
            const service = new google.maps.DistanceMatrixService();
            setTimeout(() => {
                service.getDistanceMatrix(
                    {
                        origins: [{placeId:fromDim}],
                        destinations: [{placeId:toDim}],
                        travelMode: 'DRIVING',
                        unitSystem: 1,
                        avoidHighways: false,
                        avoidTolls: false
                    }, callback);
            }, 1000);
            function callback(response, status) {
                if (status === "OK") {
                    if(response.rows[0].elements[0].status === "OK")
                    {
                       // Update trip details with retrieved addresses
                       trips[index]["fromAddress"] = response.originAddresses[0];
                       trips[index]["toAddress"] = response.destinationAddresses[0];

                       trips[index]["miles"] = response.rows[0].elements[0].distance.text;
                       newDetails.tripDetails = [...trips];
                       newDetails = calculateExp(newDetails);
                       setMileageDetails(newDetails);
                       props.loader({ loader: false, notification: false });
                   }else
                   {
                       props.loader({ loader: false, notification: true, message: "error", validationMessage: "Please enter valid address." });
                   }
               }
               else {
                   props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
               }

           }
       }
        if (trips[index]["medSw"] === 'N') {
            trips[index]["medStatus"] = 'No';
            trips[index]["medFirstname"] = null;
            trips[index]["medLastname"] = null;
            trips[index]["medDob"] = null;
            trips[index]["medId"] = null;
            trips[index]["medicaidDocuments"] = [];
            let mileageExpDetails = {...mileageDetails};
            trips[index]["internalOrder"] = mileageExpDetails.expInternalOrder;
            trips[index]["costCenter"] = mileageExpDetails.expCostCenter;
            trips[index]["ledgerCode"] = mileageExpDetails.ledgerCode;
            setCurrentMedicaid({ medFirstname: null, medLastname: null, medDob: null, medId: null, index: index, internalOrder: mileageExpDetails.expInternalOrder, costCenter: mileageExpDetails.expCostCenter, notes: null, ledgerCode: mileageExpDetails.ledgerCode, medicaidDocuments: []});
            setMedicaid('N');
        }
        if((trips[index]["medSw"] === 'Y') && (newDetails.expReason === null || newDetails.expReason === 0) || (newDetails.expenseDate === null))
        {
            trips[index]["internalOrder"] = null;
            trips[index]["costCenter"] = null;
            trips[index]["ledgerCode"] = null;
            //setMedicaid('N');
            trips[index]["medSw"] = 'N';
            setCurrentMedicaid({index: index, medSw: 'N', internalOrder: null, costCenter: null, ledgerCode:null, medicaidDocuments: [] });
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "Expense Date and Reason For Expense cannot be empty" });
            }, 1000);
        }
        if (trips[index]["medSw"] === 'Y' && (newDetails.expReason !== null && newDetails.expReason !== 0) && (newDetails.expenseDate !== null) && (moment(newDetails.expenseDate, 'MM/DD/YYYY').isValid())) {
            let newDetails = { ...mileageDetails };
            let request = {
                expense: 'MI',
                expenseType: 'ME',
                reasonForExpense: newDetails.expReason,
                userType: props.userInfo.userType,
                division: (props.travellerInfo.accountActive !== null && props.travellerInfo.accountActive === '0') ? props.reimbursement.division : (props.userInfo.role === 'PR') ? props.userInfo.division : props.travellerInfo.divisionCode,
                startDate: new Date(newDetails.expenseDate)
            }      
            trips[index]["internalOrder"] =null;
            trips[index]["costCenter"] =null;
            trips[index]["ledgerCode"] =null;  
            
            props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
            postFetch("/financialAdministration/loadIOCC", request).then((data) => {
                if (!data.message) {
                    props.loader({ loader: false, notification: false, message: "success" });
                    trips[index]["internalOrder"] = data.internalOrder;
                    trips[index]["costCenter"] = data.costCenter;
                    trips[index]["ledgerCode"] = data.generalLedgerCode;
                    trips[index]["medSw"] = 'Y'
                    
                    //if(request.userType === 'EM' && request.expenseType !== 'ME')
                    if(data.internalOrder === null || data.internalOrder === '')
                    {
                        trips[index]["internalOrder"] = props.travellerInfo.empInternalOrder;
                        trips[index]["costCenter"] = props.travellerInfo.empCostCenter;
                    }
                }
                else {
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                    }, 1000);
                }
                setCurrentMedicaid({ medFirstname: trips[index]["medFirstname"], medLastname: trips[index]["medLastname"], medDob: trips[index]["medDob"], medId: trips[index]["medId"], index: index, internalOrder: trips[index]["internalOrder"], costCenter: trips[index]["costCenter"], notes: null, ledgerCode: trips[index]["ledgerCode"], medicaidDocuments: trips[index]["medicaidDocuments"] });
            }).catch((e) => {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
                }, 1000);
            });
            // getIOCC(request);
            //setCurrentMedicaid({ medFirstname: trips[index]["medFirstname"], medLastname: trips[index]["medLastname"], medDob: trips[index]["medDob"], medId: trips[index]["medId"], index: index, internalOrder: trips[index]["internalOrder"], costCenter: trips[index]["costCenter"], notes: null, ledgerCode: trips[index]["ledgerCode"] });
            setMedicaid('Y');
        }
        setAddresses(trips);
    }
    const Medicaid = (row, column, index) => {
        return (
            <FormControl component="fieldset">
                <RadioGroup row aria-label="med-sw" name="med-sw" value={row.medSw} onChange={(e) => changeTripDetails(index, 'medSw', e.target.value)}>
                    <div className={classes.radioButton}>
                        <FormControlLabel value="Y" control={<Radio disabled={readOnly || allowMedicaid} color="primary" />} label="Yes" />
                        <FormControlLabel value="N" control={<Radio disabled={readOnly || allowMedicaid} color="primary" />} label="No" />
                    </div>
                </RadioGroup>
            </FormControl>)
    }

    const documentMedicaid = (row, column, index) => {
        return (
            <FormControl component="fieldset">
                <RadioGroup row aria-label="med-sw" name="med-sw" value={row.medSw} onChange={(e) => changeDocumentDetails(index, 'medSw', e.target.value)}>
                    <div className={classes.radioButton}>
                        <FormControlLabel value="Y" control={<Radio disabled={readOnly} color="primary" />} label="Yes" />
                        <FormControlLabel value="N" control={<Radio disabled={readOnly} color="primary" />} label="No" />
                    </div>
                </RadioGroup>
            </FormControl>)
    }

    const changeDocumentDetails = (index, key, value) => {
        let updatedDocs = [...documents].filter((doc) => doc.delSw !== 'Y');
        updatedDocs[index][key] = value;
        setDocuments(updatedDocs);
    }

    const documentActionButton = (row, column, index) => {
        return (<div style={{ cursor: 'pointer' }}><VisibilityRoundedIcon onClick={() => viewDocument(row, index)} style={{ fontSize: 20 }} /><DeleteIcon disabled={readOnly} onClick={() => readOnly ? null : changeDocumentDetails(index, 'delSw', 'Y')} style={{ fontSize: 20 }} /></div>);
    }

    const medicaidDocumentMedicaidButton = (row, column, index) => {
        return (
            <FormControl component="fieldset">
                <RadioGroup row aria-label="med-sw" name="med-sw" value={row.medSw} onChange={(e) => changeMedicaidDocumentDetails(index, 'medSw', e.target.value)}>
                    <div className={classes.radioButton}>
                        <FormControlLabel value="Y" control={<Radio disabled color="primary" />} label="Yes" />
                        <FormControlLabel value="N" control={<Radio disabled color="primary" />} label="No" />
                    </div>
                </RadioGroup>
            </FormControl>)
    }

    const changeMedicaidDocumentDetails = (ind, key, value) => {
        let updatedDocs = [...currentMedicaid.medicaidDocuments].filter((doc) => doc.delSw !== 'Y');
        updatedDocs[ind][key] = value;
        //setCurrentMedicaid(updatedDocs);
        setCurrentMedicaid(prevDetails => ({
            ...prevDetails,
            medicaidDocuments: updatedDocs
        }));
        // setCurrentMedicaid({ 
        //     ...currentMedicaid, 
        //     medicaidDocuments: currentMedicaid.medicaidDocuments.map((el, index) => index === ind && el === key ? value : el)
        //   })
    }

    const medicaidDocumentActionButton = (row, column, index) => {
        return (<div style={{ cursor: 'pointer' }}><VisibilityRoundedIcon onClick={() => viewDocument(row, index)} style={{ fontSize: 20 }} /><DeleteIcon disabled={readOnly} onClick={() => readOnly ? null : changeMedicaidDocumentDetails(index, 'delSw', 'Y')} style={{ fontSize: 20 }} /></div>);
    }

    const actionButton = (row, column, index) => {
     return (<><div style={{ cursor: 'pointer' }} > {( readOnly || returnTripBtnDisabled[index]) === true ? <Tooltip title="Return Trip"><Repeat  style={{ fontSize: 50, width: '20px', color: 'darkgrey'}} ><Cached style={{color:returnTripBtnDisabled[index] ? 'darkgrey':'black'}} ></Cached></Repeat></Tooltip> :<Tooltip title="Return Trip"><Repeat  style={{ fontSize: 50, width: '20px' }} onClick={() => addReturnTrip(row, index)} disabled={returnTripBtnDisabled[index]} ><Cached style={{color:returnTripBtnDisabled[index] ? 'darkgrey':'black'}} ></Cached></Repeat></Tooltip> }<Tooltip title="Notes"  ><EditRoundedIcon onClick={() => viewMedicaid(row, index)} style={{ fontSize: 50, width: '20px' }}  ></EditRoundedIcon></Tooltip><Tooltip title='Delete'><DeleteIcon disabled={readOnly} onClick={() => readOnly ? null : deleteMed(index)} style={{ fontSize: 50, width: '20px' }} /></Tooltip></div></>);   
     }
    

    function addNewAddress() {
        if(((props.userInfo.role === 'PR') ? props.userInfo.division === 'DCFS' :  props.travellerInfo.divisionCode === 'DCFS') || ((props.travellerInfo.accountActive !== null && props.travellerInfo.accountActive === '0') && props.reimbursement.division === 'DCFS'))
        {
            setAllowMedicaid(false);
        }
        let newDetails = {...mileageDetails};
        if((newDetails.expReason === null || newDetails.expReason === 0) || (newDetails.expenseDate === null))
        {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "Expense Date and Reason For Expense cannot be empty before adding trips." });
            }, 1000);
        }else
        {
            let newAddresses = [...addresses];
            newAddresses.push({ fromAddress: null, toAddress: null, amount: 0, miles: null, medSw: 'N', medStatus: 'No', fromAddressDir: null, toAddressDir: null, medFirstname: null, medLastname: null, medDob: null, medId: null, mileageExpId: null, id: null, internalOrder: null, costCenter: null, delSw: 'N', notes: null, ledgerCode: null, medicaidDocuments: []});
            setAddresses(newAddresses);
            setIsFileUploaded(false);
            setCurrentMedicaid({ medFirstname: null, medLastname: null, medDob: null, medId: null, index: (newAddresses.length - 1), internalOrder: null, costCenter: null, notes: null, ledgerCode: null, medicaidDocuments: [] });
            //setIsRREdited(true);
            setSelectedIndex(null);
            setMedicaid('N');
        }
    }

    const addReturnTrip = (row, index) => {
        //console.log(returnTripBtnDisabled);
        if(row.toAddress !== null && row.fromAddress !== null && row.miles !== null)
        {
            let newReturnTripBtnDisabled = {...returnTripBtnDisabled};
            newReturnTripBtnDisabled[index] = true;
            newReturnTripBtnDisabled[index+1] = false;
            setReturnTripBtnDisabled(newReturnTripBtnDisabled); 
            setIsFileUploaded(false);
            if(((props.userInfo.role === 'PR') ? props.userInfo.division === 'DCFS' :  props.travellerInfo.divisionCode === 'DCFS') || ((props.travellerInfo.accountActive !== null && props.travellerInfo.accountActive === '0') && props.reimbursement.division === 'DCFS'))
            {
                setAllowMedicaid(false);
            }
            setSelectedIndex(null);
            setMedicaid('N');
            let trip = { fromAddress: row.toAddress, toAddress: row.fromAddress, amount: 0, miles: null, medSw: 'N', medStatus: 'No', fromAddressDir: row.toAddressDir, toAddressDir: row.fromAddressDir, medFirstname: null, medLastname: null, medDob: null, medId: null, mileageExpId: null, id: null, internalOrder: row.internalOrder, costCenter: row.costCenter, delSw: 'N', notes: null, ledgerCode: row.ledgerCode, medicaidDocuments: []};
            getMiles(trip);
        }else
        {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "Please ensure mileage has been calculated. From Address and To Address must be valid addresses to proceed." });
            }, 1000);
        }
       
    }

    const getMiles = (trip) => {


        const service = new google.maps.DistanceMatrixService();
        setTimeout(() => {
            service.getDistanceMatrix(
                {

                    origins: [{placeId:trip.fromAddressDir}],
                    destinations: [{placeId:trip.toAddressDir}],
                    travelMode: 'DRIVING',
                    unitSystem: 1,
                    avoidHighways: false,
                    avoidTolls: false
                }, callback);
        }, 1000);
        function callback(response, status) {
            if (status === "OK") {
                if(response.rows[0].elements[0].status === "OK")
                {
                    trip["miles"] = response.rows[0].elements[0].distance.text;
                    let newAddresses = [...addresses];
                    newAddresses.push(trip);
                    setAddresses(newAddresses);
                    let newDetails = {...mileageDetails};
                    newDetails.tripDetails = newAddresses;
                    newDetails = calculateExp(newDetails);
                    setMileageDetails(newDetails);        
                    setCurrentMedicaid({ medFirstname: null, medLastname: null, medDob: null, medId: null, index: (newAddresses.length - 1), internalOrder: null, costCenter: null, notes: null, ledgerCode: null, medicaidDocuments: [] });
                    props.loader({ loader: false, notification: false });
                }else
                {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: "Please enter valid address." });
                }
                
            }
            else {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            }

        }

    }

    function deleteMed(index) {

        // let newReturnTripBtnDisabled = {...returnTripBtnDisabled}
        // delete newReturnTripBtnDisabled[index];
        // newReturnTripBtnDisabled[index-1] = !newReturnTripBtnDisabled[index-1];
        // setReturnTripBtnDisabled(newReturnTripBtnDisabled);
        let newAddresses = [...addresses];
        newAddresses[index]["delSw"] = 'Y';
        newAddresses.splice(index, 1);
        setSelectedIndex(null);
        setAddresses(newAddresses);
        if(mileageDetails.tripDetails[index] !== undefined)
        {    
            let request = {};
            request.id = mileageDetails.tripDetails[index].id;
            request.mileageExpId = mileageDetails.tripDetails[index].mileageExpId;
            let removedTrip = mileageDetails.tripDetails[index].miles;
            let miles = removedTrip === null ? parseFloat(0) : removedTrip.split(' ')[1].replace(',','') === 'ft' ? parseFloat(0) : parseFloat(removedTrip.split(' ')[0].replace(',',''));
            mileageDetails.totalMileage = parseFloat(mileageDetails.totalMileage - miles).toFixed(2);
            let mileage_Rate = fetchMileageRate(mileageDetails.expenseDate);
            mileageDetails.amount = parseFloat(parseFloat(mileage_Rate)*mileageDetails.totalMileage).toFixed(2);
            mileageDetails.tripDetails.splice(index,1);
            request.totalMileage = mileageDetails.totalMileage;
            request.amount = mileageDetails.amount;
            /*postFetch("/mileage/deletesw", request).then((data) => {
                if (!data.message) {
                    setTimeout(() => {
                        //props.loader({ loader: false, notification: true, message: "success", validationMessage: "Mileage expense saved successfully." });
                    }, 1500);
                    setTimeout(() => {
                        //setOnInit(true);
                    }, 3000);
                }
                else {
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                    }, 1000);
                }
            }).catch((e) => {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
                }, 1000);
            }); */
            let newReturnTripBtnDisabled = {};
            for(let [i,g] of mileageDetails.tripDetails.filter(el => el.delSw === 'N').entries())
            {
                if (i !== mileageDetails.tripDetails.filter(el => el.delSw === 'N').length -1)
                {
                    newReturnTripBtnDisabled[i] = true;
                }else
                {
                    newReturnTripBtnDisabled[i] = false;
                }
                
            }
            //newReturnTripBtnDisabled[mileageDetails.tripDetails.length -1] = false;
            setReturnTripBtnDisabled(newReturnTripBtnDisabled); 
            setMileageDetails(mileageDetails);
        }

        setMedicaid('N');
    }

    React.useEffect(() => {
        return ()=>{
            console.log("Component unmount");
        }
    },)

    React.useEffect(() => {
        const loadMileageDetails = async () => {
            props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
            setOnInit(false);
            history.push("/tr1");
            setSelectedIndex(null);
            const request = {
                rembId: parseInt(localStorage.getItem("requestId"))
            }           
            await postFetch("/mileage/getByRembId", request).then((data) => {
                if (!data.message) {
                    let summary = [];
                    let totalMileage = 0;
                    let totalAmount = 0;
                    let existingDates = localStorage.getItem("dates").split(",");
                    existingDates = existingDates.filter((date) => date.indexOf("mi") === -1);
                    
                    //let medCount = [...data.tripDetails].filter((mil) => (mil.medSw === 'Y'));
                    //let mediApprover = props.staticData.dropdown.MedicaidApprovers.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0;
                    //console.log("medCount " + medCount + " mediApprover " + mediApprover);

                    data.forEach(exp => {
                        let medCount = [...exp.tripDetails].filter((mil) => (mil.medSw === 'Y' && mil.medApprovalStatus === null));
              
                        let mediApprover = props.staticData.dropdown.MedicaidApprovers.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0;
                        exp.sumexpenseDate = convertDateFromServer(exp.expenseDate);
                        existingDates = existingDates + "," + exp.expenseDate + "mi";
                        exp.sumexpenseReason = props.staticData.reference.ReasonForExpense.value.find((config) => config.value === exp.expReason).name;
                        exp.sumamount = "$" + exp.amount;
                        if(medCount.length > 0  && mediApprover && exp.status !== 'RR'){
                            exp.sumstatus = props.staticData.reference.ReimbursementStatus.value.find((config) => config.value === 'SB').name;
                        }else{
                            exp.sumstatus = props.staticData.reference.ReimbursementStatus.value.find((config) => config.value === exp.status).name;
                        }

                        if(exp.status === 'SB' && exp.nonMedStatus === null){
                            exp.nonMedStatusRevReason = null;
                            exp.nonMedStatus = null;
                        }

                        if(exp.status === 'AP' && exp.nonMedStatus === 'AP' && exp.medStatus === 'RR'){
                            exp.medStatus = null;
                            exp.mediStatusRevReason = null;
                        }
                        
                        if (exp.delSw !== 'Y') {
                            summary.push(exp);
                            totalMileage = parseFloat(totalMileage) + parseFloat(exp.totalMileage);
                            totalAmount = parseFloat(totalAmount) + parseFloat(exp.amount);
                        }
                    });
                    localStorage.setItem("dates", existingDates);
                    let temp = [];
                    summary.forEach(sum => {
                        temp.push([...sum.tripDetails])
                        sum.medicaid = [...sum.tripDetails].filter((mil) => (mil.medSw === 'Y')).length > 0 ? 'Yes' : 'No';
                    })

                    setSummaryData(summary);
                    setInitialTrips(temp);
                    setTotalMileage(parseFloat(totalMileage).toFixed(2));
                    setTotalAmount(parseFloat(totalAmount).toFixed(2));
                    setTimeout(() => {
                        props.loader({ loader: false, notification: false });
                    }, 1000);
                    if (localStorage.getItem("readOnly") !== null) {
                        setReadOnly(true);
                    }           

                }
                else {
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                    }, 1000);
                }
            }).catch((e) => {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
                }, 1000);
            });
        }
        if (onInit) {
            if ((localStorage.getItem("requestId") !== undefined) && (localStorage.getItem("requestId") !== null)) {
                loadMileageDetails();
                getStateHolidays();
            }           
        }
        if(((props.userInfo.role === 'PR') ? props.userInfo.division === 'DCFS' :  props.travellerInfo.divisionCode === 'DCFS') || ((props.travellerInfo.accountActive !== null && props.travellerInfo.accountActive === '0') && props.reimbursement.division === 'DCFS'))
        {
            setAllowMedicaid(false);
        }
       
        
    }, [props, onInit, summaryData, history]);

    function convertDateFromServer(value) {
        if ((value !== null)
            && !(value instanceof Date)) {
            let serverDateArray = value.split("-");
            if (serverDateArray.length > 1) {
                return serverDateArray[1] + "/" + serverDateArray[2] + "/" + serverDateArray[0];
            }
            else {
                return serverDateArray[0];
            }
        }
        else if (value instanceof Date) {
            return value;
        }
        else {
            return null;
        }

    }

    const checkMileageExpenseDate = async (expenseDate) => {
        if (!expenseDate) {
            // Skip the check if no date is provided
            return;
        }
        let formattedExpenseDate = moment(expenseDate).format('YYYY-MM-DD');
        let details = { expenseDate: formattedExpenseDate, userId: props.userInfo.id };
    
        try {
            const data = await postFetch("/expense/checkMileageExpenseDate", details);
            if (data.exists) {
                const mileageExpenses = data.reimbursementData.filter(item => item.expenseType === 'mileage');
                if (mileageExpenses.length > 0) {
                    const reimbursementIds = [...new Set(mileageExpenses.map(item => item.reimbursementId))];
                    if (reimbursementIds.length > 0) {
                        const reimbursementIdsString = reimbursementIds.join(", ");
                        const message = `You already have a mileage expense for this date in Reimbursement ${reimbursementIdsString}. Please ensure this is not a duplicate expense.`;
                        
                        // Set the warning message and ensure it is shown
                        setWarningMessage(message);
                        setShowWarning(true);
                    }
                }
            } else {
                // Clear the warning if no expense exists
                setWarningMessage("");
                setShowWarning(false);
            }
        } catch (e) {
            // Handle system error and show warning
            setWarningMessage("System error. Please try again later.");
            setShowWarning(true);
        }
    }

  
    const saveMileage = async () => {
        
        if (document.getElementsByClassName("Mui-error").length > 0) {
            setError(true);
        } 
       else if ([...addresses].filter((med) => med.medSw === 'Y').some((mil) => {// Filter only Medicaid-related trips
            const validMileageDocuments = mil.medicaidDocuments.filter((doc) => doc.delSw === 'N');
            return validMileageDocuments.length === 0; })) {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "At least one Medicaid document needs to be uploaded per Medicaid-related trip to submit expense."  });
    }
        else if ([...addresses].filter((mil) => (mil.medSw === 'Y' && mil.medStatus === 'No')).length > 0) {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "One or More Medicaid details are not verified." });
        } else if ([...addresses].filter((mil) => (mil.miles === null)).length > 0) {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "One or More trips have no mileage details." });
        } else if (props.reimbursement.expenseStartDate === null || props.reimbursement.expenseEndDate === null) {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "Total Reimbursement Expense Start Date or Expense End Date is missing" });
        } else if (mileageDetails.tripDetails.length < 1) {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "Please add at least one trip." });
        } else if (((mileageDetails.status !== 'IP') && (props.reimbursement.assignedTo !== null) && (props.reimbursement.assignedTo === props.userInfo.id)) && mileageDetails.status === 'SB' && props.reimbursement.status === 'SB') {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "Please Complete Status Details." });
        } else if (((mileageDetails.status !== 'IP') && (props.reimbursement.assignedTo !== null) && (props.reimbursement.medSupervisor !== null) && (props.reimbursement.medSupervisor.toString() === props.userInfo.id.toString()) && medicaidSw) && mileageDetails.medStatus === 'IP' && props.reimbursement.status === 'SB') {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "Please Complete Status Details." });
        } else {    
            if (localStorage.getItem("requestId") === null) {
                props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
                let request = { ...props.reimbursement };
                request.expenseStartDate = request.expenseStartDate === null ? null : new Date(request.expenseStartDate);
                request.expenseEndDate = request.expenseEndDate === null ? null : new Date(request.expenseEndDate);
                
                try {
                    const data = await postFetch("/reimbursement/save", request);
                    if (!data.message) {
                        setTimeout(() => {
                            localStorage.setItem("requestId", data.id);
                            localStorage.setItem("recordVersion", data.recordVersion);
                            saveExpense(data.id);
                        }, 1000);
                    } else {
                        setTimeout(() => {
                            props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                        }, 1000);
                    }
                } catch (e) {
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
                    }, 1000);
                }
            } else {
                saveExpense(localStorage.getItem("requestId"));
            }
        }
    };

    const saveExpense = async (reimId) => {
        if ([...addresses].filter((mil) => (mil.medSw === 'Y' && mil.medStatus === 'No')).length > 0) {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "One or More Medicaid details are not verified." });
        }
        else {
            props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
            let request = { ...mileageDetails };

            request.rembId = reimId;
            request.expenseDate = new Date(request.expenseDate);
            request.rate = parseFloat(mileageRate);
            request.totalMileage = request.totalMileage !== null ? parseFloat(request.totalMileage) : null;
            request.amount = request.amount !== null ? parseFloat(request.amount) : null;
            request.dhsCardAmount = request.dhsCardAmount !== null ? parseFloat(request.dhsCardAmount) : null;
            //request.ledgerCode = props.staticData.dropdown.GeneralLedgerCodes.value.find((config) => config.value === request.expReason).name;
            request.tripDetails = [...addresses];
            request.documents = [...documents];
            
                     

            let medSt  = request.medStatus;

            if (props.reimbursement.status === 'RR') {
                let oldMileageDetls = { ...oldMileageDetails };
                oldMileageDetls.expenseDate = new Date(oldMileageDetls.expenseDate);
                oldMileageDetls.rembId = reimId;
                if (!_.isEqual(oldMileageDetls, request)) {
                    request.status = request.medStatus = request.nonMedStatus = 'IP';
                }
            }
            //request.status = (props.reimbursement.status === 'RR' && isEdited) ? 'IP' : request.status;
            let medicaidCount = [...request.tripDetails].filter((mil) => (mil.medSw === 'Y'));
            if (medicaidCount.length > 0 && request.status !== 'IP' && request.status !== 'RR') {
                if (medicaidCount.length === [...request.tripDetails].length) {
                    request.status = 'AP';
                    request.medStatus = request.medStatus !== null ? request.medStatus : request.nonMedStatus;
                    request.revReason = request.medStatusRevReason;
                }
                else {
                    request.status = request.nonMedStatus;
                    //request.status = (request.medStatus !== null && request.medStatus !== 'AP') ? request.medStatus : ((request.medStatus !== null) ? request.nonMedStatus : "SB");
                }
            }
            else if (medicaidCount.length === 0 && request.status !== 'IP' && request.status !== 'RR') {
                request.status = request.nonMedStatus;
                request.revReason = request.nonMedStatusRevReason;
            }else if (medicaidCount.length > 0 && request.status === 'IP'){
                request.status = request.nonMedStatus;
                request.medStatus = request.nonMedStatus;
                //request.nonMedStatus = null;
                
            }
            if(medicaidCount.length > 0 && request.medStatus === 'AP'){
                request.tripDetails.forEach((trip) => {
                    trip.medApprovalStatus = 'AP';
                })
            }
            
            if (medicaidCount.length > 0 && request.status === 'RR') {
                request.medStatus = request.status;
                request.medStatusRevReason = request.medStatusRevReason;
            }

            if (medicaidCount.length === 0 && request.status === 'RR') {
                request.nonMedStatus = request.status;
                request.nonMedStatusRevReason = request.nonMedStatusRevReason;
            }

            if(medSt === 'RR' &&  props.staticData.dropdown.MedicaidApprovers.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0){
                request.medStatus = 'XX';
            }
            if(medSt === 'XX'){request.medStatus = 'XX';}

            if(request.status === 'AP' && request.medStatus === 'AP' && props.staticData.dropdown.MedicaidApprovers.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0){
                request.medicaidApproved = true;
            }

            if((props.travellerInfo.accountActive !== null && props.travellerInfo.accountActive === '0') && request.status === 'IP' && props.reimbursement.assignedTo === props.userInfo.id)
            {
                request.status = 'SB'
            }

            request.userId = request.userId || props.userInfo.vmUserId;
            await postFetch("/mileage/save", request).then((data) => {
                if (!data.message) {
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "success", validationMessage: "Mileage expense saved successfully." });
                    }, 1500);
                    setTimeout(() => {
                        setOnInit(true);
                    }, 3000);
                }
                else {
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                    }, 1000);
                }
            }).catch((e) => {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
                }, 1000);
            });
        }
    }
    

    const upload = (target, fromMethod, ind) => {
        if (target.files.length === 0) {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "No File Selected" });
        }
        else if (target.files[0].type.length === 0) {
            target.value = null;
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "Upload Failed. Please upload a document with a valid file extension such as .doc,.docx, .pdf, .txt, .jpeg etc." });
        }
        else {
            props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
            var fileByteArray = [];
            const fileReader = new FileReader();
            fileReader.onloadend = (evt) => {
                if (evt.target.readyState === FileReader.DONE) {
                    var arrayBuffer = evt.target.result,
                        array = new Uint8Array(arrayBuffer);
                    for (var i = 0; i < array.length; i++) {
                        fileByteArray.push(array[i]);
                    }
                }
            }
            fileReader.readAsArrayBuffer(target.files[0]);
            setTimeout(() => {
                let request = {};
                request.DocumentName = target.files[0].name;
                request.DocumentTitle = target.files[0].name + "_" + props.userInfo.id;
                request.DocumentID = null;
                request.DatabaseName = Config.edocutusDatabase;
                request.DocumentTypeID = Config.edocutusDocumentTypeId;
                request.DocumentData = fileByteArray;
                if (fileByteArray.length > 0) {
                    postFetch2("/document", request).then((data) => {
                        if (data.documentID !== null && !data.message) {
                            const newDocument = {
                                 id: null, 
                                 fileType: target.files[0].type, 
                                 fileName: (fromMethod === "Medicaid" ? "Mileage Medicaid Document - " : "Mileage Expense - ") + target.files[0].name, 
                                 size: (target.files[0].size / 1048576).toFixed(2) + " MB", 
                                 edocId: data.DocumentID, 
                                 medSw: fromMethod === "Medicaid" ?'Y' : 'N',  
                                 milId: null, 
                                 delSw: 'N' 
                                };
                                if(fromMethod === "Medicaid")
                                {
                                    let cm = [...currentMedicaid.medicaidDocuments];
                                    if(cm.length === 0)
                                    {
                                        cm = new Array();
                                    }
                                    cm.push(newDocument);                                   
                                    setCurrentMedicaid({...currentMedicaid, 
                                        medicaidDocuments : cm});

                                    let trips = [...addresses];
                                    trips[ind]["medicaidDocuments"] = cm;
                                    setAddresses(trips);
                                    //setCurrentMedicaid(updatedDocs);
                                    // setCurrentMedicaid(prevDetails => ({
                                    //     ...prevDetails,
                                    //     medicaidDocuments: updatedDocs
                                    // }));
                                    // setAddresses({...addresses, 
                                    //         medicaidDocuments : addresses.map((el, index) => index === ind ? currentMedicaid.medicaidDocuments : el)
                                    // });

                                }else
                                {
                                    let updatedDocs = [...documents];
                                    updatedDocs.push(newDocument);
                                    setDocuments(updatedDocs);
                                }
                           
                            target.value = null;
                            setTimeout(() => {
                                props.loader({ loader: false, notification: true, message: "success", validationMessage: fromMethod === "Medicaid" ? "Medicaid Document uploaded successfully" : "Document uploaded successfully" });
                            }, 1000);
                        }
                        else {
                            target.value = null;
                            setTimeout(() => {
                                props.loader({ loader: false, notification: true, message: "error", validationMessage: "Document upload failed" });
                            }, 1000);
                        }
                    }).catch(() => {  
                        target.value = null;
                        setTimeout(() => {
                             props.loader({ loader: false, notification: true, message: "error", validationMessage: "Document upload failed." });
                        }, 1000);
                      });
                }

            }, 1000);
        }
    }

    const viewDocument = (row, index) => {
        props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
        postFileDownload("/document?DocumentID=" + row.edocId + "&DatabaseName=" + Config.edocutusDatabase + "&DocumentTypeID=" + Config.edocutusDocumentTypeId).then((data) => {
            if (!data.message) {
                var fileUrl = "data:" + row.fileType + ";base64," + data;
                fetch(fileUrl)
                    .then(response => response.blob())
                    .then(blob => {
                        var link = window.URL.createObjectURL(blob, { type: row.fileType });
                        window.open(link);
                    });
                setTimeout(() => {
                    props.loader({ loader: false, notification: false });
                }, 1000);
            }
            else {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                }, 1000);
            }
        }).catch((e) => {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            }, 1000);
        });
    }


    const summaryActionButton = (row, column, index) => {
        return (<div style={{ cursor: 'pointer' }}><EditRoundedIcon onClick={() => viewExpense(row, index)} style={{ fontSize: 20 }} /><DeleteIcon onClick={() => readOnly ? null : deleteExpense(row)} style={{ fontSize: 20 }} /></div>);
    }

    const viewExpense = (row, index) => {
        setSelectedIndex(null);
        let details = { ...row };
        let newReturnTripBtnDisabled = {...returnTripBtnDisabled};
        details.expenseDate = new Date(convertDateFromServer(details.expenseDate));
        let updatedDetails = JSON.parse(JSON.stringify(details));
        updatedDetails.expenseDate = new Date(convertDateFromServer(details.expenseDate));
        setMileageDetails(updatedDetails);
        setDocuments([...details.documents]);
        const sortedTrip = initialTrips.find(trip => {
            return JSON.stringify(details.tripDetails) === JSON.stringify(trip);
        })
        details.tripDetails = sortedTrip ? JSON.parse(JSON.stringify(sortedTrip)): initialTrips[index].slice()
        details.tripDetails.forEach((trip) =>trip["delSw"] = "N" );
        details.tripDetails.forEach((trip) => {
            if (trip.medSw === 'Y' && trip.medDob !== null) {
                trip.medDob = new Date(convertDateFromServer(trip.medDob));
            }
        })
       
        for(let [i,g] of details.tripDetails.filter(el => el.delSw === 'N').entries())
        {
            if (i !== details.tripDetails.filter(el => el.delSw === 'N').length -1)
            {
                newReturnTripBtnDisabled[i] = true;
            }else
            {
                newReturnTripBtnDisabled[i] = false;
            }
            
        }
        //newReturnTripBtnDisabled[details.tripDetails.length -1] = false;
        setReturnTripBtnDisabled(newReturnTripBtnDisabled); 
        const updatedAddr = [];
        details.tripDetails.forEach(trip => {
            updatedAddr.push(trip)
        })
        setAddresses([...updatedAddr]);
        let medicaidCount = details.tripDetails.filter((trip) => trip.medSw === 'Y');
        setMedicaidSw(((medicaidCount.length > 0)) ? true : false);
        setMedicaid('N');
        setOldMileageDetails({ ...details });
        loadHistory({...details})
        history.push("/tr1/mileageexp");
    }

    const reset = async () => {
        setMedicaid('N');
        setEmgExpReason(false);
        //setIsRREdited(false);
        let details = { ...mileageDetails };
        if (details.rembId === null) {
            setMileageDetails({ expReason: null, expenseDate: null, justification: null, ledgerCode: null, rate: null, totalMileage: 0, amount: 0, dhsCardSw: 'N', dhsCardAmount: 0, revReason: null, rembId: null, status: 'IP', medStatus: null, nonMedStatus: 'IP', medStatusRevReason: null, nonMedStatusRevReason: null, delSw: 'N', emergencyExReason:'0', emergencyExSw:'N', expInternalOrder: null, expCostCenter: null ,outsideWorkHours: 'N'});
            setError(false);
            setAddresses([]);
            setMedicaid('N');
            setMedicaidSw(false);
            setCurrentMedicaid({ medFirstname: null, medLastname: null, medDob: null, medId: null, index: null, internalOrder: null, costCenter: null, ledgerCode: null, medicaidDocuments: []});
            setDocuments([]);
            setReturnTripBtnDisabled({0:false});  
        }
        else {
            props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
            let request = { ...details };
            await postFetch("/mileage/findById", request).then((data) => {
                if (!data.message) {
                    setTimeout(() => {
                        props.loader({ loader: false, notification: false, message: "success" });

                        let details = { ...data };
                        details.expenseDate = new Date(convertDateFromServer(details.expenseDate));
                        setMileageDetails({ ...details });
                        setDocuments([...details.documents]);
                        details.tripDetails.forEach((trip) => {
                            trip.medDob = new Date(convertDateFromServer(trip.medDob));
                        })
                        let newReturnTripBtnDisabled = {};
                        for(let [i,g] of details.tripDetails.filter(el => el.delSw === 'N').entries())
                        {
                            if (i !== details.tripDetails.filter(el => el.delSw === 'N').length -1)
                            {
                                newReturnTripBtnDisabled[i] = true;
                            }else
                            {
                                newReturnTripBtnDisabled[i] = false;
                            }
                            
                        }
                        
                        //newReturnTripBtnDisabled[newReturnTripBtnDisabled.length -1] = false;
                        setReturnTripBtnDisabled(newReturnTripBtnDisabled);  
                        setAddresses(details.tripDetails);
                        let medicaidCount = details.tripDetails.filter((trip) => trip.medSw === 'Y');
                        setMedicaidSw(((medicaidCount.length > 0)) ? true : false);
                       
                        forceUpdate();
                    }, 1000);
                }
                else {
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                    }, 1000);
                }
            }).catch((e) => {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
                }, 1000);
            });
        }
    }
    const deleteExpense = async (row) => {
        let details = { ...row };
        details.delSw = 'Y';
        props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
        let request = { ...details };
        await postFetch("/mileage/save", request).then((data) => {
            if (!data.message) {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "success", validationMessage: "Mileage expense saved successfully." });
                }, 1500);
                setOnInit(true);
            }
            else {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                }, 1000);
            }
        }).catch((e) => {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            }, 1000);
        });
    }

    function viewMedicaid(value, index) {
        setSelectedIndex(index);
        setCurrentMedicaid({ medFirstname: value.medFirstname, medLastname: value.medLastname, medDob: value.medDob, medId: value.medId, index: index, internalOrder: value.internalOrder, costCenter: value.costCenter , notes: value.notes, ledgerCode: value.ledgerCode, medicaidDocuments: value.medicaidDocuments});
        value.medSw === 'Y' ? setMedicaid('Y') : setMedicaid('N');
    }
    const saveTrip = async (index) => {
        if ((currentMedicaid.medId === null || currentMedicaid.medId === '') || (currentMedicaid.medFirstname === null || currentMedicaid.medFirstname === '')
            || (currentMedicaid.medLastname === null || currentMedicaid.medLastname === '') || (currentMedicaid.medDob === null)) {
            setMedError(true);
        }
        else {
            const invalidTrips = addresses.filter(address => {
                if(address.internalOrder == null || address.ledgerCode == null || address.costCenter == null) {
                    return address
                }
            });
            if(invalidTrips.length > 0){

                props.loader({ loader: false, notification: true, message: "error", validationMessage: "Internal order or Ledger code or cost center cannot be null" });
                return false;
            }       
            props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
            const request = {
                mediciadId: currentMedicaid.medId,
                firstName: currentMedicaid.medFirstname,
                lastName: currentMedicaid.medLastname,
                dateOfBirth: (currentMedicaid.medDob !== null) ? moment(currentMedicaid.medDob).format("YYYY-MM-DD") : null,
                inquiryBeginDate: (mileageDetails.expenseDate !== null) ? moment(mileageDetails.expenseDate).format("YYYY-MM-DD") : null,
                inquiryEndDt: (mileageDetails.expenseDate !== null) ? moment(mileageDetails.expenseDate).format("YYYY-MM-DD") : null
            }
            if(request.inquiryBeginDate === null){
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "Please enter an expense start date" });
            } else{
            await postFetch("/aries/verifyMedicaidStatus2", request).then((data) => {
                if (!data.message) {
                    if (data.ArMedicaidStatusInquiryResponse.MedicaidStatusCodes.StatusCode === '00') {
                        let newAddresses = [...addresses];
                        newAddresses[index]["medFirstname"] = currentMedicaid.medFirstname;
                        newAddresses[index]["medLastname"] = currentMedicaid.medLastname;
                        newAddresses[index]["medDob"] = currentMedicaid.medDob;
                        newAddresses[index]["medId"] = currentMedicaid.medId;
                        newAddresses[index]["internalOrder"] = currentMedicaid.internalOrder;
                        newAddresses[index]["costCenter"] = currentMedicaid.costCenter;
                        newAddresses[index]["medStatus"] = "Yes";
                        newAddresses[index]["ledgerCode"] = currentMedicaid.ledgerCode;
                        newAddresses[index]["medicaidDocuments"] = currentMedicaid.medicaidDocuments;
                        setAddresses(newAddresses);
                        setMedicaid('N');
                        setTimeout(() => {
                            props.loader({ loader: false, notification: true, message: "success", validationMessage: "Medicaid Verified Successfully" });
                        }, 1000);
                    }
                    else {
                        let message = 'Unable to verify Medicaid status: Invalid ';
                        data.ArMedicaidStatusInquiryResponse.MedicaidStatusCodes.StatusCode.toString().split(',').forEach((code) => {
                            message += props.staticData.reference.MedicaidStatusCodes.value.find((config) => config.value.toString() === code.toString()).name + ', ';
                        });
                        setTimeout(() => {
                            props.loader({ loader: false, notification: true, message: "error", validationMessage: message });
                        }, 1000);
                    }
                }
                else {
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
                    }, 1000);
                }
            }).catch((e) => {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
                }, 1000);
            });
        }
        }

    }

    const changeReimAmount = () => {
        let newDetails = { ...mileageDetails };
        newDetails = calculateExp(newDetails);
        setMileageDetails(newDetails);
    }

    const calculateExp = (newDetails) => {
        let miles = parseFloat(0);
        let amount = parseFloat(0);
        newDetails.tripDetails.forEach((trip) => {
            if ((trip.miles !== null) && (trip.miles.split(' ')[1].replace(',', '') !== "ft")) {
                let updatesMiles = parseFloat(trip.miles.split(' ')[0].replace(',', ''));
                let mileage_Rate = fetchMileageRate(mileageDetails.expenseDate);
                trip.amount = parseFloat((parseFloat(mileage_Rate) * updatesMiles).toFixed(2));
                miles = miles + updatesMiles;
                amount = amount + parseFloat((parseFloat(mileage_Rate) * updatesMiles).toFixed(2));
            }
        });
        if ((newDetails.dhsCardAmount !== null) && (newDetails.dhsCardAmount !== '') && (parseFloat(newDetails.dhsCardAmount) <= amount)) {
            amount = amount - parseFloat(newDetails.dhsCardAmount);
        }
        else if ((newDetails.dhsCardAmount !== null) && (newDetails.dhsCardAmount !== '') && (parseFloat(newDetails.dhsCardAmount) > amount)) {
            newDetails.dhsCardAmount = null;
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: 'DHS Credit Card Amount should be less than Total Expense Amount' });
            }, 1000);
        }
        newDetails.totalMileage = parseFloat(miles.toFixed(2)).toFixed(2);
        newDetails.amount = parseFloat(amount.toFixed(2)).toFixed(2);
        return newDetails;
    }

    function fetchMileageRate(expdate) {
        let mileageRate = parseFloat(0);
        if (expdate !== null) {
            let configValue = props.staticData.dropdown.PerDiemRates.value;
            configValue.forEach((mrates) => {
                if (mrates.name.includes('MileageRate')) {
                    let ratesArray = mrates.name.split('|');
                    var date1 = Date.parse(ratesArray[2].replace('-', '/').replace('-', '/'));
                    var date2 = Date.parse(ratesArray[3].replace('-', '/').replace('-', '/'));
                    if (expdate >= new Date(date1) && expdate <= new Date(date2)) {
                        mileageRate = parseFloat(ratesArray[1]);
                    }
                }
            });
        }
        return mileageRate;
    }
    const changeMileageDetails = (key, value) => {
        let newDetails = { ...mileageDetails };
        newDetails.tripDetails = [...addresses];
        // if(newDetails[key] !== value)
        // {
        //     setIsRREdited(true);
        // }
        if (key === 'expenseDate' && (value !== null) && (props.reimbursement.expenseStartDate !== null) && (value.setHours(0, 0, 0) < new Date(new Date(props.reimbursement.expenseStartDate).toDateString()).setHours(0, 0, 0))) {
            value = null;
            props.loader({ loader: false, notification: true, message: "error", validationMessage: 'Expense Date cannot be prior to Total Reimbursement Expense Start Date' });
        }
        if (key === 'expenseDate' && (value !== null) && (props.reimbursement.expenseEndDate !== null) && (value.setHours(0, 0, 0) > new Date(new Date(props.reimbursement.expenseEndDate).toDateString()).setHours(0, 0, 0))) {
            value = null;
            props.loader({ loader: false, notification: true, message: "error", validationMessage: 'Expense Date cannot be greater than Total Reimbursement Expense End Date' });
        }
        if ((key === 'dhsCardSw')) {
            newDetails.dhsCardAmount = null;
            newDetails = calculateExp(newDetails);
        }

        newDetails[key] = value;
        if (key === 'nonMedStatus' || key === 'medStatus') {
            newDetails['status'] = value;
        }
        if (key === 'expenseDate') {
            if (value !== null && value !== "") {
                // Update the expense date in the state
                newDetails[key] = value;
                setMileageDetails(newDetails);  // Update date in state
    
                // Call the mileage expense check function only after a valid date is entered
                checkMileageExpenseDate(value).then(() => {
                    // Optionally set the mileage rate after checking the date
                    setMileageRate(fetchMileageRate(value));
                });
            } else {
                // Clear warning and reset state when the date is null or empty
                newDetails[key] = value;
                setMileageDetails(newDetails);
                setWarningMessage("");  // Clear warning message
                setShowWarning(false);  // Hide warning
            }
        } else {
            // For other fields, just update the state
            newDetails[key] = value;
            setMileageDetails(newDetails);
        }
    
        if(key === 'emergencyExSw'){
            newDetails.emergencyExSw = value;
            newDetails.emergencyExReason='0';
            newDetails.amount = value === 'N' ? 0 : newDetails.amount;
            newDetails.totalMileage = value === 'N' ? 0 : newDetails.totalMileage;
            setAddresses([]);
            setCurrentMedicaid([])
            if(value  === 'N'){
                setEmgExpReason(false);
            }else{
                setEmgExpReason(true); 
            }
        }

        if(key === 'emergencyExReason'){
            newDetails.emergencyExReason = value;
        }
        if((key === "expReason" || key === "expenseDate") && (newDetails.expReason === null || newDetails.expReason === 0) || (newDetails.expenseDate === null))
        {
            newDetails.ledgerCode = null;
            newDetails.expInternalOrder = null;
            newDetails.expCostCenter = null;
            //setMedicaid('N');
            //setCurrentMedicaid({medSw: 'N', internalOrder: null, costCenter: null, ledgerCode: null });
            if(newDetails.tripDetails.length > 0 && medicaid === 'Y')
            {
                changeTripDetails(currentMedicaid.index,'medSw', 'N' );
            }
        }
       
        if(key === 'expenseDate' && value !== null && props.userInfo.userType === 'EM')
        {        
           
            var expenseDtStateHoliday = stateHoliday.filter((holiday) => holiday.holidayDate === moment(value).format("YYYY-MM-DD") && holiday.active === 'Y').length > 0 ? true : false;
            var dayOfWeek = new Date(value).getDay();
            if((dayOfWeek === 6 || dayOfWeek  === 0) || (expenseDtStateHoliday)){
                    newDetails.outsideWorkHours = 'Y';
                    mileageDetails["outsideWorkHours"] = 'Y';
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: "Expense Date selected requires Request to Travel Outside Normal Work Schedule Preapproval attachment prior to submission. Please attach your preapproval on the Travel Reconciliation Screen." });
                    }, 1000);
                } else{
                    newDetails.outsideWorkHours = 'N';
                    mileageDetails["outsideWorkHours"] = 'N';
            }
            // postFetch("/expense/checkStateHoliday", request).then((data) => {
            //     if (!data.message) {
            //         props.loader({ loader: false, notification: false, message: "success" });
            //         var dayOfWeek = new Date(value).getDay();
            //         if((dayOfWeek === 6 || dayOfWeek  === 0) || (data.holidayCount > 0)){
            //                 newDetails.outsideWorkHours = 'Y';
            //                 setTimeout(() => {
            //                     props.loader({ loader: false, notification: true, message: "error", validationMessage: "Expense Date selected requires Request to Travel Outside Normal Work Schedule Preapproval attachment prior to submission. Please attach your preapproval on the Travel Reconciliation Screen." });
            //                 }, 1000);
            //             } else{
            //                 newDetails.outsideWorkHours = 'N';
            //             }
            //     }
            //     else {
            //         setTimeout(() => {
            //             props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
            //         }, 1000);
            //     }
            // }).catch((e) => {
            //     setTimeout(() => {
            //         props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            //     }, 1000);
            // });
        }
        setMileageDetails(newDetails);
        if(key === 'expenseDate'){
            setEmgExpReason(false);
            newDetails.emergencyExReason='0'
            newDetails.emergencyExSw='N'
            if(moment(value, 'MM/DD/YYYY').isValid()){
                loadHistory(newDetails);
            }
           
        }

        if((key === "expReason" || key === "expenseDate" || key === "medSw") && (newDetails.expReason !== null && newDetails.expReason !== 0) && (newDetails.expenseDate !== null) && (moment(newDetails.expenseDate, 'MM/DD/YYYY').isValid())){
            let request = {
                expense: 'MI',
                expenseType: (newDetails.medSw !== null && newDetails.medSw === 'Y' ? 'ME' : 'NM'),
                reasonForExpense: newDetails.expReason,
                userType: props.userInfo.userType,
                division:  (props.travellerInfo.accountActive !== null && props.travellerInfo.accountActive === '0') ? props.reimbursement.division : (props.userInfo.role === 'PR') ? props.userInfo.division : props.travellerInfo.divisionCode,
                startDate: new Date(newDetails.expenseDate)
            }
            //setMedicaid('N');            
           // setMedicaidSw(false);            
            getIOCC(request);
            if(newDetails.tripDetails.length > 0 && medicaid === 'Y')
            {
                changeTripDetails(currentMedicaid.index,'medSw', 'N' );
            }
        }
    }
    const getStateHolidays = async () => {
        let details = { id: null };
        props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
        await postFetch("/expense/getStateHoliday", details).then((data) => {
            if (!data.message) {
                setTimeout(() => {
                    props.loader({ loader: false, notification: false, message: "success" });
                    setStateHoliday([...data]);
                }, 1000);
            }
            else {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                }, 1000);
            }
        }).catch((e) => {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            }, 1000);
        });
    }
    
    const getIOCC = async (request) => {
        let newDetails = { ...mileageDetails };
        props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
        postFetch("/financialAdministration/loadIOCC", request).then((data) => {
            if (!data.message) {
                props.loader({ loader: false, notification: false, message: "success" });
                newDetails.expInternalOrder = data.internalOrder;
                newDetails.expCostCenter = data.costCenter;
                newDetails.expReason = request.reasonForExpense;
                newDetails.ledgerCode = data.generalLedgerCode;
                newDetails.medSw = request.expenseType === 'ME' ? 'Y' : 'N';
                newDetails.expenseDate = request.startDate;
                //if(request.userType === 'EM' && request.expenseType !== 'ME')
                if(data.internalOrder === null || data.internalOrder === '')
                {
                    newDetails.expInternalOrder = props.travellerInfo.empInternalOrder;
                    newDetails.expCostCenter = props.travellerInfo.empCostCenter;
                }
                // setTimeout(() => {
                //     props.loader({ loader: false, notification: false });
                //     setSummaryData(data);
                //   }, 1000);
                newDetails.tripDetails.forEach((trip) => {
                    trip.ledgerCode = data.generalLedgerCode;
                });
                setMileageDetails(newDetails);
            }
            else {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                }, 1000);
            }
        }).catch((e) => {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            }, 1000);
        });
       // setMileageDetails(newDetails);
    }
    const addNewMileage = () => {     
        setMileageDetails({});  // Reset mileage details
        setWarningMessage("");  // Clear the warning message
        setShowWarning(false);  // Hide the warning message  
        setMileageDetails({ expReason: null, expenseDate: null, justification: null, ledgerCode: null, rate: null, totalMileage: 0, amount: 0, dhsCardSw: 'N', dhsCardAmount: 0, revReason: null, rembId: null, status: 'IP', medStatus: null, nonMedStatus: 'IP', medStatusRevReason: null, nonMedStatusRevReason: null, delSw: 'N', emergencyExReason:'0', emergencyExSw:'N', expInternalOrder: null, expCostCenter: null,  outsideWorkHours: 'N' });
        setError(false);
        setAddresses([]);
        setSelectedIndex(null);
        setMedicaid('N');
        setMedicaidSw(false);
        setCurrentMedicaid({ medFirstname: null, medLastname: null, medDob: null, medId: null, index: null, internalOrder: null, costCenter: null, notes: null, ledgerCode: null, medicaidDocuments: [] });
        setDocuments([]);
        //setMedicaidDocuments([]);
        setHistoryData([]);
        setReturnTripBtnDisabled({0:false});
        getStateHolidays();
        history.push("/tr1/mileageexp");
        
    }
    const mileageColumns = [
        {
            name: "Expense Date",
            field: "sumexpenseDate",
            type: "text"
        },
        {
            name: "Medicaid",
            field: "medicaid",
            type: "text"
        },
        {
            name: "Amount",
            field: "sumamount",
            type: "text"
        },
        {
            name: "Status",
            field: "sumstatus",
            type: "text"
        },
        {
            name: "Action",
            field: "action",
            type: "input",
            renderView: summaryActionButton
        }
    ]
    const addressColumns = [
        {
            name: "From Address",
            field: "fromAddress",
            type: "input",
            renderView: fromAddress
        },
        {
            name: "To Address",
            field: "toAddress",
            type: "input",
            renderView: toAddress
        },
        {
            name: "Mileage",
            field: "miles",
            type: "text"
        },
        {
            name: "Medicaid?",
            field: "medSw",
            type: "input",
            renderView: Medicaid
        },
        {
            name: "Medicaid verified?",
            field: "medStatus",
            type: "text"
        },
        {
            name: "Action",
            field: "action",
            type: "input",
            renderView: actionButton
        }
    ]

    const uploadColumns = [
        {
            name: "File Name",
            field: "fileName",
            type: "text"
        },
        {
            name: "Size",
            field: "size",
            type: "text"
        },
        {
            name: "Medicaid?",
            field: "medSw",
            type: "input",
            renderView: documentMedicaid
        },
        {
            name: "Action",
            field: "action",
            type: "input",
            renderView: documentActionButton
        }
    ];

    const medicaidUploadColumns = [
        {
            name: "File Name",
            field: "fileName",
            type: "text"
        },
        {
            name: "Size",
            field: "size",
            type: "text"
        },
        {
            name: "Medicaid?",
            field: "medSw",
            type: "input",
            renderView: medicaidDocumentMedicaidButton
        },
        {
            name: "Action",
            field: "action",
            type: "input",
            renderView: medicaidDocumentActionButton
        }
    ];


    const historyColumns = [
        {
            name: "Reservation Start Date",
            field: "reservationStartDate",
            type: "text"
        },
        {
            name: "Reservation End Date",
            field: "reservationEndDate",
            type: "text"
        },
        {
            name: "Division",
            field: "division",
            type: "text"
        },

        {
            name: "Official Station",
            field: "station",
            type: "text"
        },
        {
            name: "Vehicle Type",
            field: "type",
            type: "text"
        },
        {
            name: "Capacity",
            field: "capacity",
            type: "text"
        },
        {
            name: "VIN",
            field: "vin",
            type: "text"
        },
        {
            name: "Make",
            field: "make",
            type: "text"
        },
        {
            name: "Model",
            field: "model",
            type: "text"
        }
    ];

    
    return (
        <div>
            <Switch>
                <Route exact path="/tr1">
                    <div className={classes.root}>
                        <Typography className={classes.header}>Mileage Expense Summary</Typography>

                        <Box style={{ float: 'right' }} m={2}>
                            <Button variant="outlined" color="primary" size="small" className={(readOnly || (props.pageInfo.find((page) => page.pageId === 2).permissionSw !== 'Y')) ? "disabled" : null}
                                disabled={(readOnly || (props.pageInfo.find((page) => page.pageId === 2).permissionSw !== 'Y'))} onClick={() => addNewMileage()}>Add New</Button>
                        </Box>
                        <Paper elevation={0}>
                            <Box p={"10px"} pb={"60px"}>
                                <Gridtable columns={mileageColumns} data={summaryData}></Gridtable>
                                <div className="summaryTotal">
                                    <span>Total Mileage: {totalMileage} &emsp;</span>
                                    <span>Total Amount: ${totalAmount}</span>
                                </div>
                            </Box>
                        </Paper>
                    </div>
                </Route>
                <Route exact path="/tr1/mileageexp">
                    <div>
                        <Typography className={classes.header}>Mileage Expense Details</Typography>

                        <Grid container className={classes.filters}>
                            <Grid item xs={10} sm={5} className={classes.datepicker}>
                                <span className="datepickerLabel">Expense Date</span>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                    <KeyboardDatePicker
                                        //disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="expense-date"
                                        autoOk={true}
                                        className={readOnly ? "disabled" : "required"}
                                        disabled={readOnly}
                                        size="small"
                                        fullWidth
                                        value={mileageDetails.expenseDate}
                                        error={(mileageDetails.expenseDate === null) || (!moment(mileageDetails.expenseDate, 'MM/DD/YYYY').isValid())}
                                        minDate={props.reimbursement.expenseStartDate !== null ? props.reimbursement.expenseStartDate : null}
                                        maxDate={props.reimbursement.expenseEndDate !== null ? props.reimbursement.expenseEndDate : new Date()}
                                        onChange={(date) => changeMileageDetails('expenseDate', date)}
                                        placeholder="MM/DD/YYYY"
                                        KeyboardButtonProps={{
                                            'aria-label': 'expense date',
                                        }}
                                        inputVariant="outlined"
                                        helperText={(mileageDetails.expenseDate === null && error) ? "Required Field" : null}
                                    />
                                </MuiPickersUtilsProvider>
                                {/* Warning Message Display */}
                               {showWarning && warningMessage && (
                                <FormHelperText style={{ color: 'red', marginTop: '10px' }}>
                                    {warningMessage}
                                </FormHelperText>)}
                            </Grid>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <FormControl variant="outlined" size="small" className={classes.formControl} error={mileageDetails.expReason === 0 || mileageDetails.expReason === null}>
                                    <span className={classes.textLabel}>Reason for Expense</span>
                                    <Select
                                        id="reason-select"
                                        value={mileageDetails.expReason === null ? 0 : mileageDetails.expReason}
                                        className={readOnly ? "disabled" : "required"}
                                        disabled={readOnly}
                                        onChange={(e) => changeMileageDetails('expReason', e.target.value)}
                                    >
                                        <MenuItem value={0}>Pick an Option</MenuItem>
                                        {props.staticData.dropdown.ReasonForExpense.value.map((method) => (
                                            <MenuItem key={method.value} value={method.value}>{method.name}</MenuItem>
                                        ))}
                                    </Select>
                                    {((mileageDetails.expReason === 0 || mileageDetails.expReason === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                                        : null}
                                </FormControl>
                            </Grid>                         
                        </Grid>
                        {mileageDetails.totalMileage >= 500 ? <Grid container className={classes.filters}>
                            <Grid item xs={10} className={classes.textfield}>
                                <span className={classes.textLabel}>Mileage 500 Justification</span>
                                <TextField
                                    id="milJust"
                                    multiline
                                    error={mileageDetails.justification === '' || mileageDetails.justification === null}
                                    value={mileageDetails.justification === null ? '' : mileageDetails.justification}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    onChange={(e) => changeMileageDetails('justification', e.target.value)}
                                    className={readOnly ? "disabled" : null}
                                    disabled={readOnly}
                                    inputProps={{
                                        maxLength: 4000
                                    }}
                                    helperText={((mileageDetails.justification === '' || mileageDetails.justification === null) && error) ? "Required Field" : null}
                                />
                                <FormHelperText>{mileageDetails.justification === null ? 0 : mileageDetails.justification.length}/4000 Characters</FormHelperText>
                            </Grid>
                        </Grid> : null}  
                        <Subheader>For Agency Payment Purposes Only</Subheader>                      
                        <Grid container className={classes.filters}>                            
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <span className={classes.textLabel}>General Ledger Code </span>
                                <TextField
                                    id="ledgerCode"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={(mileageDetails.ledgerCode === null || mileageDetails.ledgerCode === 0) ? '' : mileageDetails.ledgerCode}
                                />
                            </Grid>                            
                        </Grid>
                        <Grid container className={classes.filters}>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                    <span className={classes.textLabel}>Internal Order</span>
                                    <TextField
                                    variant="outlined"
                                    size="small"
                                    id="expinternalOrder"
                                    fullWidth
                                    disabled
                                    className="disabled"                                    
                                    value={mileageDetails.expInternalOrder === null ? '' : mileageDetails.expInternalOrder}                                    
                                    />
                            </Grid>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <span className={classes.textLabel}>Cost Center</span>
                                    <TextField
                                    variant="outlined"
                                    size="small"
                                    id="expcostCenter"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={mileageDetails.expCostCenter === null ? '' : mileageDetails.expCostCenter}                                    
                                    />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.filters}>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <span className={classes.textLabel}>Rate($/mile) </span>
                                <TextField
                                    id="rate"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={"$" + (fetchMileageRate(mileageDetails.expenseDate))}
                                />
                            </Grid>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <span className={classes.textLabel}>Total Mileage </span>
                                <TextField
                                    id="normalMile"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    className="disabled"
                                    value={mileageDetails.totalMileage === null ? 0 : mileageDetails.totalMileage}
                                />
                            </Grid>                            
                        </Grid>
                        <Grid container className={classes.filters}>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <span className={classes.textLabel}>Amount </span>
                                <TextField
                                    id="amnt"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    className="disabled"
                                    value={"$" + (mileageDetails.amount === null ? 0 : mileageDetails.amount)}
                                />
                            </Grid>
                        </Grid>
                        <div className={classes.root}>
                            <Typography className={classes.header}>Mileage Expense Summary</Typography>
                            {props.userInfo.role !== 'PR' ? <Subheader>Vehicle Search History</Subheader> : null}
                            {props.userInfo.role !== 'PR' ? (historyData.length) > 0 ?
                                <div>
                                    <Box p={"10px"}>
                                        <Gridtable columns={historyColumns} data={historyData}></Gridtable>
                                    </Box>
                                </div> :
                                <div style={{ margin: "10px", textAlign: "center" }}>
                                   {!hideText && <span className={classes.textLabel} style={{ color: "red" }}>{Config.VehSrchStaticText}</span>}
                                </div> : null}
                            { props.userInfo.role !== 'PR' && historyData.length == 0 && !hideText && <Grid container className={classes.filters}>
                                <Grid item xs={8} className={classes.textfield}>
                                    <FormControl component="fieldset">
                                        <RadioGroup row aria-label="emergency-ex-sw" name="emergency-ex-sw" value={mileageDetails.emergencyExSw}
                                         onChange={(e) => changeMileageDetails("emergencyExSw", e.target.value)}>
                                            <FormLabel component="legend" className={classes.radioLabel}>Do you have a special emergency exception to claim a mileage expense ?</FormLabel>
                                            <div>
                                                <FormControlLabel disabled={readOnly} value="Y" control={<Radio color="primary" />} label="Yes" />
                                                <FormControlLabel disabled={readOnly} value="N" control={<Radio color="primary" />} label="No" />
                                            </div>
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                               
                                <Grid item xs={4} className={classes.selectGrid}>
                                {emgExpReason ===  true ?  <FormControl variant="outlined" size="small" className={classes.formControl} 
                                
                                error={(mileageDetails.emergencyExReason === '0' || mileageDetails.emergencyExReason === null) && (historyData.length) == 0 && mileageDetails.emergencyExSw === 'Y'}>
                                   <span className={classes.textLabel}>Reason for Claiming Special Emergency  Expense</span>
                                   <Select
                                       id="emergency-reason-select"
                                       value={mileageDetails.emergencyExReason}
                                       className={readOnly ? "disabled" : "required"}
                                       disabled={readOnly}
                                       onChange={(e) => changeMileageDetails("emergencyExReason", e.target.value)}
                                   >
                                      <MenuItem value={0}>Pick an Option</MenuItem>
                                       {props.staticData.dropdown.SpecialEmergencyException.value.map((method) => (
                                           <MenuItem key={method.value} value={method.value}>{method.name}</MenuItem>
                                       ))}
                                   </Select>
                                   {((mileageDetails.emergencyExReason === '0' || mileageDetails.emergencyExReason === null) && (historyData.length) == 0 && mileageDetails.emergencyExSw === 'Y' && error) ? <FormHelperText>Required Field</FormHelperText>
                                       : null}
                                       
                               </FormControl>: null}
                                
                                </Grid>
                            </Grid>
                            }
                        </div>
                        <Grid container className={classes.filters}>
                            <Grid item xs={12}>
                                <Box style={{ float: 'right', margin: "20px 10px 10px 20px" }}>
                                    <Button variant="outlined" color="primary" size="small" className={historyData.length == 0 && (mileageDetails.emergencyExReason === '0' || mileageDetails.emergencyExSw === 'N') ? "disabled" : null}
                                        disabled={(props.userInfo.role !== 'PR' && historyData.length == 0 && (mileageDetails.emergencyExReason === '0' || mileageDetails.emergencyExSw === 'N')) || readOnly} onClick={() => addNewAddress()}>Add Trip</Button>
                                </Box>
                                <Box pr={"10px"}>
                                <Gridtable columns={addressColumns} data={[...addresses].filter((addr) => addr.delSw !== 'Y')}></Gridtable>
                                </Box>
                            </Grid>
                        </Grid>
                        {medicaid === 'Y' ? <div>
                            <Subheader>Medicaid Details</Subheader>
                            <Grid container className={classes.filters}>
                                <Grid item xs={10} sm={5} className={classes.textfield}>
                                    <span className={classes.textLabel}>First Name</span> 
                                    <TextField
                                        id="firstName"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        className={readOnly ? "disabled" : "required"}
                                        disabled={readOnly}
                                        value={currentMedicaid.medFirstname === null ? '' : currentMedicaid.medFirstname}
                                        error={currentMedicaid.medFirstname === '' || currentMedicaid.medFirstname === null}
                                        onChange={(e) => changeMedicaidDetails("medFirstname", e.target.value)}
                                        helperText={((currentMedicaid.medFirstname === '' || currentMedicaid.medFirstname === null) && medError) ? "Required Field" : null}
                                    />
                                </Grid>
                                <Grid item xs={10} sm={5} className={classes.textfield}>
                                    <span className={classes.textLabel}>Last Name</span>
                                    <TextField
                                        id="lastName"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        className={readOnly ? "disabled" : "required"}
                                        disabled={readOnly}
                                        value={currentMedicaid.medLastname === null ? '' : currentMedicaid.medLastname}
                                        error={currentMedicaid.medLastname === '' || currentMedicaid.medLastname === null}
                                        onChange={(e) => changeMedicaidDetails("medLastname", e.target.value)}
                                        helperText={((currentMedicaid.medLastname === '' || currentMedicaid.medLastname === null) && medError) ? "Required Field" : null}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className={classes.filters}>
                                <Grid item xs={10} sm={5} className={classes.datepicker}>
                                    <span className="datepickerLabel">Date of Birth</span>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                        <KeyboardDatePicker
                                            //disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="dob-date"
                                            autoOk={true}
                                            className={readOnly ? "disabled" : "required"}
                                            disabled={readOnly}
                                            size="small"
                                            fullWidth
                                            value={currentMedicaid.medDob}
                                            error={(currentMedicaid.medDob === null) || (!moment(currentMedicaid.medDob, 'MM/DD/YYYY').isValid())}
                                            onChange={(date) => changeMedicaidDetails('medDob', date)}
                                            maxDate={new Date()}
                                            placeholder="MM/DD/YYYY"
                                            KeyboardButtonProps={{
                                                'aria-label': 'dob date',
                                            }}
                                            inputVariant="outlined"
                                            helperText={((currentMedicaid.medDob === null && medError)) ? "Required Field" : null}
                                        />
                                    </MuiPickersUtilsProvider>
                                    {(currentMedicaid.medDob !== null && !moment(currentMedicaid.medDob, 'MM/DD/YYYY').isValid()) ? <FormHelperText error>Invalid Date Format</FormHelperText>
                                        : null}
                                </Grid>
                                <Grid item xs={10} sm={5} className={classes.textfield}>
                                    <span className={classes.textLabel}>Medicaid Number</span>
                                    <TextField
                                        id="medNum"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        className={readOnly ? "disabled" : "required"}
                                        disabled={readOnly}
                                        value={currentMedicaid.medId === null ? '' : currentMedicaid.medId}
                                        error={currentMedicaid.medId === '' || currentMedicaid.medId === null}
                                        InputProps={{
                                            inputComponent: NumberFormatCustom,
                                        }}
                                        onChange={(e) => changeMedicaidDetails("medId", e.target.value)}
                                        helperText={((currentMedicaid.medId === '' || currentMedicaid.medId === null) && medError) ? "Required Field" : null}
                                    />
                                </Grid>
                            </Grid>
                            <Subheader>For Agency Payment Purposes Only</Subheader>
                            <Grid container className={classes.filters}>
                                <Grid item xs={10} sm={5} className={classes.textfield}>
                                    <span className={classes.textLabel}>General Ledger Code</span>
                                    <TextField
                                        id="tripLedgerCode"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        className="disabled"
                                        //value={(new Date(mileageDetails.expenseDate).getMonth() < 9 && new Date(mileageDetails.expenseDate).getFullYear() < new Date().getFullYear()) ? props.staticData.dropdown["MedicaidDetails" + (new Date().getFullYear() - 1)].value.find((config) => config.value === 'CO').name : props.staticData.dropdown.MedicaidDetails.value.find((config) => config.value === 'CO').name}
                                        //value = {currentMedicaid.ledgerCode}
                                        value={currentMedicaid.ledgerCode === null ? '' : currentMedicaid.ledgerCode}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className={classes.filters}>
                                <Grid item xs={10} sm={5} className={classes.textfield}>
                                    <span className={classes.textLabel}>Internal Order</span>
                                    <TextField
                                        id="internalOrder"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        className="disabled"
                                        //value={(new Date(mileageDetails.expenseDate).getMonth() < 9 && new Date(mileageDetails.expenseDate).getFullYear() < new Date().getFullYear()) ? props.staticData.dropdown["MedicaidDetails" + (new Date().getFullYear() - 1)].value.find((config) => config.value === 'IO').name : props.staticData.dropdown.MedicaidDetails.value.find((config) => config.value === 'IO').name}
                                        //value={currentMedicaid.internalOrder}
                                        value={currentMedicaid.internalOrder === null ? '' : currentMedicaid.internalOrder}
                                    />
                                </Grid>
                                <Grid item xs={10} sm={5} className={classes.textfield}>
                                    <span className={classes.textLabel}>Cost Center</span>
                                    <TextField
                                        id="costCenter"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        className="disabled"
                                        //value={(new Date(mileageDetails.expenseDate).getMonth() < 9 && new Date(mileageDetails.expenseDate).getFullYear() < new Date().getFullYear()) ? props.staticData.dropdown["MedicaidDetails" + (new Date().getFullYear() - 1)].value.find((config) => config.value === 'CO').name : props.staticData.dropdown.MedicaidDetails.value.find((config) => config.value === 'CO').name}
                                        //value = {currentMedicaid.costCenter}
                                        value={currentMedicaid.costCenter === null ? '' : currentMedicaid.costCenter}
                                    />
                                </Grid>
    
                                <Grid item xs={12}>
                            <Subheader>Upload Medicaid Documents</Subheader>
                            </Grid>

                            <Grid container style={{ padding: "0 10px" }}>
                                <Grid item xs={12}>
                                    <Box style={{ float: 'right', margin: "20px 10px 10px 20px" }}>
                                        <Button 
                                            variant="outlined" 
                                            color="primary" 
                                            size="small" 
                                            className={readOnly ? "disabled" : null}
                                            disabled={readOnly}
                                        >
                                            <span style={{ position: 'absolute', maxWidth: '210px' }}>Select File & Upload</span>
                                            <input
                                                color="primary"
                                                type="file"
                                                onChange={(event) => upload(event.target, "Medicaid", currentMedicaid.index)}
                                                id="upload-medicaid-file"  /* Unique ID for Medicaid Upload */
                                                style={{ opacity: 0, maxWidth: '210px', cursor: 'pointer' }}
                                            />
                                        </Button>
                                    </Box>
                                    <Box pr={"10px"}>
                                        <Gridtable 
                                columns={medicaidUploadColumns} 
                                data={currentMedicaid.medicaidDocuments.filter((doc) => doc.delSw !== 'Y')} 
                            />
                                    </Box>
                                </Grid>
                            </Grid> 
                            </Grid>
                    
                            <div style={{ textAlign: 'center', paddingBottom: 10 }}>
                                <Button style={{ margin: "20px 5px 5px 20px" }} variant="contained" color="primary" size="small" className={readOnly ? "disabled" : null} 
                              disabled={readOnly || [...currentMedicaid.medicaidDocuments].filter((doc) => doc.delSw !== 'Y').length === 0}
                              onClick={() => saveTrip(currentMedicaid.index)}>Verify</Button>
                            </div>
                        </div> : null}
                        {selectedIndex !== null ? <Grid container className={classes.filters}>
                            <Grid item xs={10} className={classes.textfield}>
                                <span className={classes.textLabel}>Notes</span>
                                <TextField
                                    id="notes"
                                    disabled={readOnly}
                                    className={readOnly ? "disabled" : null}
                                    multiline
                                    value={addresses[selectedIndex].notes === null ? '' : addresses[selectedIndex].notes}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    size="small"
                                    fullWidth
                                    onChange={(e) => changeTripDetails(selectedIndex, 'notes', e.target.value)}
                                    inputProps={{
                                        maxLength: 400
                                    }}
                                />
                                <FormHelperText>{addresses[selectedIndex].notes === null ? 0 : addresses[selectedIndex].notes.length}/400 Characters</FormHelperText>
                            </Grid>
                        </Grid> : null}
                        <Subheader>Upload Documents</Subheader>
                        <Grid container style={{ padding: "0 10px" }}>
                            <Grid item xs={12}>
                                <Box style={{ float: 'right', margin: "20px 10px 10px 20px" }}>
                                    <Button variant="outlined" color="primary" size="small" className={readOnly ? "disabled" : null}
                                        disabled={readOnly}>
                                        <span style={{ position: 'absolute', maxWidth: '210px' }}>Select FIle & Upload</span>
                                        <input
                                            color="primary"
                                            type="file"
                                            onChange={(event) => upload(event.target, "NonMedicaid")}
                                            id="upload-file"
                                            style={{ opacity: 0,  maxWidth: '210px',cursor: 'pointer' }}
                                        />
                                </Button>
                            </Box>
                            <Box pr={"10px"}>
                                <Gridtable columns={uploadColumns} data={documents.filter((doc)=> doc.delSw !== 'Y')}></Gridtable>
                            </Box>
                        </Grid>
                    </Grid>
                {mileageDetails.dhsCardSw === 'Y' ? <div>
                      <Grid container className={classes.filters}>
                          <Grid item xs={12} sm={6} className={classes.textfield}>
                              <span className={classes.textLabel}>Amount</span>
                                  <TextField
                                      id="stateCardAmnt"
                                      variant="outlined"
                                      size="small"
                                      fullWidth
                                      className = {readOnly ? "disabled" : "required"}
                                      disabled = {readOnly}
                                      value={mileageDetails.dhsCardAmount === null ? '' : parseFloat(mileageDetails.dhsCardAmount).toFixed(2)}
                                      error ={mileageDetails.dhsCardAmount === '' || mileageDetails.dhsCardAmount === null}
                                      onChange={(e)=>changeMileageDetails('dhsCardAmount',parseFloat(e.target.value).toFixed(2))}
                                      onBlur = {()=> changeReimAmount()}
                                      InputProps={{
                                        inputComponent: AmountFormatCustom,
                                      }}
                                      helperText={((mileageDetails.dhsCardAmount === '' || mileageDetails.dhsCardAmount === null) && error) ? "Required Field" : null}
                                  />
                          </Grid>
                          </Grid>
                    </div> : null}
                    {((mileageDetails.status !== 'IP') && (props.reimbursement.assignedTo !== null) && (props.reimbursement.assignedTo === props.userInfo.id)) ? <div>
                    <Subheader>Travel Supervisor Status Details</Subheader>
                    <Grid container className={classes.filters}>
                        <Grid item xs={10} sm={3} className={classes.textfield}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={mileageDetails.nonMedStatus === 'AP'}
                                        onChange={()=> changeMileageDetails('nonMedStatus','AP')}
                                        name="Approve"
                                        color="primary"
                                        className = {((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : null}
                                        disabled = {((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id) || props.staticData.dropdown.MedicaidApprovers.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0) || props.staticData.dropdown.PaymentApprover.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0}
                                    />
                                }
                                label="Approve"
                                labelPlacement="end"
                            /> 
                        </Grid>
                        <Grid item xs={10} sm={3} className={classes.textfield}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={mileageDetails.nonMedStatus === 'RR'}
                                        onChange={()=> changeMileageDetails('nonMedStatus','RR')}
                                        name="Return for Revision"
                                        color="primary"
                                        className = {((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : null}
                                        disabled = {((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id) || props.staticData.dropdown.MedicaidApprovers.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0) || props.staticData.dropdown.PaymentApprover.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0}
                                    />
                                }
                                label="Return for Revision"
                                labelPlacement="end"
                            />
                        </Grid>
                    </Grid>
                    {mileageDetails.nonMedStatus === 'RR' ? <Grid container className={classes.filters}>
                    <Grid item xs={10} sm={5} className={classes.textfield}>
                        <FormControl variant="outlined" size="small" className={classes.formControl} error={mileageDetails.nonMedStatusRevReason === 0 || mileageDetails.nonMedStatusRevReason === null}>
                            <span className={classes.textLabel}>Reason for Revision</span>
                            <Select
                                id="reason-select"
                                value={mileageDetails.nonMedStatusRevReason === null ? 0 : mileageDetails.nonMedStatusRevReason}
                                className = {((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : "required"}
                                disabled = {((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id))}
                                onChange={(e)=> changeMileageDetails('nonMedStatusRevReason',e.target.value)}
                            >
                                <MenuItem value={0}>Pick an Option</MenuItem>
                                {props.staticData.dropdown.ReasonForRevision.value.map((method)=>(
                                    <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                                ))}
                            </Select>
                            {((mileageDetails.nonMedStatusRevReason === 0 || mileageDetails.nonMedStatusRevReason === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                            : null}                                               
                        </FormControl>                      
                    </Grid> 
                    </Grid> : null}
                </div> : null}
                { ( mileageDetails.medStatus === 'XX'  && medicaidSw &&  props.reimbursement.medSupervisor === null ) ? 
               <div>
                    <Subheader>Medicaid Status Details</Subheader>
                <Grid container className={classes.filters}>
                <Grid item xs={10} sm={3} className={classes.textfield}>
                    <FormControlLabel
                        control={
                            <Checkbox
                            checked={mileageDetails.medStatusRevReason === null }
                            name="Approve"
                            color="primary"
                            className ="disabled"
                            disabled
                            />
                        }
                        label="Approve"
                        labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={10} sm={3} className={classes.textfield}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={mileageDetails.medStatusRevReason !== null}
                                name="Return for Revision"
                                color="primary"
                                className = "disabled"
                                disabled 
                            />
                        }
                        label="Return for Revision"
                        labelPlacement="end"
                    />
                </Grid>
            </Grid>
            
            <Grid container className={classes.filters}>
                    <Grid item xs={10} sm={5} className={classes.textfield}>
                        <FormControl variant="outlined" size="small" className={classes.formControl} error={mileageDetails.medStatusRevReason === 0 || mileageDetails.medStatusRevReason === null}>
                            <span className={classes.textLabel}>Reason for Revision</span>
                            <Select
                                id="reason-select"
                                value={mileageDetails.medStatusRevReason === null ? 0 : mileageDetails.medStatusRevReason}
                                className =  "disabled" 
                                disabled
                            >
                                <MenuItem value={0}>Pick an Option</MenuItem>
                                {props.staticData.dropdown.ReasonForRevision.value.map((method)=>(
                                    <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                                ))}
                            </Select>
                            {((mileageDetails.medStatusRevReason === 0 || mileageDetails.medStatusRevReason === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                            : null}                                               
                        </FormControl>                      
                    </Grid> 
                    </Grid> 
                     </div>
                : null }


                    {((mileageDetails.status !== 'IP') && (props.reimbursement.assignedTo !== null) && (props.reimbursement.medSupervisor !== null) && ( (props.reimbursement.medSupervisor.toString() === props.userInfo.id.toString()) && medicaidSw) || (props.staticData.dropdown.PaymentApprover.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0 && medicaidSw) ) ? <div>
                    <Subheader>Medicaid Status Details</Subheader>
                    <Grid container className={classes.filters}>
                        <Grid item xs={10} sm={3} className={classes.textfield}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={mileageDetails.medStatus === 'AP'}
                                    onChange={()=> changeMileageDetails('medStatus','AP')}
                                    name="Approve"
                                    color="primary"
                                    className = {((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : null}
                                    disabled = {((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id) || props.staticData.dropdown.PaymentApprover.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0)}
                                    />
                                }
                                label="Approve"
                                labelPlacement="end"
                            />
                        </Grid>
                        <Grid item xs={10} sm={3} className={classes.textfield}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={mileageDetails.medStatus === 'RR' || mileageDetails.medStatus === 'XX' }
                                        onChange={()=> changeMileageDetails('medStatus','RR')}
                                        name="Return for Revision"
                                        color="primary"
                                        className = {((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : null}
                                        disabled = {((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id) || props.staticData.dropdown.PaymentApprover.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0)}
                                    />
                                }
                                label="Return for Revision"
                                labelPlacement="end"
                            />
                        </Grid>
                    </Grid>
                    {mileageDetails.medStatus === 'RR' || mileageDetails.medStatus === 'XX' ? <Grid container className={classes.filters}>
                    <Grid item xs={10} sm={5} className={classes.textfield}>
                        <FormControl variant="outlined" size="small" className={classes.formControl} error={mileageDetails.medStatusRevReason === 0 || mileageDetails.medStatusRevReason === null}>
                            <span className={classes.textLabel}>Reason for Revision</span>
                            <Select
                                id="reason-select"
                                value={mileageDetails.medStatusRevReason === null ? 0 : mileageDetails.medStatusRevReason}
                                className = {((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : "required"}
                                disabled = {((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id))}
                                onChange={(e)=> changeMileageDetails('medStatusRevReason',e.target.value)}
                            >
                                <MenuItem value={0}>Pick an Option</MenuItem>
                                {props.staticData.dropdown.ReasonForRevision.value.map((method)=>(
                                    <MenuItem  key={method.value} value={method.value}>{method.name}</MenuItem>
                                ))}
                            </Select>
                            {((mileageDetails.medStatusRevReason === 0 || mileageDetails.medStatusRevReason === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                            : null}                                               
                        </FormControl>                      
                    </Grid> 
                    </Grid> : null}
                </div> : null}
                <div style={{textAlign:'center',paddingBottom:10}}>
                    <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small" disabled = {["SB","AP","PO","PP"].indexOf(props.reimbursement.status)  > -1 && (props.reimbursement.userId === props.userInfo.id)} onClick={()=>reset()}>Reset</Button>
                    <Button style={{margin:"20px 5px 5px 20px"}} variant="contained" color="primary" size="small" disabled = {["SB","AP","PO","PP"].indexOf(props.reimbursement.status)  > -1 && (props.reimbursement.userId === props.userInfo.id)} onClick={()=> saveMileage()}>Save</Button>
                </div>
                </div>
            </Route>
        </Switch>
    </div>
)

}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
//import Checkbox from '@material-ui/core/Checkbox';
import '../App.css';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
//import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
/*import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';*/
import Config from '../assets/config';
import Cities from '../assets/cities';
import Checkbox from '@material-ui/core/Checkbox';
import { postFileDownload, postFetch } from '../http';
import Paper from '@material-ui/core/Paper';
import Gridtable from '../gridtable';
//import QueryBuilderRoundedIcon from '@material-ui/icons/QueryBuilderRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import Box from '@material-ui/core/Box';
import { Switch, Route } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { useHistory } from "react-router-dom";
import moment from "moment";
import NumberFormat from 'react-number-format';
import _ from 'lodash';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#fff',
        borderRadius: '5px',
        margin: '30px 10px',
        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 0px 0px rgb(0 0 0 / 12%)',
        "& .MuiFormControlLabel-labelPlacementStart": {
            marginLeft: 1
        }
    },
    header: {
        backgroundColor: "#1a75c9",
        color: "#fff",
        marginBottom: 10,
        padding: 10,
        fontWeight: 900,
        fontSize: 15
    },
    filters: {
        "& .MuiFormLabel-root.Mui-focused, .MuiFormLabel-root": {
            color: "#000",
            fontSize: 17
        },
        margin: '20px 0',
        padding: '0 10px'
    },
    textfield: {
        padding: '10px 10px 10px 0',
        position: 'relative'
    },
    textLabel: {
        position: 'relative',
        bottom: '8px',
        fontSize: '14px',
        fontWeight: '500'
    },
    datepicker: {
        position: 'relative',
        bottom: 3,
        padding: '0 10px 0 0'
    },
    formControl: {
        width: "100%",
        marginTop: 1
    },
    addressField: {
        width: 400,
        marginTop: 1
    },
    fabIcon: {
        padding: '10px',
        marginTop: '34px'
    },
    radioLabel: {
        position: 'relative',
        top: 13,
        marginRight: 20
    }
}));

const Subheader = ({ children }) => {
    return (
        <div className="wrapper">
            <p>{children}</p>
            <div className="line"></div>
        </div>
    )
}

function AmountFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            allowNegative={false}
            prefix="$"
        />
    );
}

export default function MileageExp(props) {
    const classes = useStyles();
    const history = useHistory();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [summaryData, setSummaryData] = React.useState([]);
    const [totalAmount, setTotalAmount] = React.useState(0);
    const [onInit, setOnInit] = React.useState(true);
    const [incidentalDetails, setIncidentalDetails] = React.useState({ expReason: null, expenseDate: null, state: null, city: null, ledgerCode: null, incidentalType: null, amount: null, reimbAmount: 0, dhsCardSw: 'N', dhsCardAmount: 0, revReason: null, rembId: null, status: 'IP', delSw: 'N', id: null, notes: null, internalOrder: null, costCenter: null,outOfState: 'N',  outsideWorkHours: 'N'});
    const [error, setError] = React.useState(false);
    const [documents, setDocuments] = React.useState([]);
    const [readOnly, setReadOnly] = React.useState(false);
    const [states, setStates] = React.useState([]);
    const [cities, setCities] = React.useState([]);
    //const [isRREdited,setIsRREdited] = React.useState(false);
    const [oldIncidentalDetails, setOldIncidentalDetails] = React.useState([]);
    const [defaultReimbAmt, setDefaultReimbAmt] = React.useState(0);
    const [defaultDhsCardAmount, setDefaultDhsCardAmount] = React.useState(0);
    const [stateHoliday, setStateHoliday] = React.useState([]);
    const [showWarning, setShowWarning] = React.useState(false);  // New state for controlling visibility
    const [warningMessage, setWarningMessage] = React.useState(''); // Stores the warning message
    const changeDocumentDetails = (index, key, value) => {
        let updatedDocs = [...documents].filter((doc) => doc.delSw !== 'Y');
        updatedDocs[index][key] = value;
        setDocuments(updatedDocs);
    }

    const documentActionButton = (row, column, index) => {
        return (<div style={{ cursor: 'pointer' }}><VisibilityRoundedIcon onClick={() => viewDocument(row, index)} style={{ fontSize: 20 }} /><DeleteIcon onClick={() => readOnly ? null : changeDocumentDetails(index, 'delSw', 'Y')} style={{ fontSize: 20 }} /></div>);
    }


    React.useEffect(() => {
        const loadIncidentalDetails = async () => {
            props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
            setOnInit(false);
            history.push("/tr1");
            const request = {
                rembId: parseInt(localStorage.getItem("requestId"))
            }
            await postFetch("/incidental/getByRembId", request).then((data) => {
                if (!data.message) {
                    let summary = [];
                    let totalAmount = 0;
                    let existingDates = localStorage.getItem("dates").split(",");
                    existingDates = existingDates.filter((date) => date.indexOf("ic") === -1);
                    data.forEach(exp => {
                        exp.sumexpenseDate = convertDateFromServer(exp.expenseDate);
                        existingDates = existingDates + "," + exp.expenseDate + "ic";
                        exp.sumexpenseReason = props.staticData.reference.ReasonForExpense.value.find((config) => config.value === exp.expReason).name;
                        exp.sumamount = "$" + exp.reimbAmount;
                        exp.sumstatus = props.staticData.reference.ReimbursementStatus.value.find((config) => config.value === exp.status).name;
                        if (exp.delSw !== 'Y') {
                            summary.push(exp);
                            totalAmount = parseFloat(totalAmount) + parseFloat(exp.reimbAmount);
                        }
                    });
                    localStorage.setItem("dates", existingDates);
                    setSummaryData(summary);
                    setTotalAmount(parseFloat(totalAmount).toFixed(2));
                    setTimeout(() => {
                        props.loader({ loader: false, notification: false });
                    }, 1000);
                    if (localStorage.getItem("readOnly") !== null) {
                        setReadOnly(true);
                    }
                }
                else {
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                    }, 1000);
                }
            }).catch((e) => {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
                }, 1000);
            });
        }
        if (onInit && (localStorage.getItem("requestId") !== undefined) && (localStorage.getItem("requestId") !== null)) {
            loadIncidentalDetails();
            getStateHolidays();
        }
    }, [props, onInit, summaryData, history]);

    function convertDateFromServer(value) {
        if ((value !== null) && !(value instanceof Date)) {
            let serverDateArray = value.split("-");
            if (serverDateArray.length > 1) {
                return serverDateArray[1] + "/" + serverDateArray[2] + "/" + serverDateArray[0];
            }
            else {
                return serverDateArray[0];
            }
        }
        else if (value instanceof Date) {
            return value;
        }
        else {
            return null;
        }

    }

    const checkIncidentalExpenseDate = async (expenseDate) => {
        if (!expenseDate) {
            // Skip the check if no date is provided
            return;
        }
        let formattedExpenseDate = moment(expenseDate).format('YYYY-MM-DD');
        let details = { expenseDate: formattedExpenseDate, userId: props.userInfo.id };
    
        try {
            const data = await postFetch("/expense/checkIncidentaExpenseDate", details);    
            if (data.exists) {
                const reimbursementData = data.reimbursementData || [];
    
                // Filter to only include 'incidental' expense types
                const incidentalExpenses = reimbursementData.filter(item => item.expenseType === 'incidental');
    
                if (incidentalExpenses.length > 0) {
                    // Extract unique reimbursement IDs
                    const reimbursementIds = [...new Set(
                        incidentalExpenses.map(item => item.reimbursementId).filter(id => id !== undefined && id !== null)
                    )];
                                        
                    if (reimbursementIds.length > 0) {
                        const reimbursementIdsString = reimbursementIds.join(", ");
                        const message = `You already have an incidental expense for this date in Reimbursement ${reimbursementIdsString}. Please ensure this is not a duplicate expense.`;
    
                        
                        // Set the warning message and ensure it is shown
                        setWarningMessage(message);
                        setShowWarning(true);
                    }
                }
            } else {
                // Clear the warning if no expense exists
                setWarningMessage("");
                setShowWarning(false);
            }
        } catch (e) {
            // Handle system error and show warning
            setWarningMessage("System error. Please try again later.");
            setShowWarning(true);
        }
    }


    const saveIncidentals = async () => {
        if (document.getElementsByClassName("Mui-error").length > 0) {
            setError(true);
        }
        else if ([...documents].filter((doc) => doc.delSw !== 'Y').length === 0) {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "At least one document is required to submit expense." });
        }
        else if (props.reimbursement.expenseStartDate === null || props.reimbursement.expenseEndDate === null) {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "Total Reimbursement Expense Start Date or Expense End Date is missing" });
        }
        else if (incidentalDetails.status === 'SB' && props.reimbursement.status === 'SB') {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "Please Complete Status Details." });
        }
        else {
            if (localStorage.getItem("requestId") === null) {
                props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
                let request = { ...props.reimbursement };
                request.expenseStartDate = request.expenseStartDate === null ? null : new Date(request.expenseStartDate);
                request.expenseEndDate = request.expenseEndDate === null ? null : new Date(request.expenseEndDate);
                await postFetch("/reimbursement/save", request).then((data) => {
                    if (!data.message) {
                        setTimeout(() => {
                            localStorage.setItem("requestId", data.id);
                            localStorage.setItem("recordVersion", data.recordVersion);
                            saveExpense(data.id);
                        }, 1000);
                    }
                    else {
                        setTimeout(() => {
                            props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                        }, 1000);
                    }
                }).catch((e) => {
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
                    }, 1000);
                });
            }
            else {
                saveExpense(localStorage.getItem("requestId"));
            }
        }
    }

    const saveExpense = async (reimId) => {
        props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
        let request = { ...incidentalDetails };
        request.amount = request.amount !== null ? parseFloat(request.amount) : null;
        request.reimbAmount = request.reimbAmount !== null ? parseFloat(request.reimbAmount) : null;
        request.rembId = reimId;
        request.expenseDate = new Date(request.expenseDate);
        request.dhsCardAmount = request.dhsCardAmount !== null ? parseFloat(request.dhsCardAmount) : null;
        //request.ledgerCode = props.staticData.dropdown.GeneralLedgerCodes.value.find((config) => config.value === request.expReason).name;
        request.documents = [...documents];
        if (props.reimbursement.status === 'RR') {
            let oldIncidentalDtl = { ...oldIncidentalDetails };
            oldIncidentalDtl.expenseDate = new Date(oldIncidentalDtl.expenseDate);
            oldIncidentalDtl.rembId = reimId;
            if (!_.isEqual(oldIncidentalDtl, request)) {
                request.status = 'IP';
            }
        }
        if((props.travellerInfo.accountActive !== null && props.travellerInfo.accountActive === '0') && request.status === 'IP' && props.reimbursement.assignedTo === props.userInfo.id)
        {
            request.status = 'SB'
        }
        await postFetch("/incidental/save", request).then((data) => { 
            if (!data.message) {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "success", validationMessage: "Incidental Expense saved successfully." });
                }, 1500);
                setOnInit(true);
            }
            else {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                }, 1000);
            }
        }).catch((e) => {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            }, 1000);
        });
    }

    const upload = async (target) => {
        if (target.files.length === 0) {
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "No File Selected" });
        }
        else if (target.files[0].type.length === 0) {
            target.value = null;
            props.loader({ loader: false, notification: true, message: "error", validationMessage: "Upload Failed. Please upload a document with a valid file extension such as .doc,.docx, .pdf, .txt, .jpeg etc." });
        }
        else {
            props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
            var fileByteArray = [];
            const fileReader = new FileReader();
            fileReader.onloadend = (evt) => {
                if (evt.target.readyState === FileReader.DONE) {
                    var arrayBuffer = evt.target.result,
                        array = new Uint8Array(arrayBuffer);
                    for (var i = 0; i < array.length; i++) {
                        fileByteArray.push(array[i]);
                    }
                }
            }
            fileReader.readAsArrayBuffer(target.files[0]);
            setTimeout(() => {
                const request = {};
                request.DocumentName = target.files[0].name;
                request.DocumentTitle = target.files[0].name + "_" + props.userInfo.id;
                request.DocumentID = null;
                request.DatabaseName = Config.edocutusDatabase;
                request.DocumentTypeID = Config.edocutusDocumentTypeId;
                request.DocumentData = fileByteArray;
                postFetch("/document", request).then((data) => {
                    if (data.documentID !==null &&  !data.message) {
                        let updatedDocs = [...documents];
                        updatedDocs.push({ id: null, fileType: target.files[0].type, fileName: "Incidental Expense - " + target.files[0].name, size: (target.files[0].size / 1048576).toFixed(2) + " MB", edocId: data.DocumentID, incidentalId: null, delSw: 'N' });
                        setDocuments(updatedDocs);
                        target.value = null;
                        setTimeout(() => {
                            props.loader({ loader: false, notification: true, message: "success", validationMessage: "Document uploaded successfully" });
                        }, 1000);
                    }
                    else {
                        target.value = null;
                        setTimeout(() => {
                            props.loader({ loader: false, notification: true, message: "error", validationMessage: "Document upload failed" });
                        }, 1000);
                    }
                }).catch(() => {
                    target.value = null;
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: "Document upload failed." });
                    }, 1000);
                });
            }, 1000);
        }
    }

    const viewDocument = (row, index) => {
        props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
        postFileDownload("/document?DocumentID=" + row.edocId + "&DatabaseName=" + Config.edocutusDatabase + "&DocumentTypeID=" + Config.edocutusDocumentTypeId).then((data) => {
            if (!data.message) {
                var fileUrl = "data:" + row.fileType + ";base64," + data;
                fetch(fileUrl)
                    .then(response => response.blob())
                    .then(blob => {
                        var link = window.URL.createObjectURL(blob, { type: row.fileType });
                        window.open(link);
                    });
                setTimeout(() => {
                    props.loader({ loader: false, notification: false });
                }, 1000);
            }
            else {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                }, 1000);
            }
        }).catch((e) => {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            }, 1000);
        });
    }


    const summaryActionButton = (row, column, index) => {
        return (<div style={{ cursor: 'pointer' }}><EditRoundedIcon onClick={() => viewExpense(row)} style={{ fontSize: 20 }} /><DeleteIcon onClick={() => readOnly ? null : deleteExpense(row)} style={{ fontSize: 20 }} /></div>);
    }

    const viewExpense = (row) => {
        let details = { ...row };
        getStates();
        getCities(details.state);
        details.expenseDate = new Date(convertDateFromServer(details.expenseDate));
        setIncidentalDetails({ ...details });
        setDefaultReimbAmt(details.reimbAmount);
        setDefaultDhsCardAmount(details.dhsCardAmount);
        setDocuments([...details.documents]);
        setOldIncidentalDetails({ ...details });
        history.push("/tr1/incidentalexp");
    }

    const getCities = async (value) => {
        props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
        await postFetch("/reimbursement/loadCities", value).then((data) => {
            if (!data.message) {
                setTimeout(() => {
                    props.loader({ loader: false, notification: false, message: "success" });
                    setCities([...data]);
                }, 1000);
            }
            else {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                }, 1000);
            }
        }).catch((e) => {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            }, 1000);
        });
    }

    const getStates = async () => {
        let details = { id: null };
        props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
        await postFetch("/reimbursement/loadStates", details).then((data) => {
            if (!data.message) {
                setTimeout(() => {
                    props.loader({ loader: false, notification: false, message: "success" });
                    setStates([...data]);
                }, 1000);
            }
            else {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                }, 1000);
            }
        }).catch((e) => {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            }, 1000);
        });
    }

    const getStateHolidays = async () => {
        let details = { id: null };
        props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
        await postFetch("/expense/getStateHoliday", details).then((data) => {
            if (!data.message) {
                setTimeout(() => {
                    props.loader({ loader: false, notification: false, message: "success" });
                    setStateHoliday([...data]);
                }, 1000);
            }
            else {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                }, 1000);
            }
        }).catch((e) => {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            }, 1000);
        });
    }

    const reset = async () => {
        let details = { ...incidentalDetails };
        //setIsRREdited(false);
        if (details.id === null) {
            setIncidentalDetails({ expReason: null, expenseDate: null, state: null, city: null, ledgerCode: null, incidentalType: null, amount: null, reimbAmount: 0, dhsCardSw: 'N', dhsCardAmount: 0, revReason: null, rembId: null, status: 'IP', delSw: 'N', id: null, notes: null, internalOrder:null, costCenter: null,outOfState: 'N', outsideWorkHours: 'N' });
            setError(false);
            setDocuments([]);
        }
        else {
            props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
            let request = { ...details };
            await postFetch("/incidental/findById", request).then((data) => {
                if (!data.message) {
                    setTimeout(() => {
                        props.loader({ loader: false, notification: false, message: "success" });
                        let details = { ...data };
                        details.expenseDate = new Date(convertDateFromServer(details.expenseDate));
                        setIncidentalDetails({ ...details });
                        setDocuments([...details.documents]);
                        forceUpdate();
                    }, 1000);
                }
                else {
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                    }, 1000);
                }
            }).catch((e) => {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
                }, 1000);
            });
        }
    }
    const deleteExpense = async (row) => {
        let details = { ...row };
        details.delSw = 'Y';
        props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
        let request = { ...details };
        await postFetch("/incidental/save", request).then((data) => {
            if (!data.message) {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "success", validationMessage: "Incidental Expense saved successfully." });
                    setOnInit(true);
                }, 1000);
            }
            else {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                }, 1000);
            }
        }).catch((e) => {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            }, 1000);
        });
    }


    const changeReimAmount = () => {
        let newDetails = { ...incidentalDetails };
        newDetails.dhsCardAmount = null;
        newDetails.reimbAmount = (newDetails.amount !== null) ? parseFloat(newDetails.amount).toFixed(2) : null;
       
       
        setDefaultReimbAmt(newDetails.reimbAmount);
        setDefaultDhsCardAmount(newDetails.dhsCardAmount);

        if (newDetails.dhsCardSw === 'P') {
            if(newDetails.reimbAmount == 0.0 && newDetails.dhsCardAmount>0){
                newDetails.reimbAmount = newDetails.dhsCardAmount
            }

            newDetails.dhsCardAmount = null;
            newDetails.reimbAmount = parseFloat(newDetails.reimbAmount).toFixed(2);
        }
        if (newDetails.dhsCardSw === 'D' || newDetails.dhsCardSw === 'C') {
            if ( (newDetails.dhsCardAmount !== null && newDetails.dhsCardAmount !== '') && ((parseFloat(newDetails.dhsCardAmount) > parseFloat(newDetails.reimbAmount)) || (newDetails.reimbAmount === null))) {
               newDetails.reimbAmount = newDetails.dhsCardAmount;
               newDetails.dhsCardAmount = 0.0;
            }
                newDetails.dhsCardAmount = parseFloat(newDetails.reimbAmount);
                newDetails.reimbAmount = parseFloat(newDetails.reimbAmount) - parseFloat(newDetails.dhsCardAmount);
                newDetails.reimbAmount = parseFloat(newDetails.reimbAmount).toFixed(2);
            
        }

        setIncidentalDetails(newDetails);

    }
    const changeIncidentalDetails = (key, value) => {
        let newDetails = { ...incidentalDetails };
        // if(newDetails[key] !== value)
        // {
        //     setIsRREdited(true);
        // }

        if (key === 'expenseDate') {
            if (value !== null && value !== "") {
                // Update the expense date in the state
                newDetails[key] = value;
                setIncidentalDetails(newDetails);  // Update date in state
                checkIncidentalExpenseDate(value);
            } else {
                // Clear warning and reset state when the date is null or empty
                newDetails[key] = value;
                setIncidentalDetails(newDetails);
                setWarningMessage("");  // Clear warning message
                setShowWarning(false);  // Hide warning
            }
        } else {
            newDetails[key] = value;
            setIncidentalDetails(newDetails);  // Update other fields
        }
        if (key === 'state' && value !== null) {
            newDetails.city = null;
            getCities(value);
        }
        if (key === 'expenseDate' && (value !== null) && (props.reimbursement.expenseStartDate !== null) && (value.setHours(0, 0, 0) < new Date(new Date(props.reimbursement.expenseStartDate).toDateString()).setHours(0, 0, 0))) {
            value = null;
            props.loader({ loader: false, notification: true, message: "error", validationMessage: 'Expense Date cannot be prior to Total Reimbursement Expense Start Date' });
        }
        if (key === 'expenseDate' && (value !== null) && (props.reimbursement.expenseEndDate !== null) && (value.setHours(0, 0, 0) > new Date(new Date(props.reimbursement.expenseEndDate).toDateString()).setHours(0, 0, 0))) {
            value = null;
            props.loader({ loader: false, notification: true, message: "error", validationMessage: 'Expense Date cannot be greater than Total Reimbursement Expense End Date' });
        }
       
        if(key === 'dhsCardSw'){
            newDetails.dhsCardAmount = defaultDhsCardAmount;
            newDetails.reimbAmount = defaultReimbAmt;
            if (value === 'P') {
                if(newDetails.reimbAmount == 0.0 && newDetails.dhsCardAmount>0){
                    newDetails.reimbAmount = newDetails.dhsCardAmount
                }
                newDetails.dhsCardAmount = null;
                newDetails.reimbAmount = (newDetails.amount !== null) ? parseFloat(newDetails.amount).toFixed(2) : null;
            }
            if (value === 'D' || value === 'C') {
                if ((newDetails.dhsCardAmount !== null && newDetails.dhsCardAmount !== '') && ((parseFloat(newDetails.dhsCardAmount) > parseFloat(newDetails.reimbAmount)) || (newDetails.reimbAmount === null))) {
                    newDetails.reimbAmount = newDetails.dhsCardAmount;
                   newDetails.dhsCardAmount = 0.0;
                }
                    newDetails.dhsCardAmount = parseFloat(newDetails.reimbAmount);
                    newDetails.reimbAmount = parseFloat(newDetails.amount) - parseFloat(newDetails.dhsCardAmount);
                    newDetails.reimbAmount = parseFloat(newDetails.reimbAmount).toFixed(2);
      
            }
        }
        newDetails[key] = value;
        if((key === "expReason" || key === "expenseDate" || key === "incidentalType") && (newDetails.expReason === null || newDetails.expReason === 0) || (newDetails.expenseDate === null) || (newDetails.incidentalType === null || newDetails.incidentalType === 0))
        {
            newDetails.ledgerCode = null;
            newDetails.internalOrder = null;
            newDetails.costCenter = null;
        }
        if(key === 'city' && newDetails.state !== null && props.userInfo.userType === 'EM')
        {
            if((["Arkansas"].indexOf(newDetails.state)  === -1))
            {
                newDetails.outOfState = 'Y';
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: "Location selected requires Out of State Travel Preapproval attachment prior to submission. Please attach your preapproval on the Travel Reconciliation Screen." });
                }, 1000);
            }else{
                newDetails.outOfState = 'N';
            }            
        }
        if(key === 'expenseDate' && value !== null && props.userInfo.userType === 'EM')
        {        
            
            var expenseDtStateHoliday = stateHoliday.filter((holiday) => holiday.holidayDate === moment(value).format("YYYY-MM-DD") && holiday.active === 'Y').length > 0 ? true : false;
            var dayOfWeek = new Date(value).getDay();
            if((dayOfWeek === 6 || dayOfWeek  === 0) || (expenseDtStateHoliday)){
                    newDetails.outsideWorkHours = 'Y';
                    incidentalDetails["outsideWorkHours"] = 'Y';
                    setTimeout(() => {
                        props.loader({ loader: false, notification: true, message: "error", validationMessage: "Expense Date selected requires Request to Travel Outside Normal Work Schedule Preapproval attachment prior to submission. Please attach your preapproval on the Travel Reconciliation Screen." });
                    }, 1000);
                } else{
                    newDetails.outsideWorkHours = 'N';
                    incidentalDetails["outsideWorkHours"] = 'N';
            }
            // postFetch("/expense/checkStateHoliday", request).then((data) => {
            //     if (!data.message) {
            //         props.loader({ loader: false, notification: false, message: "success" });
            //         var dayOfWeek = new Date(value).getDay();
            //         if((dayOfWeek === 6 || dayOfWeek  === 0) || (data.holidayCount > 0)){
            //                 newDetails.outsideWorkHours = 'Y';
            //                 setTimeout(() => {
            //                     props.loader({ loader: false, notification: true, message: "error", validationMessage: "Expense Date selected requires Request to Travel Outside Normal Work Schedule Preapproval attachment prior to submission. Please attach your preapproval on the Travel Reconciliation Screen." });
            //                 }, 1000);
            //             } else{
            //                 newDetails.outsideWorkHours = 'N';
            //             }
            //     }
            //     else {
            //         setTimeout(() => {
            //             props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
            //         }, 1000);
            //     }
            // }).catch((e) => {
            //     setTimeout(() => {
            //         props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            //     }, 1000);
            // });
        }
        setIncidentalDetails(newDetails);
        if((key === "expReason" || key === "expenseDate" || key === "incidentalType") && ((newDetails.expReason !== null && newDetails.expReason !== 0) && newDetails.expenseDate !== null && (newDetails.incidentalType !== null && newDetails.incidentalType !== 0))
            && (moment(newDetails.expenseDate, 'MM/DD/YYYY').isValid())){
            let request = {
                expense: 'IN',
                expenseType: newDetails.incidentalType,
                reasonForExpense: newDetails.expReason,
                userType: props.userInfo.userType,
                division: (props.travellerInfo.accountActive !== null && props.travellerInfo.accountActive === '0') ? props.reimbursement.division : (props.userInfo.role === 'PR') ? props.userInfo.division : props.travellerInfo.divisionCode,
                startDate: new Date(newDetails.expenseDate)
            }
            getIOCC(request);
        }
    }

    const getIOCC = async (request) => {
        let newDetails = { ...incidentalDetails };
        props.loader({ loader: true, notification: false, message: "success", validationMessage: "success" });
        postFetch("/financialAdministration/loadIOCC", request).then((data) => {
            if (!data.message) {
                props.loader({ loader: false, notification: false, message: "success" });
                newDetails.internalOrder = data.internalOrder;
                newDetails.costCenter = data.costCenter;
                newDetails.expReason = request.reasonForExpense;
                newDetails.ledgerCode = data.generalLedgerCode;
                newDetails.expenseDate = request.startDate;
                newDetails.incidentalType = request.expenseType;
                if(data.internalOrder === null || data.internalOrder === '')
                {
                    newDetails.internalOrder = props.travellerInfo.empInternalOrder;
                    newDetails.costCenter = props.travellerInfo.empCostCenter;
                }
                setIncidentalDetails(newDetails);
            }
            else {
                setTimeout(() => {
                    props.loader({ loader: false, notification: true, message: "error", validationMessage: data.message });
                }, 1000);
            }
        }).catch((e) => {
            setTimeout(() => {
                props.loader({ loader: false, notification: true, message: "error", validationMessage: "System Error: Please contact help desk." });
            }, 1000);
        });
    }
    const addNewIncExp = () => {
        setIncidentalDetails({});  // Reset mileage details
        setWarningMessage("");  // Clear the warning message
        setShowWarning(false);  // Hide the warning message
        setIncidentalDetails({ expReason: null, expenseDate: null, state: null, city: null, ledgerCode: null, incidentalType: null, amount: null, reimbAmount: 0, dhsCardSw: 'N', dhsCardAmount: 0, revReason: null, rembId: null, status: 'IP', delSw: 'N', id: null, notes: null, internalOrder: null, costCenter: null,outOfState: 'N', outsideWorkHours: 'N' });
        setError(false);
        getStates();
        setDocuments([]);
        getStateHolidays();
        history.push("/tr1/incidentalexp");
    }
    const incidentalColumns = [
        {
            name: "Expense Period",
            field: "sumexpenseDate",
            type: "text"
        },
        {
            name: "Reason for Expense",
            field: "sumexpenseReason",
            type: "text"
        },
        {
            name: "Amount",
            field: "sumamount",
            type: "text"
        },
        {
            name: "Status",
            field: "sumstatus",
            type: "text"
        },
        {
            name: "Action",
            field: "action",
            type: "input",
            renderView: summaryActionButton
        }
    ]
    const uploadColumns = [
        {
            name: "File Name",
            field: "fileName",
            type: "text"
        },
        {
            name: "Size",
            field: "size",
            type: "text"
        },
        {
            name: "Action",
            field: "action",
            type: "input",
            renderView: documentActionButton
        }
    ]


    return (
        <div>
            <Switch>
                <Route exact path="/tr1">
                    <div className={classes.root}>
                        <Typography className={classes.header}>Incidental Expense Summary</Typography>
                        <Box style={{ float: 'right' }} m={2}>
                            <Button variant="outlined" color="primary" size="small" className={(readOnly || (props.pageInfo.find((page) => page.pageId === 5).permissionSw !== 'Y')) ? "disabled" : null}
                                disabled={(readOnly || (props.pageInfo.find((page) => page.pageId === 5).permissionSw !== 'Y'))} onClick={() => addNewIncExp()}>Add New</Button>
                        </Box>
                        <Paper elevation={0}>
                            <Box p={"10px"} pb={"60px"}>
                                <Gridtable columns={incidentalColumns} data={summaryData}></Gridtable>
                                <div className="summaryTotal">
                                    <span>Total Amount: ${totalAmount}</span>
                                </div>
                            </Box>
                        </Paper>
                    </div>
                </Route>
                <Route exact path="/tr1/incidentalexp">
                    <div>
                        <Typography className={classes.header}>Incidental Expense Details</Typography>

                        <Grid container className={classes.filters}>
                            <Grid item xs={10} sm={5} className={classes.datepicker}>
                                <span className="datepickerLabel">Expense Date</span>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                    <KeyboardDatePicker
                                        //disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="exp-date"
                                        autoOk={true}
                                        className={readOnly ? "disabled" : "required"}
                                        disabled={readOnly}
                                        size="small"
                                        fullWidth
                                        value={incidentalDetails.expenseDate}
                                        error={(incidentalDetails.expenseDate === null) || (!moment(incidentalDetails.expenseDate, 'MM/DD/YYYY').isValid())}
                                        minDate={props.reimbursement.expenseStartDate !== null ? props.reimbursement.expenseStartDate : null}
                                        maxDate={props.reimbursement.expenseEndDate !== null ? props.reimbursement.expenseEndDate : new Date()}
                                        onChange={(date) => changeIncidentalDetails('expenseDate', date)}
                                        placeholder="MM/DD/YYYY"
                                        KeyboardButtonProps={{
                                            'aria-label': 'expense date',
                                        }}
                                        inputVariant="outlined"
                                        helperText={(incidentalDetails.expenseDate === null && error) ? "Required Field" : null}
                                    />
                                </MuiPickersUtilsProvider>
                                 {/* Warning Message Display */}
                               {showWarning && warningMessage && (
            <FormHelperText style={{ color: 'red', marginTop: '10px' }}>
                {warningMessage}
            </FormHelperText>
        )}
                            </Grid>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <FormControl variant="outlined" size="small" className={classes.formControl} error={incidentalDetails.incidentalType === 0 || incidentalDetails.incidentalType === null}>
                                    <span className={classes.textLabel}>Incidental Type</span>
                                    <Select
                                        id="carrier-select"
                                        value={incidentalDetails.incidentalType === null ? 0 : incidentalDetails.incidentalType}
                                        className={readOnly ? "disabled" : "required"}
                                        disabled={readOnly}
                                        onChange={(e) => changeIncidentalDetails('incidentalType', e.target.value)}
                                    >
                                        <MenuItem value={0}>Pick an Option</MenuItem>
                                        {props.staticData.dropdown.IncidentalTypes.value.map((method) => (
                                            <MenuItem key={method.value} value={method.value}>{method.name}</MenuItem>
                                        ))}
                                    </Select>
                                    {((incidentalDetails.incidentalType === 0 || incidentalDetails.incidentalType === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                                        : null}
                                </FormControl>
                            </Grid>                           
                        </Grid>
                        <Grid container className={classes.filters}>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <FormControl variant="outlined" size="small" className={classes.formControl} error={incidentalDetails.expReason === 0 || incidentalDetails.expReason === null}>
                                    <span className={classes.textLabel}>Reason for Expense</span>
                                    <Select
                                        id="reason-select"
                                        value={incidentalDetails.expReason === null ? 0 : incidentalDetails.expReason}
                                        className={readOnly ? "disabled" : "required"}
                                        disabled={readOnly}
                                        onChange={(e) => changeIncidentalDetails('expReason', e.target.value)}
                                    >
                                        <MenuItem value={0}>Pick an Option</MenuItem>
                                        {props.staticData.dropdown.ReasonForExpense.value.map((method) => (
                                            <MenuItem key={method.value} value={method.value}>{method.name}</MenuItem>
                                        ))}
                                    </Select>
                                    {((incidentalDetails.expReason === 0 || incidentalDetails.expReason === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                                        : null}
                                </FormControl>
                            </Grid>                                                    
                        </Grid>
                        <Subheader>For Agency Payment Purposes Only</Subheader>
                        <Grid container className={classes.filters}>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <span className={classes.textLabel}>General Ledger Code </span>
                                <TextField
                                    id="ledgerCode"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={(incidentalDetails.ledgerCode === null || incidentalDetails.ledgerCode === 0) ? '' : incidentalDetails.ledgerCode}
                                />
                            </Grid>   
                        </Grid>
                        <Grid container className={classes.filters}>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                    <span className={classes.textLabel}>Internal Order</span>
                                    <TextField
                                    variant="outlined"
                                    size="small"
                                    id="expinternalOrder"
                                    fullWidth
                                    disabled
                                    className="disabled"                                    
                                    value={incidentalDetails.internalOrder === null ? '' : incidentalDetails.internalOrder}                                    
                                    />
                            </Grid>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <span className={classes.textLabel}>Cost Center</span>
                                    <TextField
                                    variant="outlined"
                                    size="small"
                                    id="expcostCenter"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={incidentalDetails.costCenter === null ? '' : incidentalDetails.costCenter}                                    
                                    />
                            </Grid>
                        </Grid>
                        <Grid container className={classes.filters}>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <FormControl variant="outlined" size="small" className={classes.formControl} error={incidentalDetails.state === 0 || incidentalDetails.state === null}>
                                    <span className={classes.textLabel}>State</span>
                                    <Select
                                        id="state-select"
                                        value={incidentalDetails.state === null ? 0 : incidentalDetails.state}
                                        className={readOnly ? "disabled" : "required"}
                                        disabled={readOnly}
                                        onChange={(e) => changeIncidentalDetails('state', e.target.value)}
                                    >
                                        <MenuItem value={0}>Pick an Option</MenuItem>
                                        {states.sort().map((method) => (
                                            <MenuItem key={method} value={method}>{method}</MenuItem>
                                        ))}
                                    </Select>
                                    {((incidentalDetails.state === 0 || incidentalDetails.state === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                                        : null}
                                </FormControl>
                            </Grid>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <FormControl variant="outlined" size="small" className={classes.formControl} error={incidentalDetails.city === 0 || incidentalDetails.city === null}>
                                    <span className={classes.textLabel}>City</span>
                                    <Select
                                        id="city-select"
                                        value={incidentalDetails.city === null ? 0 : incidentalDetails.city}
                                        className={readOnly ? "disabled" : "required"}
                                        disabled={readOnly}
                                        onChange={(e) => changeIncidentalDetails('city', e.target.value)}
                                    >
                                        <MenuItem value={0}>Pick an Option</MenuItem>
                                        {(incidentalDetails.state === 0 || incidentalDetails.state === null) ? null : cities.map((value) => (
                                            <MenuItem key={value} value={value}>{value}</MenuItem>
                                        ))}
                                    </Select>
                                    {((incidentalDetails.city === 0 || incidentalDetails.city === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                                        : null}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.filters}>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <span className={classes.textLabel}>Amount</span>
                                <TextField
                                    id="expAmount"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    className={readOnly ? "disabled" : "required"}
                                    disabled={readOnly}
                                    value={incidentalDetails.amount === null ? '' : parseFloat(incidentalDetails.amount).toFixed(2)}
                                    error={incidentalDetails.amount === '' || incidentalDetails.amount === null}
                                    onChange={(e) => (e.target.value !== '') ? changeIncidentalDetails('amount', parseFloat(e.target.value).toFixed(2)) : changeIncidentalDetails('amount', null)}
                                    onBlur={() => changeReimAmount()}
                                    InputProps={{
                                        inputComponent: AmountFormatCustom,
                                    }}
                                    helperText={((incidentalDetails.amount === '' || incidentalDetails.amount === null) && error) ? "Required Field" : null}
                                />
                            </Grid>
                            <Grid item xs={10} sm={5} className={classes.textfield}>
                                <span className={classes.textLabel}>Reimbursement Amount</span>
                                <TextField
                                    id="reimbAmount"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    disabled
                                    className="disabled"
                                    value={"$" + (incidentalDetails.reimbAmount === null ? 0 : incidentalDetails.reimbAmount)}
                                />
                            </Grid>
                        </Grid>
                        {props.userInfo.role !== 'PR' ? <Grid container className={classes.filters}>
                            <Grid item xs={12} className={classes.textfield}>
                                <FormControl component="fieldset">
                                    <RadioGroup row aria-label="stateCard-sw" name="stateCard-sw" defaultValue={(incidentalDetails.dhsCardSw == 'D' || incidentalDetails.dhsCardSw == 'C') ? incidentalDetails.dhsCardSw : 'P'} onChange={(e) => changeIncidentalDetails('dhsCardSw', e.target.value)}>
                                        <FormLabel component="legend" className={classes.radioLabel}>How have you paid for this expense?</FormLabel>
                                        <div>
                                            <FormControlLabel value="P" control={<Radio disabled={readOnly} color="primary" />} label="Personal" />
                                            <FormControlLabel value="D" control={<Radio disabled={readOnly} color="primary" />} label="Direct Billing" />
                                            <FormControlLabel value="C" control={<Radio disabled={readOnly} color="primary" />} label="DHS Credit Card" />
                                        </div>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid> : null}
                        {incidentalDetails.dhsCardSw === 'D' || incidentalDetails.dhsCardSw === 'C' ? <div>
                            <Grid container className={classes.filters}>
                                <Grid item xs={10} sm={5} className={classes.textfield}>
                                    <span className={classes.textLabel}>Direct Billing/DHS Credit Card Amount</span>
                                    <TextField
                                        id="stateCardAmnt"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        className={"disabled"}
                                        disabled={true}
                                        value={incidentalDetails.dhsCardAmount === null ? '' : parseFloat(incidentalDetails.dhsCardAmount).toFixed(2)}
                                        error={incidentalDetails.dhsCardAmount === '' || incidentalDetails.dhsCardAmount === null}
                                        InputProps={{
                                            inputComponent: AmountFormatCustom,
                                            readOnly:true
                                        }}
                                        read
                                        helperText={((incidentalDetails.dhsCardAmount === '' || incidentalDetails.dhsCardAmount === null) && error) ? "Required Field" : null}
                                    />
                                </Grid>
                            </Grid>
                        </div> : null}
                        <Grid container className={classes.filters}>
                            <Grid item xs={10} className={classes.textfield}>
                                <span className={classes.textLabel}>Notes</span>
                                <TextField
                                    id="notes"
                                    multiline
                                    value={incidentalDetails.notes === null ? '' : incidentalDetails.notes}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    size="small"
                                    fullWidth
                                    onChange={(e) => changeIncidentalDetails('notes', e.target.value)}
                                    inputProps={{
                                        maxLength: 400
                                    }}
                                />
                                <FormHelperText>{incidentalDetails.notes === null ? 0 : incidentalDetails.notes.length}/400 Characters</FormHelperText>
                            </Grid>
                        </Grid>
                        <Subheader>Upload Documents</Subheader>
                        <Grid container style={{ padding: "0 10px" }}>
                            <Grid item xs={12}>
                                <Box style={{ float: 'right', margin: "20px 10px 10px 20px" }}>
                                    <Button variant="outlined" color="primary" size="small" className={readOnly ? "disabled" : null}
                                        disabled={readOnly}>
                                        <span style={{ position: 'absolute', maxWidth: '210px'  }}>Select FIle & Upload</span>
                                        <input
                                            color="primary"
                                            type="file"
                                            onChange={(event) => upload(event.target)}
                                            id="upload-file"
                                            style={{ opacity: 0, maxWidth: '210px', cursor: 'pointer' }}
                                        />
                                    </Button>
                                </Box>
                                <Box pr={"10px"}>
                                    <Gridtable columns={uploadColumns} data={documents.filter((doc) => doc.delSw !== 'Y')}></Gridtable>
                                </Box>
                            </Grid>
                        </Grid>

                        {((incidentalDetails.status !== 'IP') && (props.reimbursement.assignedTo !== null) && (props.reimbursement.assignedTo === props.userInfo.id)) ? <div>
                            <Subheader>Travel Supervisor Status Details</Subheader>
                            <Grid container className={classes.filters}>
                                <Grid item xs={10} sm={3} className={classes.textfield}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={incidentalDetails.status === 'AP'}
                                                onChange={() => changeIncidentalDetails('status', 'AP')}
                                                name="Approve"
                                                color="primary"
                                                className={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : null}
                                                disabled={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id) || props.staticData.dropdown.MedicaidApprovers.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0 || props.staticData.dropdown.PaymentApprover.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0)}
                                            />
                                        }
                                        label="Approve"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={10} sm={3} className={classes.textfield}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={incidentalDetails.status === 'RR'}
                                                onChange={() => changeIncidentalDetails('status', 'RR')}
                                                name="Return for Revision"
                                                color="primary"
                                                className={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : null}
                                                disabled={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id) || props.staticData.dropdown.MedicaidApprovers.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0 || props.staticData.dropdown.PaymentApprover.value.filter((config)=> config.value.toString() === props.userInfo.id.toString()).length > 0)}
                                            />
                                        }
                                        label="Return for Revision"
                                        labelPlacement="end"
                                    />
                                </Grid>
                            </Grid>
                            {incidentalDetails.status === 'RR' ? <Grid container className={classes.filters}>
                                <Grid item xs={10} sm={5} className={classes.textfield}>
                                    <FormControl variant="outlined" size="small" className={classes.formControl} error={incidentalDetails.revReason === 0 || incidentalDetails.revReason === null}>
                                        <span className={classes.textLabel}>Reason for Revision</span>
                                        <Select
                                            id="reason-select"
                                            value={incidentalDetails.revReason === null ? 0 : incidentalDetails.revReason}
                                            className={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id)) ? "disabled" : "required"}
                                            disabled={((props.reimbursement.status === 'RR') && (props.reimbursement.userId === props.userInfo.id))}
                                            onChange={(e) => changeIncidentalDetails('revReason', e.target.value)}
                                        >
                                            <MenuItem value={0}>Pick an Option</MenuItem>
                                            {props.staticData.dropdown.ReasonForRevision.value.map((method) => (
                                                <MenuItem key={method.value} value={method.value}>{method.name}</MenuItem>
                                            ))}
                                        </Select>
                                        {((incidentalDetails.revReason === 0 || incidentalDetails.revReason === null) && error) ? <FormHelperText>Required Field</FormHelperText>
                                            : null}
                                    </FormControl>
                                </Grid>
                            </Grid> : null}
                        </div> : null}
                        <div style={{ textAlign: 'center', paddingBottom: 10 }}>
                            <Button style={{ margin: "20px 5px 5px 20px" }} variant="contained" color="primary" size="small" disabled = {["SB","AP","PO","PP"].indexOf(props.reimbursement.status)  > -1 && (props.reimbursement.userId === props.userInfo.id)} onClick={() => reset()}>Reset</Button>
                            <Button style={{ margin: "20px 5px 5px 20px" }} variant="contained" color="primary" size="small" disabled = {["SB","AP","PO","PP"].indexOf(props.reimbursement.status)  > -1 && (props.reimbursement.userId === props.userInfo.id)} onClick={() => saveIncidentals()}>Save</Button>
                        </div>
                    </div>
                </Route>
            </Switch>
        </div>
    )

}